import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { AttachmentsHelper, FormInfo, FormMode, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, EditingToolbar } from '@liasincontrol/ui-basics';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, License } from '@liasincontrol/userrights-service';
import { useTextAssistantSkills } from "@liasincontrol/redux-service";
import { TextAssistantAI, MultilineToolbarContainer } from '@liasincontrol/ui-elements';
import { HierarchyItemForm } from '../HierarchyItemForm';

type Props = {
    fields: Record<string, string>,
    complexFields: Domain.Shared.ComplexField[],
    attachments: Domain.Shared.Attachment[],
    audit: Domain.Dto.Shared.AuditEvent[],
    users: Domain.Shared.User[],
    elementDefinition: Domain.Shared.ElementDefinition,
    workflowTemplate?: Domain.Shared.WorkflowTemplateWithStates,
    workflowState?: Domain.Shared.AbstractElementWorkflowStatus,
    hierarchyRights?: Domain.Performance.HierarchyItemRight[],
    isMeasureMomentClosed: boolean,
    mode: FormMode,
    userIdentity: UserIdentity,
    onAssignContributors: (contributors: Record<string, boolean>) => void,
    onSaveChanges: () => void,
    onCancelChanges: () => void,

    //send values to parent elementInstance
    onFieldsDataChanged: (fields: FormInfo<ValueType>) => void,
    onWorkflowStateChanged: (workflowState: Domain.Shared.AbstractElementWorkflowStatus) => void,
    leaseInfo?: Domain.Shared.AcquireLease,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders the form for creating or editing an entity.
 */
export const HierarchyItem: React.FC<Props> = (props) => {
    const [fields, setFields] = useState<FormInfo<ValueType>>({
        values: props.fields,
        complex: props.complexFields ?? [],
        attachments: props.attachments ?? [],
        workflow: props.workflowState,
        isValid: undefined,
        isTouched: false,
    });

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [showTextAssistantAI, setShowTextAssistantAI] = useState<boolean>(false);
    const hasArtificialIntelligenceAvailable = UserRightsService.getInstance().userHasLicence(props.userIdentity, License.AITextAssistant);
    const textAssistantSkills = useTextAssistantSkills(hasArtificialIntelligenceAvailable);
    const [selectedFieldId, setSelectedFieldId] = useState<string>(null);

    // This is needed to ensure the initial load of data
    useEffect(() => {
        setFields((prev) =>
            ({
                values: _.isEmpty(prev.values) || Object.values(prev.values).some(v => !v) ? props.fields : prev.values,
                complex: _.isEmpty(prev.complex) || Object.values(prev.complex).some(v => !v) ? (props.complexFields ?? []) : prev.complex,
                attachments: _.isEmpty(prev.attachments) || Object.values(prev.attachments).some(v => !v) ? (props.attachments ?? []) : prev.attachments,
                workflow: prev.workflow ?? props.workflowState,
                isValid: prev.isValid,
                isTouched: prev.isTouched,
            })
        );
    }, [props.fields, props.complexFields, props.attachments, props.workflowState])

    // #region event handlers...
    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     */
    const onUploadAttachment = async (file: File, abortSignal: AbortSignal) => {
        const attachmentsComplexFieldDefinition = props.elementDefinition.complexFields.find(item => item.systemId === Domain.SystemFieldDefinitions.Performance.Attachment);
        const attachmentsFileFieldDefinition = attachmentsComplexFieldDefinition.fields.find(item => item.systemId === Domain.SystemFieldDefinitions.Performance.AttachmentFile);

        return AttachmentsHelper.uploadAttachment(file, abortSignal, (attachmentId: string, file: File) => {
            const newFields = _.cloneDeep(fields);
            newFields.attachments = [...newFields.attachments, AttachmentsHelper.mapFileToAttachment(file, attachmentId, true)];

            // fill in the gaps: if rowIndex is bigger than item's index in the complex array or if the complex field value is empty, 
            // then the new attachment will take its place. And findIndex only works with a sorted array in this case. 
            const index = newFields.complex.sort((a, b) => a.rowIndex - b.rowIndex).findIndex((c, i) => c.rowIndex > i || !c.fields);
            const rowIndex = index === -1 ? newFields.complex.length : index;

            newFields.complex = [...newFields.complex, {
                complexFieldDefinitionId: attachmentsComplexFieldDefinition.id,
                rowIndex,
                fields: {
                    [attachmentsFileFieldDefinition.id]: attachmentId,
                },
            },
            ];
            newFields.isValid = _.isUndefined(fields.isValid) || fields.isValid;
            newFields.isTouched = true;
            setFields(newFields);
            props.onFieldsDataChanged(newFields);
        });
    };

    /**
     * Removes an attachment related to a performance item.
     * @param attachmentId Defines the attachment unique identifier.
     */
    const onRemoveAttachment = (attachmentId: string) => {
        const attachmentsComplexFieldDefinition = props.elementDefinition.complexFields.find(item => item.systemId === Domain.SystemFieldDefinitions.Performance.Attachment);
        const attachmentsFileFieldDefinition = attachmentsComplexFieldDefinition.fields.find(item => item.systemId === Domain.SystemFieldDefinitions.Performance.AttachmentFile);

        const attachmentsList = _.cloneDeep(fields.attachments);
        const attachmentsComplexFields = _.cloneDeep(fields.complex);

        const existingComplexField = fields.complex && fields.complex
            .find(item => item.fields && Object.keys(item.fields).includes(attachmentsFileFieldDefinition.id) && item.fields[attachmentsFileFieldDefinition.id] === attachmentId);

        if (existingComplexField) {
            // Mark as deleted:
            const attachment = attachmentsList.find(item => item.id === attachmentId);
            attachment.deleted = true;

            const attachmentComplexField = attachmentsComplexFields.find(item => item.fields && item.fields[attachmentsFileFieldDefinition.id] === attachmentId);
            attachmentComplexField.fields = null;

        } else {
            // Delete the complex field with the related attachment from the local storage:
            const attachment = attachmentsList.find(item => item.id === attachmentId);
            attachmentsList.splice(attachmentsList.indexOf(attachment), 1);

            const attachmentComplexField = attachmentsComplexFields && attachmentsComplexFields
                .find(item => item.fields && Object.keys(item.fields).includes(attachmentsFileFieldDefinition.id) && item.fields[attachmentsFileFieldDefinition.id] === attachmentId);
            attachmentsComplexFields.splice(attachmentsComplexFields.indexOf(attachmentComplexField), 1);
        }

        const newFields = _.cloneDeep(fields);
        newFields.attachments = attachmentsList;
        newFields.complex = attachmentsComplexFields;
        newFields.isTouched = true;
        newFields.isValid = _.isUndefined(fields.isValid) || fields.isValid;
        setFields(newFields);
        props.onFieldsDataChanged(newFields);
    };

    /**
     * Downloads an attachment related to a performance item.
     * @param attachmentId Defines the attachment unique identifier.
     */
    const onDownloadAttachment = (attachmentId: string) => {
        const attachmentsList = _.cloneDeep(fields.attachments);
        const attachment = attachmentsList.find(item => item.id === attachmentId);

        AttachmentsHelper.getAttachmentInfo(attachmentId)
            .then(async (response) => {
                const fileResponse = await fetch(response.data.urlWithSasToken, { method: 'GET' });
                const blob = await fileResponse.blob();

                const file = new File([blob], `${attachment.name}.${attachment.fileExtension}`, { type: blob.type });
                return file;
            })
            .then((file) => {
                saveAs(file, file.name);
            });
    };

    /**
     * Sets a new value for the workflow state related to the current performance item.
     * @param workflowStateValue The value of the workflow state that has been changed.
     */
    const onWorkflowStateChanged = (workflowStateValue: Domain.Shared.AbstractElementWorkflowStatus) => {
        setFields(fields => {
            const newFields = _.cloneDeep(fields);
            newFields.workflow = workflowStateValue;
            newFields.isTouched = true;
            return newFields;
        });
        props.onWorkflowStateChanged(workflowStateValue);
    };

    /**
     * Sets a new value for the field values related to the current performance item.
     * @param fields The data with the fields that has been changed.
     */
    const onFieldsChanged = (fields: FormInfo<ValueType>) => {
        setFields(prevFields => {
            const newFields = _.cloneDeep(prevFields);
            newFields.values = fields.values;
            newFields.isValid = fields.isValid;
            newFields.isTouched = true;
            return newFields;
        });
        props.onFieldsDataChanged(fields);
    };
    // #endregion

    const middleEditorToolbars = useMemo(() => {
        if (!hasArtificialIntelligenceAvailable || !selectedFieldId) {
            return null;
        }

        const selectedMultilineField = props.elementDefinition.fields.find(field => field.id === selectedFieldId && isFieldMultiline(field));

        if (selectedMultilineField) {
            return <MultilineToolbarContainer hidden={false} onClick={() => setShowTextAssistantAI(true)} />
        } else {
            return null;
        }
    }, [props.elementDefinition, selectedFieldId, hasArtificialIntelligenceAvailable]);

    return (
        <ModalDialog
            toolbars={<EditingToolbar
                look="default"
                isVisible={true}
                isValid={fields.isValid}
                disabled={props.mode === FormMode.View || isBusy || !fields.isTouched}
                middleNode={middleEditorToolbars}
                onSave={() => props.onSaveChanges()}
                onCancel={props.onCancelChanges} />}
            customPadding
            modalDialogStyle="card"
        >
            <HierarchyItemForm
                fields={fields}
                audit={props.audit}
                users={props.users}
                isMeasureMomentClosed={props.isMeasureMomentClosed}
                elementDefinition={props.elementDefinition}
                workflowTemplate={props.workflowTemplate}
                hierarchyRights={props.hierarchyRights}
                mode={props.mode}
                onAssignContributors={props.onAssignContributors}
                onFieldsDataChanged={onFieldsChanged}
                onUploadAttachment={onUploadAttachment}
                onRemoveAttachment={onRemoveAttachment}
                onDownloadAttachment={onDownloadAttachment}
                onStartMultipleUpload={() => setIsBusy(true)}
                onFinishMultipleUpload={() => setIsBusy(false)}
                onWorkflowStateChanged={onWorkflowStateChanged}
                leaseInfo={props.leaseInfo}
                setSelectedFieldId={setSelectedFieldId}
                icons={props.icons}
            />
            {hasArtificialIntelligenceAvailable && showTextAssistantAI &&
                <TextAssistantAI
                    textAssistantSkills={textAssistantSkills?.items}
                    textContent={fields.values[selectedFieldId]}
                    onCancel={() => setShowTextAssistantAI(false)}
                />}
        </ModalDialog>
    );
};

const isFieldMultiline = (field: Domain.Shared.FieldDefinition) => {
    if (field.dataType !== Domain.Shared.FieldDataType.String.toString())
        return false;

    if (field.stringType === Domain.Shared.StringType.MultiLine) {
        return true;
    }

    switch (field.systemId) {
        case Domain.SystemFieldDefinitions.Performance.Explanation:
        case Domain.SystemFieldDefinitions.Performance.Description:
        case Domain.SystemFieldDefinitions.Performance.Result:
            return true;
        default: return false;
    }
};
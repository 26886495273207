import React, { useState } from 'react';
import _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import { BasicValidator, FormHelper, TextValidator, ValidationErrorData, ValueType } from '@liasincontrol/core-service';
import { TextElement, SelectElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';

type Props = {
    baseYears: number[],
    disableSaveButton: boolean,
    onSave: (createBudgetJournal: Domain.Dto.Finance.CreateBudgetJournal) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the modal for create a budgetjournal item with minimal requirements.
 */
export const BudgetJournalCreateForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<{ formData: Domain.Dto.Finance.CreateBudgetJournal, isTouched: boolean }>(initForm());
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    const onSave = () => {
        const errors = validate(form.formData, validationErrors.errors);

        if (errors.hasErrors) {
            setValidationErrors(errors);
            return;
        }

        props.onSave(form.formData);
    };

    const onChange = (value: string | number, fieldName: string) => {
        const data: Domain.Dto.Finance.CreateBudgetJournal = { ...form.formData };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm({ formData: data, isTouched: true });

        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        setValidationErrors(temporaryValidationError);
    };

    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Begrotingswijzigingen aanmaken',
            footer:
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCancel}
                    rightButtonText='Opslaan'
                    onRightButtonClick={onSave}
                    rightButtonDisabled={!form.isTouched || props.disableSaveButton || validationErrors.hasErrors}
                />
        }}>
            <MuiGrid container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 1, md: 2 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1} md={2} key='journal-name-field'>
                    <AutoFocus>
                        <TextElement
                            id='name-field'
                            label='Naam'
                            editorSettings={{
                                disabled: false,
                                restrictions: { required: true, minLength: 2, maxLength: 200 },
                                validationErrors: validationErrors.errors['name'],
                                onChange: (value: string) => onChange(value, 'name'),
                            }}
                            value={form.formData.name}
                        />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid item xs={1} key='journal-code-field'>
                    <TextElement
                        id='code-field'
                        label='Code'
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: true, maxLength: 50 },
                            validationErrors: validationErrors.errors['code'],
                            onChange: (value: string) => onChange(value, 'code'),
                        }}
                        value={form.formData.code}
                    />
                </MuiGrid>
                <MuiGrid item xs={1} key='journal-base-year-field'>
                    <SelectElement<number>
                        id='select-base-year'
                        label='Basisjaar'
                        optionItems={props.baseYears}
                        value={form.formData.baseYear}
                        clearable={false}
                        searchable={false}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: true },
                            validationErrors: validationErrors.errors['baseYear'],
                            onChange: (item) => onChange(item, 'baseYear'),
                        }}
                    />
                </MuiGrid>
            </MuiGrid>
        </ModalDialog>
    );
};

const initForm = (form?: Domain.Dto.Finance.CreateBudgetJournal) => {
    return { formData: form || new Domain.Dto.Finance.CreateBudgetJournal(), isTouched: false };
};

const validate = (form: Domain.Dto.Finance.CreateBudgetJournal, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    'name': new TextValidator({ required: true, stringMaxLength: 200, stringType: Domain.Shared.StringType.SingleLine }),
    'code': new TextValidator({ required: true, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
    'baseYear': new BasicValidator({ required: true })
};
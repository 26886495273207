import React, { useEffect, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondErrorDescription, FilePondFile, LoadServerConfigFunction } from 'filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { ContentTypeUtils } from '@liasincontrol/core-service';
import { HelpText } from '../../helptext';
import { Feedback, Label, MandatoryIcon } from '../../primitives';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '../../button';
import { Tooltip } from 'devextreme-react/tooltip';


registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation, FilePondPluginImageValidateSize, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

type Props = {
    id: string,
    label: string,
    attachmentId: string,
    allowMultiple?: boolean,
    allowedFileTypes?: string[],
    maxFileSize?: number,
    maxImageSize?: { height: number, width: number },
    disabled?: boolean,
    mandatory?: boolean,
    error?: React.ReactNode,
    success?: React.ReactNode,
    feedback?: React.ReactNode,
    helpText?: { title?: string, text: string },
    onChange: (attachmentId: string) => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onStartMultipleUpload?: () => void,
    onFinishMultipleUpload?: () => void,
    onAddFileError?: (errorMessage: string) => void,
    onReset?: () => void,
}

interface AttachmentFieldError extends FilePondErrorDescription {
    main?: string;
    sub?: string;
}

/**
 * Represents an UI component that renders an attachment field.
 */
const AttachmentFieldItem: React.FC<Props> = (props) => {
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [errorOnUpload, setErrorOnUpload] = useState<boolean>(false);
    const timer = useRef(null);

    const initializeLocalAttachmentFiles = (firstInit = false) => {
        if (!props.attachmentId) {
            setAttachmentFiles([]);
            return;
        }

        timer.current = setTimeout(() => {
            setAttachmentFiles([
                {
                    source: props.attachmentId,
                    options: {
                        type: "local"
                    }
                }
            ]);
        }, firstInit ? 0 : 3000)

        return () => clearInterval(timer.current);
    };

    useEffect(() => initializeLocalAttachmentFiles(true), []);

    useEffect(initializeLocalAttachmentFiles, [props.attachmentId]);

    /**
   * An event handler that is triggered when a file is processed.
   */
    const onProcess = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        const controller = new AbortController();
        const { signal } = controller;
        props.onUploadAttachment(file, signal)
            .then((attachmentId: string) => {
                load(attachmentId);
            })
            .catch((ex) => {
                error(ex);
                setErrorOnUpload(true);
            });

        return {
            abort: () => {
                controller.abort();
                abort();
            },
        };
    };

    /**
     * An event handler that is triggered when a file is loaded.
     */
    const onLoad: LoadServerConfigFunction = (source, load, error, progress, abort) => {
        props.onLoadAttachment(props.attachmentId).then((response) => {
            load(response);
        });
    };

    const imageSizeValidation = () => {
        if (!!props.maxImageSize) {
            return {
                imageValidateSizeMaxHeight: props.maxImageSize.height,
                imageValidateSizeMaxWidth: props.maxImageSize.width,
            };
        }
    };

    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            {props.helpText && props.helpText.text && (
                <HelpText title={props.helpText.title}>{props.helpText.text}</HelpText>
            )}
            {!props.disabled && props.mandatory && <MandatoryIcon />}
            {props.onReset &&
                <Button id="btn-reset-to-template" btnbase='textbuttons' btntype='small_icon' icon={<RefreshIcon />} onClick={() => props.onReset()}>
                    <Tooltip
                        target="#btn-reset-to-template"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={true}
                    >
                        Laad {props.label} uit template
                    </Tooltip>
                </Button>

            }
            <FilePond
                id={`input-filepond-${props.id}`}
                files={attachmentFiles}
                allowImagePreview={!isFirefox()}
                //server events
                server={{
                    process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                        onProcess(fieldName, file, metadata, load, error, progress, abort, transfer, options);
                    },
                    load: (source, load, error, progress, abort, headers) => {
                        onLoad(source, load, error, progress, abort, headers);
                    },
                    remove: (source, load, error) => {
                        props.onChange('');
                    },
                    revert: (source, load, error) => {
                        props.onChange('');
                    },
                }}
                //A file has been added or removed
                onupdatefiles={(fileItems) => {
                    setAttachmentFiles(fileItems);
                }}
                //multiple
                onprocessfiles={() => {
                    if (props.onFinishMultipleUpload) {
                        props.onFinishMultipleUpload();
                    }
                }}
                //Finished processing a file, if the detail object contains an error property, something went wrong
                onprocessfile={(error: AttachmentFieldError, file: FilePondFile) => {
                    props.onChange(file.serverId);
                    setIsProcessing(false);
                    if (!errorOnUpload && props.allowMultiple) {
                        setAttachmentFiles((files) => {
                            return file ? files.filter((f) => f.id !== file.id) : files;
                        });
                    }
                }}
                onremovefile={(error: AttachmentFieldError | null, file: FilePondFile) => {
                    setAttachmentFiles((files) => {
                        return file ? files.filter((f) => f.id !== file.id) : files;
                    });

                }}
                onaddfile={(error: AttachmentFieldError | null, file: FilePondFile) => {
                    if (!error && !props.attachmentId) {
                        setIsProcessing(true);
                        if (props.allowMultiple && props.onStartMultipleUpload) {
                            props.onStartMultipleUpload();
                        }
                    }
                    else if (error && props.onAddFileError) {
                        props.onAddFileError(error?.main);
                    }
                }}
                dropOnPage
                {...defaultOptions}
                disabled={props.disabled}
                allowMultiple={props.allowMultiple ?? false}
                allowRevert={true}
                allowPaste={false}
                acceptedFileTypes={props.allowedFileTypes}
                maxFileSize={`${props.maxFileSize}`}
                maxParallelUploads={1}
                fileValidateTypeLabelExpectedTypes={props.allowedFileTypes?.length > 1 ? `Verwacht {allButLastType} of {lastType}` : `Verwacht {lastType}`}
                name="files"
                fileValidateTypeDetectType={vendorMimeTypeValidate}
                {...imageSizeValidation()}
            ></FilePond>
            {!isProcessing && <Feedback
                error={!!props.error}
                children={props.error}
                withCounter={false}
                id={`${props.attachmentId}-feedback`}
            />}
        </>
    );
};

/**
 * Represents a Custom type detector method for vendor specific mimetypes.
 * 
 * @param source Represents the current uploaded file.
 * @param type Represents the browser detected type.
 */
const vendorMimeTypeValidate = (source, type) => new Promise<string>((resolve, reject) => {
    resolve(ContentTypeUtils.translate(type, source.name));
});

/**
 * Defines the default options.
 */
const defaultOptions =
{
    labelIdle: 'Sleep & plaats uw bestanden of <span class="filepond - label-action"> bladeren </span>',
    labelInvalidField: 'Veld bevat ongeldige bestanden',
    labelFileWaitingForSize: 'Wachten op bestandsgrootte',
    labelFileSizeNotAvailable: 'Bestandsgrootte niet beschikbaar',
    labelFileLoading: 'Bezig met laden',
    labelFileLoadError: 'Fout tijdens laden',
    labelFileProcessing: 'Uploaden',
    labelFileProcessingComplete: 'Upload compleet',
    labelFileProcessingAborted: 'Upload geannuleerd',
    labelFileProcessingError: 'Fout tijdens uploaden',
    labelFileProcessingRevertError: 'Fout tijdens terugdraaien',
    labelFileRemoveError: 'Fout tijdens verwijderen',
    labelTapToCancel: 'tik om te annuleren',
    labelTapToRetry: 'tik om het opnieuw te proberen',
    labelTapToUndo: 'tik om ongedaan te maken',
    labelButtonRemoveItem: 'Verwijderen',
    labelButtonAbortItemLoad: 'Afbreken',
    labelButtonRetryItemLoad: 'Opnieuw proberen',
    labelButtonAbortItemProcessing: 'Annuleren',
    labelButtonUndoItemProcessing: 'Ongedaan maken',
    labelButtonRetryItemProcessing: 'Opnieuw proberen',
    labelButtonProcessItem: 'Uploaden',
    imageValidateSizeLabelImageSizeTooBig: 'Afbeelding is te groot',
    imageValidateSizeLabelExpectedMaxSize: 'Maximale afmeting is {maxWidth}px × {maxHeight}px',
    labelMaxFileSizeExceeded: 'Bestand is te groot',
    labelMaxFileSize: 'Maximale bestandsgrootte is {filesize}',
    labelFileTypeNotAllowed: 'Verkeerde bestandsextensie',
    fileValidateTypeLabelExpectedTypesMap: {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'image/x-icon': '.ico',
        'image/webp': '.webp',
        'text/plain': '.txt',
        'text/csv': '.csv',
        'application/msword': '.doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/vnd.ms-excel': '.xsl',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xslx',
        'application/vnd.ms-powerpoint': '.ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
        'application/pdf': '.pdf',
    },
};

const isFirefox = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('firefox') || userAgent.includes('fxios')) {
        return true;
    }

    if (userAgent.match(/firefox\/\d+/) || userAgent.match(/fxios\/\d+/)) {
        return true;
    }

    return false;
};



export const AttachmentField = React.memo(AttachmentFieldItem);

import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ColumnBuilderItem } from './columnbuilderitem';
import { ILsColumnProps } from '../../index';
import * as Styled from './columnbuilder.styled';

export type ILsColumnValue = ILsColumnProps & {
    /**
     * Determines if the column total is displayed at the bottom of the grid.
     */
    showTotalsSummary?: boolean,
    /**
     * Determines if the column group total is displayed in the header of the grid group.
     */
    showGroupHeaderSummary?: boolean,
    /**
     * Determines if the column group total is displayed in the footer of the grid group.
     */
    showGroupFooterSummary?: boolean,
}

type Props = {
    /**
     * Defines the column settings array.
     */
    readonly values: string,
    /**
     * Determines if the current column settings value is disabled.
     */
    readonly disabled?: boolean,
    /**
     * Defines the height of a column item.
     */
    readonly height?: number,
    /**
     * Defines an event handler triggered when the column settings value has changed.
     */
    readonly onValuesChanged?: (string) => void,

    /** 
     * Available actions array
     */
    readonly allowedActions: Record<string, boolean>,

}

type SortableItemProps = {
    value: ILsColumnValue,
    myIndex: number,
    disabled: boolean,
    height: number,
    onValueChanged: any,
    allowedActions: any,
    expandedFields: string[],
    onExpand: any,
};
type SortableListProps = {
    items: ILsColumnValue[],
    disabled: boolean,
    height: number,
    onValueChanged: any,
    allowedActions: any,
    expandedFields: string[],
    onExpand: any
};

/**
 * Represents a control that allows the user to configure the DataTable columns and summary items.
 */
export const LsColumnBuilder: React.FC<Props> = (props) => {
    const [items, setItems] = useState<ILsColumnValue[]>([]);
    const [expandedFields, setExpandedFields] = useState<string[]>([]);

    useEffect(() => {
        setItems(props?.values && props.values !== '' ? JSON.parse(props.values) : []);
    }, [props.values]);

    const onValueChanged = (changedItem: ILsColumnValue, changedIndex: number): void => {
        if (!changedItem) {
            return;
        }

        let sortColumns = 0;
        const values = items.map((value: ILsColumnValue, index: number) => {
            if (index === changedIndex) {
                if (changedItem.sortOrder) {
                    changedItem.sortIndex = sortColumns++;
                }
                return changedItem;
            } else {
                if (value.sortOrder) {
                    value.sortIndex = sortColumns++;
                }
                return value;
            }
        });
        setItems(values);
        props.onValuesChanged?.(JSON.stringify(values));
    }

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        let sortColumns = 0;
        const itemsClone = [...items];
        itemsClone.splice(newIndex, 0, itemsClone.splice(oldIndex, 1)[0]);
        //Fix sorting on re-ordering items
        itemsClone.forEach(item => {
            if (item.sortOrder) {
                item.sortIndex = sortColumns++;
            }
        });

        setItems(itemsClone);
        props.onValuesChanged?.(JSON.stringify(itemsClone));
    };

    const onExpand = (field: string): void => {
        setExpandedFields((prev) => {
            return prev.includes(field) ? prev.filter(i => i !== field) : prev.concat(field);
        });
    }

    return (
        <SortableList
            items={items}
            disabled={props.disabled!}
            height={props.height!}
            onValueChanged={onValueChanged}
            allowedActions={props.allowedActions}
            onSortEnd={onSortEnd}
            useDragHandle
            expandedFields={expandedFields}
            onExpand={onExpand} />
    );
};

const SortableItem = SortableElement<SortableItemProps>((props: SortableItemProps) => {
    return (<ColumnBuilderItem
        index={props.myIndex}
        disabled={props.disabled}
        value={props.value}
        height={props.height}
        onValueChanged={props.onValueChanged}
        allowedActions={props.allowedActions}
        expandedFields={props.expandedFields}
        onExpand={props.onExpand}
    />);
});
const SortableList = SortableContainer<SortableListProps>((props) => {
    return (<Styled.SideMenuList>
        {props.items.map((value: ILsColumnValue, index) => {
            return <SortableItem
                key={`item-${index}`}
                myIndex={index}
                index={index}
                value={value}
                disabled={props.disabled}
                height={props.height}
                allowedActions={props.allowedActions}
                onValueChanged={props.onValueChanged}
                expandedFields={props.expandedFields}
                onExpand={props.onExpand}
            />;
        })}
    </Styled.SideMenuList>);
});

import React, { useState } from 'react';
import Add from '@mui/icons-material/Add';
import QuestionMark from '@mui/icons-material/QuestionMark';
import * as Domain from '@liasincontrol/domain';
import { Button, ElementLabel, IconValue, SVGThumbnail } from '@liasincontrol/ui-basics';
import { ElementAttachIcon, ElementDatasourceIcon, ElementDiagramIcon, ElementGraphBarIcon, ElementHeaderIcon, ElementHtmlIcon, ElementImageIcon, ElementListDropdownIcon, ElementMapIcon, ElementPerformanceIcon, ElementSectieIcon, ElementSubNavigationIcon, ElementTabbladIcon, ElementTableDatabaseIcon, ElementTextBlockIcon, ElementTileMenuIcon, ElementPivotTableIcon } from '@liasincontrol/ui-elements';
import { Popup } from '@liasincontrol/ui-devextreme';
import { PageComponentSelectElement } from '@liasincontrol/ui-elements';
import { UserRightsService } from '@liasincontrol/userrights-service';
import { License, UserIdentity } from '@liasincontrol/auth-service';
import Styled from './index.style';

type PlaceholderGroup = {
    name: string,
    id: number,
};

type PlaceholderGroupItem = {
    label: string,
    elementSystemId: Domain.Shared.SystemElementDefinitions.Pub,
    group?: number,
};

type Props = {
    wrapperId: string,
    direction: Domain.Publisher.Direction,
    groups: PlaceholderGroup[],
    items: PlaceholderGroupItem[],
    title: string,
    publicationElement: Domain.Publisher.PublicationElement,
    onCreateOperation: (elementSystemId: string) => void,
};

/**
 * Represents a UI component that renders a placeholder inside the publication.
 */
const Placeholder: React.FC<Props> = (props) => {
    const [isToolboxVisible, setIsToolboxVisible] = useState<boolean>(false);

    const onItemClick = (elementSystemId: string) => {
        props.onCreateOperation(elementSystemId);
        setIsToolboxVisible(false);
    };

    const content = <div onClick={onShow}>
        <Popup
            width={'70%'}
            showTitle={true}
            title='Elementen toevoegen'
            dragEnabled={false}
            hideOnOutsideClick={true}
            visible={isToolboxVisible}
            onHiding={() => setIsToolboxVisible(false)}
            showCloseButton={true}
            className='ls-popup'
            wrapperAttr={{
                class: 'ls-popup'
            }}
        >
            <PageComponentSelectElement
                id={`page-component-modal-${props.wrapperId}`}
                label='Selecteer icoon'
                direction='horizontal'
                items={props.items}
                groups={props.groups}
                value={''}
                height='100%'
                baseWidth={150}
                baseHeight={150}
                keyExpr='elementSystemId'
                searchEnabled={true}
                searchExpr='label'
                itemRender={(item) => (<Styled.Card role="button" onClick={() => onItemClick(item.elementSystemId)}>
                    <ElementLabel>{item.label}</ElementLabel>
                    <div className="tile-image">
                        <ToolboxThumbnail systemId={item.elementSystemId} publicationElement={props.publicationElement} />
                    </div>
                </Styled.Card>)}
                editorSettings={{
                    disabled: false,
                    validationErrors: [],
                    restrictions: { required: false },

                }} />
        </Popup>
    </div>;

    return (
        <Styled.PlaceholderContainer direction={props.direction}>
            <Styled.PlaceholderAddContainer direction={props.direction}>
                <Button
                    id={`btn-add-component-${props.wrapperId}`}
                    icon={<Add />}
                    btnbase='iconbuttons'
                    btntype='small_fabprimary'
                    title={props.title}
                    onClick={(e) => { e.stopPropagation(); setIsToolboxVisible(true); }}
                />
                {isToolboxVisible && content}
            </Styled.PlaceholderAddContainer>
        </Styled.PlaceholderContainer>
    );
};

const ToolboxIcon = ({ systemId }: {
    systemId: Domain.Shared.SystemElementDefinitions.Pub,
}) => {

    switch (systemId) {
        case Domain.SystemElementDefinitions.Pub.SectionContainer:
            return <ElementSectieIcon />;
        case Domain.SystemElementDefinitions.Pub.AccordionContainer:
            return <ElementListDropdownIcon />;
        case Domain.SystemElementDefinitions.Pub.StackContainer:
            return <ElementSectieIcon />;
        case Domain.SystemElementDefinitions.Pub.TabContainer:
            return <ElementTabbladIcon />;
        case Domain.SystemElementDefinitions.Pub.ImageControl:
            return <ElementImageIcon />;
        case Domain.SystemElementDefinitions.Pub.TextControl:
            return <ElementTextBlockIcon />;
        case Domain.SystemElementDefinitions.Pub.TitleControl:
            return <ElementHeaderIcon />;
        case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
            return <ElementHtmlIcon />;
        case Domain.SystemElementDefinitions.Pub.DataTableControl:
            return <ElementTableDatabaseIcon />;
        case Domain.SystemElementDefinitions.Pub.MenuControl:
            return <ElementListDropdownIcon />;
        case Domain.SystemElementDefinitions.Pub.TileMenuControl:
            return <ElementTileMenuIcon />;
        case Domain.SystemElementDefinitions.Pub.PieChartControl:
            return <ElementDiagramIcon />;
        case Domain.SystemElementDefinitions.Pub.BarChartControl:
            return <ElementGraphBarIcon />;
        case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
            return <ElementAttachIcon />;
        case Domain.SystemElementDefinitions.Pub.DataSourceText:
            return <ElementDatasourceIcon />;
        case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
            return <ElementPerformanceIcon />;
        case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
            return <ElementListDropdownIcon />;
        case Domain.SystemElementDefinitions.Pub.TreeViewControl:
            return <ElementSubNavigationIcon />;
        case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
            return <ElementListDropdownIcon />;
        case Domain.SystemElementDefinitions.Pub.MapControl:
            return <ElementMapIcon />;
        case Domain.SystemElementDefinitions.Pub.PivotTableControl:
            return <ElementPivotTableIcon />;
        case Domain.SystemElementDefinitions.Pub.TabDsControl:
            return <ElementTabbladIcon />;
        case Domain.SystemElementDefinitions.Pub.LineChartControl:
            return <QuestionMark />;

        default:
            return <QuestionMark />;
    }
};

const ToolboxThumbnail = ({ systemId, publicationElement }: {
    systemId: Domain.Shared.SystemElementDefinitions.Pub,
    publicationElement: Domain.Publisher.PublicationElement,
}) => {
    const colors = {
        primaryColor: publicationElement.primaryColor,
        primaryContrastColor: publicationElement.primaryContrastColor,
        textColor: publicationElement.bodyFontColor,
        h1Color: publicationElement.h1FontColor,
        h2Color: publicationElement.h2FontColor,
        h3Color: publicationElement.h3FontColor,
    };

    switch (systemId) {
        //Containers
        case Domain.SystemElementDefinitions.Pub.SectionContainer:
            return <SVGThumbnail variant={IconValue.PcGroup} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.StackContainer:
            return <SVGThumbnail variant={IconValue.PcGroup} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.TabContainer:
            return <SVGThumbnail variant={IconValue.PcTab} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.AccordionContainer:
            return <SVGThumbnail variant={IconValue.PcAccorderon} {...colors} />;
        //Controls
        case Domain.SystemElementDefinitions.Pub.ImageControl:
            return <SVGThumbnail variant={IconValue.PcImage} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.TextControl:
            return <SVGThumbnail variant={IconValue.PcRichText} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.TitleControl:
            return <SVGThumbnail variant={IconValue.PcTitle} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
            return <SVGThumbnail variant={IconValue.PcRichText} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.DataTableControl:
            return <SVGThumbnail variant={IconValue.PcTable} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.MenuControl:
            return <SVGThumbnail variant={IconValue.PcMenu} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.TileMenuControl:
            return <SVGThumbnail variant={IconValue.PcTile} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.PieChartControl:
            return <SVGThumbnail variant={IconValue.PcPieChart} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.BarChartControl:
            return <SVGThumbnail variant={IconValue.PcBarChart} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.LineChartControl:
            return <SVGThumbnail variant={IconValue.PcLineChart} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
            return <SVGThumbnail variant={IconValue.PcAttachment} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.DataSourceText:
            return <SVGThumbnail variant={IconValue.PcTextDataSet} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
            return <SVGThumbnail variant={IconValue.PcTextDataSet} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
            return <SVGThumbnail variant={IconValue.PcAccorderon} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.TreeViewControl:
            return <SVGThumbnail variant={IconValue.PcHierarchy} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
            return <SVGThumbnail variant={IconValue.PcAccorderon} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.MapControl:
            return <SVGThumbnail variant={IconValue.PcMap} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.PivotTableControl:
            return <SVGThumbnail variant={IconValue.PcPivot} {...colors} />;
        case Domain.SystemElementDefinitions.Pub.TabDsControl:
            return <SVGThumbnail variant={IconValue.PcTab} {...colors} />;

        default:
            return <QuestionMark />;
    }
};

export { Placeholder, PlaceholderGroup, PlaceholderGroupItem, ToolboxIcon };

export const getPlaceholderContent = (userIdentity: UserIdentity): PlaceholderGroupItem[] => {
    const licensedContent = [];

    if (UserRightsService.getInstance().userHasLicence(userIdentity, License.Map)) {
        licensedContent.push({ elementSystemId: Domain.SystemElementDefinitions.Pub.MapControl, label: 'Kaart', group: 7 });
    }

    const availablePlaceholders = [
        { elementSystemId: Domain.SystemElementDefinitions.Pub.StackContainer, label: 'Groep', group: 6 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.TabContainer, label: 'Tabblad', group: 6 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.AccordionContainer, label: 'Accordeon', group: 6 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.TitleControl, label: 'Titel', group: 1 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.TextControl, label: 'Tekstblok', group: 1 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.ImageControl, label: 'Afbeelding', group: 2 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.MenuControl, label: 'Menu', group: 3 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.TileMenuControl, label: 'Tegel menu', group: 3 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.DataTableControl, label: 'Tabel uit dataset', group: 4 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.PieChartControl, label: 'Ringdiagram', group: 5 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.BarChartControl, label: 'Staafdiagram', group: 5 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.LineChartControl, label: 'Linediagram', group: 5 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.ReferenceAttachments, label: 'Bijlagen', group: 7 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.DataSourceText, label: 'Tekstblok uit dataset', group: 1 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.TreeViewControl, label: 'Hiërarchie', group: 4 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.AccordionDsControl, label: 'Accordeon uit dataset', group: 7 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.PivotTableControl, label: 'Draaitabel', group: 4 },
        { elementSystemId: Domain.SystemElementDefinitions.Pub.TabDsControl, label: 'Tabbladen uit dataset', group: 7 },
    ]
        .concat(licensedContent);

    return availablePlaceholders;
};

export const placeholderCategories: PlaceholderGroup[] = [
    { id: 0, name: 'Alle elementen' },
    { id: 1, name: 'Tekst' },
    { id: 2, name: 'Afbeelding' },
    { id: 3, name: 'Navigatie' },
    { id: 4, name: 'Tabellen' },
    { id: 5, name: 'Datavisualisatie' },
    { id: 6, name: 'Paginastructuur' },
    { id: 7, name: 'Overig' },
];

const onShow: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
};
import React, { useMemo, useState } from "react";
import { Heading2, PageTitle, Section, Wrapper, WrapperContent, ErrorOverlay, ResetZIndex, FloaterButtons, Heading3 } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { Finance as FinanceDataAccess } from '@liasincontrol/data-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Filter } from "./Filter";
import { BudgetDevelopmentGrid } from "./BudgetDevelopmentGrid";
import { CreateCustomPivotDataSource, CreatePivotDataSource, LoadPanel, LsPivotTable, PivotGridDataSource } from "@liasincontrol/ui-devextreme";
import { BudgetJournalForm } from "../BudgetJournals/BudgetJournalForm";
import { useBudgetJournalGroups, useFinanceSettings, useMeasureMoments, useUsers } from "../../shared/hooks";

type Props = { userIdentity: UserIdentity };

const BudgetDevelopment: React.FC<Props> = (props) => {
    const measureMoments = useMeasureMoments();
    const users = useUsers();
    const settings = useFinanceSettings();
    const budgetJournalGroups = useBudgetJournalGroups();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [data, setData] = useState<Domain.Finance.BudgetDevelopment[]>();
    const [filter, setFilter] = useState<FinanceDataAccess.BudgetDevelopmentFilter>(null);
    const [showGrid, setShowGrid] = useState(false);
    const [showDetails, setShowDetails] = useState<{ visible: boolean, title: string, drillDownDataSource?: PivotGridDataSource }>({ visible: false, title: '', drillDownDataSource: undefined });
    const [divideBy, setDivideBy] = useState(1);
    const [selectedCell, setSelectedCell] = useState<{ rowIndex: number, columnIndex: number, cellElement: HTMLElement }>(undefined);

    const dataSource = useMemo(() => {
        const fields = getColumnConfiguration(divideBy);
        return CreatePivotDataSource({ data, fields })
    }, [data, divideBy]);

    const [formDialog, setFormDialog] = useState<{
        isVisible: boolean,
        isSaving: boolean,
        formMode: FormMode,
        entity?: Domain.Finance.BudgetJournalDetail
    }>({ isVisible: false, isSaving: false, formMode: FormMode.View });

    const filterChanged = (filter: FinanceDataAccess.BudgetDevelopmentFilter) => {
        if (!filter.baseYear) return;
        setLoading(true);
        closeDetails();
        FinanceDataAccess.BudgetDevelopmentDataAccessor.getAll(filter).then((response) => {
            // problem: pivot grid allows sorting only by visible fields, we need to order by measureMomentOrder but show only measureMomentName
            // workaround: concatenate order___name___id into a single field that is sorted as text by the grid and also override the displayed name 
            // where we display only the name
            const newData = response.data.map(item => ({
                ...item,
                measureMomentNameCombined: `${item.measureMomentOrder.toString().padStart(5, '0')}___${item.measureMomentName}___${item.measureMomentId}` as Domain.Finance.MeasureMomentNameCombinedField,
            }));
            setData(newData);
            setFilter(filter);
        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setShowGrid(true);
            setLoading(false);
        });
    };

    const fetchDrillDownData = (filter: FinanceDataAccess.BudgetDevelopmentFilter, measureMomentId: string) => {
        if (!filter.baseYear) return;

        const thenCallBack = (data) => data.map(item => ({
            ...item,
            budgetJournalName: `${item.budgetJournalId}___${item.budgetJournalName}___${item.budgetJournalCode}`
        }));

        const customSource = CreateCustomPivotDataSource<Domain.Finance.BudgetDevelopmentDetail>({
            fields: getColumnConfigurationDrillDown(divideBy),
            dataSourcePromise: FinanceDataAccess.BudgetDevelopmentDataAccessor.getAllByMeasureMoment(filter, measureMomentId),
            thenCallBack
        });
        return customSource;
    };

    const fetchBudgetJournal = (budgetJournalId: string) => {
        FinanceDataAccess.BudgetJournalDataAccessor.get(budgetJournalId).then((response) => {
            setFormDialog({
                ...formDialog,
                isVisible: true,
                isSaving: false,
                entity: response.data,
                formMode: FormMode.View,
            });
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    };

    const onCellClick = (e) => {
        const { rowIndex, columnIndex, cellElement } = e;
        setSelectedCell({ rowIndex, columnIndex, cellElement });

        const parts = getrowPathNameParts(e);

        if (parts) {
            setShowDetails({ title: `${parts[1]} boekingen`, visible: true, drillDownDataSource: fetchDrillDownData(filter, parts[2]) });
        }
    };

    const onDrilldownCellClick = (e) => {
        const parts = getrowPathNameParts(e);
        if (parts) {
            fetchBudgetJournal(parts[0]);
        }
    };

    const closeDetails = () => {
        setShowDetails({ ...showDetails, visible: false });
    };

    const scrollToSelectedCell = () => {
        const pivotGridElement = document.getElementById('overview-financial-development-grid');
        const pivotInstance = (pivotGridElement as any)?.dxPivotGrid?.instance(); // Cast to `any`

        if (pivotInstance) {
            const cellElement = pivotInstance.getCellElement(selectedCell.rowIndex, selectedCell.columnIndex);
            cellElement?.scrollIntoView({ behavior: "smooth", block: "center" });
        }

    };

    return (<Wrapper>
        <WrapperContent>
            <PageTitle>
                <Heading2>Financiële ontwikkeling</Heading2>
            </PageTitle>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Section look='white'>
                    <Filter onFilter={filterChanged} disabled={loading} />
                </Section>
                {showGrid &&
                    <BudgetDevelopmentGrid
                        id='overview-financial-development-grid'
                        dataSource={dataSource}
                        onCellClick={onCellClick}
                        divideBy={divideBy}
                        setDivideBy={setDivideBy}
                        cellSelectionEnabled={true}
                        selectedCellElementBackgroundColor='rgb(224, 226, 255)'
                        selectedCellElementColor='#414141'
                    />
                }
                {showDetails.visible && showDetails.drillDownDataSource &&
                    <Section
                        look='white'
                        withCloseButton={true}
                        onClose={() => {
                            closeDetails();
                            scrollToSelectedCell();
                        }}
                        title={<Heading3>{showDetails?.title}</Heading3>}>
                        <LoadPanel visible={false} />
                        <ResetZIndex>
                            <LsPivotTable
                                dataSource={showDetails.drillDownDataSource}
                                showColumnGrandTotals={false}
                                showColumnTotals={false}
                                onCellClick={onDrilldownCellClick}
                                scrollIntoView={true}
                            />
                            <FloaterButtons />
                        </ResetZIndex>
                    </Section>
                }

                {formDialog.isVisible &&
                    (<BudgetJournalForm
                        disableSaveButton={formDialog.isSaving}
                        formMode={formDialog.formMode}
                        isReadonly={true}
                        userIdentity={props.userIdentity}
                        budgetJournal={formDialog.entity}
                        measureMoments={measureMoments.items}
                        users={users.items}
                        yearsAhead={settings.item.yearsAhead}
                        //onSave={() => { }}
                        onCancel={() => setFormDialog((prev) => ({ ...prev, isVisible: false, }))}
                        // onRefresh={onRefresh}
                        budgetJournalGroups={budgetJournalGroups.items}
                        onError={(err) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err))} />
                    )
                }
            </ErrorOverlay>
        </WrapperContent>
    </Wrapper>);
};

export { BudgetDevelopment as index };

const formatText = (cellInfo, divideBy) => {
    // Format the value using European formatting
    const value = Number(cellInfo.value);
    if (!isNaN(value)) {
        const dividedValue = value / divideBy;
        return dividedValue.toLocaleString('nl-NL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
    return cellInfo.value; // Return original value if it's not a number
};

const getrowPathNameParts = (e) => {
    if ((e.area === 'column') ||
        (e.area === 'row' && e.cell.type === 'GT') ||
        (e.area === 'data' && e.cell.rowType === 'GT'))
        return;

    const path = e.cell.rowPath || e.cell.path;
    const rowPathLength = path.length;
    const rowPathName = path[rowPathLength - 1];
    const rowPathNameParts: string[] = rowPathName.toString().split('___');
    return rowPathNameParts;
};

const getColumnConfiguration = (divideBy) => {
    return [
        {
            dataField: 'budgetYear',
            dataType: 'number',
            area: 'column',
        },
        {
            dataField: 'reserveOperation',
            dataType: 'string',
            area: 'column',
        },
        {
            dataField: 'costBenefit',
            dataType: 'string',
            area: 'column',
        },
        {
            dataField: 'measureMomentNameCombined',
            area: 'row',
            sortOrder: 'asc',
            width: 300,
            customizeText: (e: { value: Domain.Finance.MeasureMomentNameCombinedField }) => {
                //override the displayed name only, at this point the data is already sorted by the grid
                const parts: string[] = e.value.split('___');
                return parts[1];
            },
        },
        {
            dataField: 'amount',
            dataType: 'number',
            area: 'data',
            summaryType: 'sum',
            customizeText: (cellInfo) => formatText(cellInfo, divideBy),
        },
    ];
};

const getColumnConfigurationDrillDown = (divideBy) => {
    return [
        {
            dataField: 'budgetYear',
            dataType: 'number',
            area: 'column',
        },
        {
            dataField: 'reserveOperation',
            dataType: 'string',
            area: 'column',
        },
        {
            dataField: 'costBenefit',
            dataType: 'string',
            area: 'column',
        },
        {
            dataField: 'budgetJournalName',
            area: 'row',
            sortOrder: 'asc',
            width: 300,
            customizeText: (e: { value: string }) => {
                //override the displayed name only, at this point the data is already sorted by the grid
                const parts: string[] = e.value.split('___');
                return parts[1];
            },
        },
        {
            dataField: 'amount',
            dataType: 'number',
            area: 'data',
            summaryType: 'sum',
            customizeText: (cellInfo) => formatText(cellInfo, divideBy),
        }
    ];
}
import React, { useState, useEffect, useReducer } from 'react';
import { Dictionary } from 'lodash';
import Refresh from '@mui/icons-material/Refresh';
import * as Domain from '@liasincontrol/domain';
import { ActionType, Actions } from '@liasincontrol/userrights-service';
import { DeviceFrame, DeviceType, EditingToolbar, PreviewToolbar, ReadonlyToolbar, ErrorOverlay, ModalDialog, Button, IDataItemProps } from '@liasincontrol/ui-basics';
import { ActionSource } from '@liasincontrol/redux-service';
import { Settings } from './Settings';
import { PageLayoutCol, PageLayoutGrid } from '../../../../../_shared/EditorLayoutGrid';
import { Navigation } from './Navigation';

export enum ContentEditorState {
    None = 0,
    Preview = 1,
    Editing = 2
}

enum EdgeToolbarAction {
    ToggleSetting = 'toggleSettings',
    ToggleSideNavigation = 'toggleSideNavigation',
    OpenSettings = 'openSettings',
    OpenSideNavigation = 'openSideNavigation',
    ReopenPanel = 'reOpenPanel'
}

type EdgeToolbarState = {
    isOpen: boolean,
    isSettingsVisible: boolean,
    isSideNavigationVisible: boolean,
};

type Props = {
    // Identifiers
    publicationId: string,
    selectedElementId: string,
    selectedPageId: string,
    currentUserId: string,

    // States
    editingState: ContentEditorState,
    isProcessing: boolean,
    publishProfilesChanged: boolean,
    disabled?: boolean,

    // Elements and Definitions
    elementdefinitions: Record<string, Record<string, Domain.Shared.ElementDefinition>>,
    selectedElement?: Domain.Publisher.Element,
    validElement?: { message: string, isValid: boolean },
    variables: Domain.Shared.ComplexFieldItem[],
    editableElementIds?: Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any, componentHasDiscussion: boolean }>,
    templateOperations: Domain.Publisher.Operation[],

    // Sitemap and Hierarchies
    sitemap: Domain.Publisher.Sitemap,
    hierarchies: Domain.Shared.HierarchyMap,
    hierarchyDefinitions: Dictionary<Domain.Shared.HierarchyDefinition[]>,

    // Events and Audits
    auditEvents?: { content: Domain.Shared.AuditEvent[] },
    error: Domain.Shared.ErrorInfo,
    measureMoments: Domain.Shared.MeasureMoment[],
    statusAuditEvents?: Domain.Shared.WorkflowAuditEvent[],

    // Workflow
    userWorkflowTasks?: Domain.Publisher.WorkflowTask[],
    publicationWorkflowStates?: Domain.Publisher.TaskManagerWorkflowState[],
    componentWorkflowTasks?: Domain.Publisher.WorkflowTask[],

    // Profiles
    availablePublishProfiles: IDataItemProps<string>[],
    selectedPublishProfiles: IDataItemProps<string>[],

    // Device
    device: DeviceType,
    onChangeDeviceType: (device: DeviceType) => void,

    // Callbacks and Event Handlers
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onRemoveAttachment: (attachmentId: string) => void,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onFieldsChanged: (changes: Domain.Publisher.FieldPatch[]) => void,
    onComplexFieldChanged: (change: Domain.Publisher.ComplexFieldPatch) => void,
    onEditingStateChanged: (state: ContentEditorState) => void,
    onCancel: () => void,
    onSaveChanges: () => void,
    onCancelChanges: () => void,
    onResetError?: () => void,
    onRetryError?: () => void,
    onToggleTaskStatus?: (workflowTask: Domain.Publisher.WorkflowTask, completeTask: boolean) => void,
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    onUpdateControlPublishProfiles: (publishProfiles: IDataItemProps<string>[]) => void,
    canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
    onRefresh: () => void,
    onAuditRefresh?: () => void,
    onChangeElementState: (elementId: string, stateId: string, userIds: string[], remark: string) => Promise<boolean>,

    // Additional Properties
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders the content editor of the publication.
 */
export const ContentEditor: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [hasErrorsInSettings, setHasErrorsInSettings] = useState<boolean>(false);
    const [edgeToolbarState, edgeToolbarStateDispatch] = useReducer(edgeToolbarStateReducer, { isOpen: true, isSettingsVisible: false, isSideNavigationVisible: true } as EdgeToolbarState);
    const [types, setTypes] = useState<IDataItemProps<string>[]>([]);

    useEffect(() => {
        edgeToolbarStateDispatch(EdgeToolbarAction.ReopenPanel);
    }, [props.selectedElementId, props.editableElementIds[props.selectedElementId]]);

    useEffect(() => {
        if (props.selectedElement) {
            edgeToolbarStateDispatch(EdgeToolbarAction.OpenSettings);
        } else {
            edgeToolbarStateDispatch(EdgeToolbarAction.OpenSideNavigation);
        }
    }, [props.selectedElement]);

    const getElementDefinition = (systemId: string, elementDefinitionId?: string): Domain.Shared.ElementDefinition => {
        if (!props.elementdefinitions[ActionSource.Publication]) {
            return null;
        }

        if (elementDefinitionId) {
            return props.elementdefinitions[ActionSource.Publication][elementDefinitionId];
        }

        return Object.values(props.elementdefinitions[ActionSource.Publication]).find((definition) => definition.systemId === systemId);
    };

    const getDefinition = (id: string, source: ActionSource): Domain.Shared.ElementDefinition => {
        if (!props.elementdefinitions[source]) {
            return null;
        }

        return Object.values(props.elementdefinitions[source]).find((definition) => definition.id === id);
    };

    // Get available types based on hierarchy def and element def
    const getAvailableHierarchyTypes = (items: Domain.Shared.HierarchyLinkDefinition[], source: ActionSource) => {
        if (!items || !source) return;
        const usedElementDefinitionIds: string[] = items
            ?.filter((hierarchyDefinitionLink) => !hierarchyDefinitionLink.fromElementDefinitionId)
            ?.map((hierarchyDefinitionLink) => hierarchyDefinitionLink.toElementDefinitionId);

        const availableTypes = Object.values(props.elementdefinitions[source])
            .filter((elementDefinition) => usedElementDefinitionIds.indexOf(elementDefinition.id) >= 0)
            .map((elementDefinition) => {
                return {
                    value: elementDefinition.id,
                    label: elementDefinition.name
                };
            });
        setTypes(availableTypes);
    };

    const getEdgeToolbarElement = (): JSX.Element => {
        // Don't render the left-side Settings toolbar while displaying an error, so we don't get the error behind it.
        if (props.error) {
            return null;
        }

        if (!props.selectedElement) {
            return (
                <Navigation
                    sitemap={props.sitemap}
                    tasks={props.userWorkflowTasks}
                    isVisible={edgeToolbarState.isOpen && edgeToolbarState.isSideNavigationVisible}
                    onToggleVisibility={() => edgeToolbarStateDispatch(EdgeToolbarAction.ToggleSideNavigation)} />
            );
        }

        const controlTask = props.componentWorkflowTasks ? props.componentWorkflowTasks.find(item => (item.taskIsActive === true && item.userId === props.currentUserId)) : undefined;

        return (
            <Settings
                publicationId={props.publicationId}
                selectedElement={props.selectedElement}
                selectedPageId={props.selectedPageId}
                userControlTask={controlTask}
                isVisible={edgeToolbarState.isOpen && edgeToolbarState.isSettingsVisible}
                isDesigner={false}
                auditEvents={props.auditEvents}
                editableElementIds={props.editableElementIds}
                templateOperations={props.templateOperations}
                isProcessing={props.isProcessing}
                elementTypes={types}
                measureMoments={props.measureMoments}
                availablePublishProfiles={props.availablePublishProfiles}
                selectedPublishProfiles={props.selectedPublishProfiles}
                sitemap={props.sitemap}
                getElementDefinition={getElementDefinition}
                onUploadAttachment={props.onUploadAttachment}
                onLoadAttachment={props.onLoadAttachment}
                onRemoveAttachment={props.onRemoveAttachment}
                onSingleFieldChanged={props.onFieldsChanged}
                onComplexFieldChanged={props.onComplexFieldChanged}
                onErrorsInSettings={setHasErrorsInSettings}
                onToggleVisibility={() => edgeToolbarStateDispatch(EdgeToolbarAction.ToggleSetting)}
                onToggleTaskStatus={props.onToggleTaskStatus}
                onUpdateControlPublishProfiles={props.onUpdateControlPublishProfiles}
                hierarchies={props.hierarchies}
                loadPerformanceHierarchy={props.loadPerformanceHierarchy}
                canPerformAction={props.canPerformAction}
                getDefinition={getDefinition}
                hierarchyDefinitions={props.hierarchyDefinitions}
                getAvailableHierarchyTypes={getAvailableHierarchyTypes}
                loadStudioHierarchy={props.loadStudioHierarchy}
                publicationWorkflowStates={props.publicationWorkflowStates}
                componentWorkflowTasks={props.componentWorkflowTasks}
                onChangeElementState={props.onChangeElementState}
                statusAuditEvents={props.statusAuditEvents}
                currentUserId={props.currentUserId}
                onAuditRefresh={props.onAuditRefresh}
                icons={props.icons}
                variables={props.variables}
            />
        );
    };

    // default value = don't allow editing.
    const canEditControl = (props.selectedElement && props.editableElementIds[props.selectedElement.elementId] && props.editableElementIds[props.selectedElement.elementId].editable) || false;

    const edgeToolbar = getEdgeToolbarElement();
    const elementIsEditable = props.selectedElement && Object.keys(props.editableElementIds).some((key) => key === props.selectedElement.elementId && props.editableElementIds[key] && props.editableElementIds[key].editable);

    const getToolbarsElement = (
        isEditingToolbarVisible: boolean,
        isPreviewToolbarVisible: boolean,
        isReadonlyToolbarVisible: boolean,
        onSaveChanges: () => void,
        onCancelChanges: () => void,
        onCancel: () => void,
        onEditingStateChanged: (state: ContentEditorState) => void,
    ): JSX.Element => {
        return (
            <>
                <EditingToolbar
                    look='editor'
                    isValid={!hasErrorsInSettings && (props.validElement?.isValid || true)}
                    disabled={!props.validElement.isValid || props.disabled}
                    showSaveButton={canEditControl || props.publishProfilesChanged}
                    isVisible={isEditingToolbarVisible}
                    onSave={() => { onSaveChanges(); }}
                    onCancel={() => { onCancelChanges(); }}
                    readonly={!elementIsEditable && !props.publishProfilesChanged}
                />

                <PreviewToolbar
                    isVisible={isPreviewToolbarVisible}
                    look='editor'
                    device={props.device}
                    onCancel={() => {
                        props.onChangeDeviceType('desktop');
                        onEditingStateChanged(ContentEditorState.Editing)
                    }}
                    onDeviceChanged={props.onChangeDeviceType} />

                <ReadonlyToolbar
                    isVisible={isReadonlyToolbarVisible}
                    onClose={onCancel}
                    onPreview={() => onEditingStateChanged(ContentEditorState.Preview)}
                    endActions={<Button id="btn-preview" btnbase="iconbuttons" btntype="medium_background" icon={<Refresh />} onClick={props.onRefresh} />}
                />
            </>);
    };

    const toolbars = getToolbarsElement(!!props.selectedElement, !props.selectedElement && props.editingState === ContentEditorState.Preview,
        !props.selectedElement && props.editingState !== ContentEditorState.Preview,
        props.onSaveChanges, props.onCancelChanges, props.onCancel, props.onEditingStateChanged);

    return (<>
        <ModalDialog customPadding edgeToolbar={edgeToolbar} toolbars={toolbars}>
            <PageLayoutGrid wide={!edgeToolbarState.isOpen || (!edgeToolbarState.isSideNavigationVisible && !edgeToolbarState.isSettingsVisible)}>
                <DeviceFrame device={props.device} position='center' />
                <PageLayoutCol className={props.device} pose={props.device} withParent={false}>
                    <ErrorOverlay
                        error={props.error?.message}
                        errorDetails={props.error?.details}
                        onRetry={props.error?.canRetry && props.onRetryError ? props.onRetryError : null}
                        onBack={props.error?.canGoBack && props.onResetError ? props.onResetError : null}>
                        {props.children}
                    </ErrorOverlay>
                </PageLayoutCol>
            </PageLayoutGrid>
        </ModalDialog>
    </>
    );
};

const edgeToolbarStateReducer = (prevState: EdgeToolbarState, action: EdgeToolbarAction) => {
    if (!action)
        return prevState;

    const newState: EdgeToolbarState = { ...prevState };
    switch (action) {
        case EdgeToolbarAction.OpenSettings:
            newState.isSettingsVisible = true;
            newState.isSideNavigationVisible = false;
            break;
        case EdgeToolbarAction.OpenSideNavigation:
            newState.isSettingsVisible = false;
            newState.isSideNavigationVisible = true;
            break;
        case EdgeToolbarAction.ToggleSetting:
            newState.isSettingsVisible = !prevState.isSettingsVisible;
            newState.isOpen = !prevState.isOpen;
            break;
        case EdgeToolbarAction.ToggleSideNavigation:
            newState.isSideNavigationVisible = !prevState.isSideNavigationVisible;
            newState.isOpen = !prevState.isOpen;
            break;
        case EdgeToolbarAction.ReopenPanel:
            newState.isOpen = true;
            break;
    }

    return newState;
};

import React from 'react';
import { SectionHeader } from '@liasincontrol/ui-basics';
import { LsColumnBuilder } from '@liasincontrol/ui-devextreme';
import { defaultAllowedAction, Props } from '.';

/**
 * Represents a UI component that renders a column builder editor.
 */

export const ColumnBuilderEditor: React.FC<Props> = (props) => {
    return (
        <>
            <SectionHeader>{props.label}</SectionHeader>
            <LsColumnBuilder
                values={props.value}
                disabled={props.editorSettings?.disabled}
                onValuesChanged={props.editorSettings?.onChange}
                allowedActions={props.allowedActions || defaultAllowedAction}
            />
        </>
    );
};

import React, { useCallback } from 'react';
import { withField } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import { DropDownOptions, DxDataSource } from '../..';

export const LookupField = withField<{
    displayExpr: string | ((item) => string),
    placeholder?: string,
    value?: any,
    dataSource?: DxDataSource | any[],
    items?: any[],
    isDisabled?: boolean,
    isClearable?: boolean,
    noDataText?: string,
    valueExpr?: string | ((item: any) => string),
    itemRender?: (props) => JSX.Element,
}>(
    ({ id,
        onChange,
        onBlur,
        value: {
            displayExpr, value, dataSource, items, placeholder, isDisabled, isClearable, noDataText, valueExpr, itemRender
        },
    }) => {
        return (
            <Styled.StyledLookup
                id={id}
                displayExpr={displayExpr}
                valueExpr={valueExpr}
                value={value}
                disabled={isDisabled}
                placeholder={placeholder ?? 'Kies…'}
                items={items}
                dataSource={dataSource}
                onValueChanged={onChange && useCallback(
                    (value) => onChange({ displayExpr, value, dataSource, items, placeholder, isDisabled, isClearable, noDataText, valueExpr, itemRender }),
                    [onChange, displayExpr, value, dataSource, items, placeholder, isDisabled, isClearable, noDataText, valueExpr, itemRender],
                )}
                noDataText={noDataText}
                height='51'
                cancelButtonText='ANNULEREN'
                clearButtonText='VERWIJDEREN'
                searchPlaceholder='Zoeken...'
                pageLoadingText='Laden...'
                itemRender={itemRender}
                searchEnabled={true}
                showCancelButton={true}
                showClearButton={isClearable}
                stylingMode='outlined'
                wrapItemText={true}
                dropDownCentered={false}
                onFocusOut={onBlur}
            >
                <DropDownOptions
                    showTitle={false}
                />
            </Styled.StyledLookup>
        )
    }
);

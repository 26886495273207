import { JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ILsColumnProps, ILsColumnValue, ILsGroupItem, ILsSummaryItem, LsBaseField, noColumnNameGroupCellTemplate, GroupRenderMode, renderModeAvailable, PivotGridFieldType } from '@liasincontrol/ui-devextreme';
import { dxDataGridColumn } from 'devextreme/ui/data_grid';
import { SortDescriptor } from 'devextreme/data';
import _ from 'lodash';

/**
 * Represents an utils helper class for the DataTable and PieChart controls.
 */
class DataSourceControlsUtils {
    /**
     * Returns the default column alignment of a devexpress grid depending on the data type.
     * @param dataType Defines the column data type.
     */
    public static getDefaultDxAlignment = (dataType: string) => {
        let defaultAlignment: 'center' | 'left' | 'right' | undefined;
        switch (dataType) {
            case 'number':
                defaultAlignment = 'right';
                break;
            case 'boolean':
                defaultAlignment = 'center';
                break;
            case 'string':
            case 'date':
            case 'datetime':
                defaultAlignment = 'left';
                break;
            default:
                defaultAlignment = 'center';
                break;
        }
        return defaultAlignment;
    };

    /**
     * Maps the dataSource data types to devexpress data types.
     *
     * @param item Defines the dataSource data element.
     */
    public static mapDataSourceToDxType = (item: DataSourceElement, useDateType?: boolean): 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' => {
        return DataSourceControlsUtils.mapStringToDxType(item.type, item.format && item.format === `date-time`, useDateType);
    };

    /**
     * Maps a string to devexpress data types.
     * @param value Defines the string to get a devexpress data type for. 
     * @param isDateTime Defines a flag for datetime values.
     */
    public static mapStringToDxType = (value: string, isDateTime = false, useDateType = false): 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' => {
        let rightType: 'string' | 'number' | 'boolean' | 'object' | 'date' | 'datetime';
        switch (value) {
            case 'string':
            case 'number':
            case 'boolean':
            case 'object':
            case 'date':
            case 'datetime':
                if (isDateTime) {
                    rightType = useDateType ? 'date' : 'datetime';
                } else {
                    rightType = value;
                }
                break;
            case 'integer':
            case 'float':
            case 'bigint':
            case 'double':
                rightType = 'number';
                break;
            default:
                rightType = 'string';
                break;
        }
        return rightType;
    };

    /**
     * Maps a collection of DevExpress dataSource items from the LIAS dataSource.
     * @param dataSourceData Defines the collection of data from the datasource.
     * @param numberOfVisibleColumns Defines the number of the first visible columns for the grid.
     */
    public static mapsDataSourceColumnFields = (dataSourceData: DataSourceSchemaData, numberOfVisibleColumns?: number, defaultColumns = false, useDateType = false, combineColumns = true): ILsColumnProps[] => {
        if (!dataSourceData?.items?.properties) {
            return [];
        }

        const fieldlist: ILsColumnProps[] = [];
        const { properties } = dataSourceData.items;

        let i = 0;
        Object.keys(properties).forEach((key: string) => {
            const fieldDescriptor = properties[key];
            const dxDataType = DataSourceControlsUtils.mapDataSourceToDxType(fieldDescriptor, useDateType);

            const isVisible = defaultColumns
                ? defaultVisibleColumns.includes(key)
                : numberOfVisibleColumns
                    ? i < numberOfVisibleColumns
                    : false;

            const field: ILsColumnProps = {
                dataField: key,
                caption: key,
                dataType: dxDataType,
                visible: isVisible,
                alignment: DataSourceControlsUtils.getDefaultDxAlignment(dxDataType),
                format: dxDataType === 'datetime' ? dateFormat : fieldDescriptor.format,
            };

            if (combineColumns) {
                field.caption = renderModeAvailable.includes(fieldDescriptor.propertyGroupKind) ? fieldDescriptor.propertyGroupName || '' : key;
                field.dataType = renderModeAvailable.includes(fieldDescriptor.propertyGroupKind) ? 'string' : dxDataType;
                field.propertyGroupName = fieldDescriptor.propertyGroupName || '';
                field.propertyGroupKind = fieldDescriptor.propertyGroupKind || '';

                if (fieldDescriptor.propertyGroupName && renderModeAvailable.includes(fieldDescriptor.propertyGroupKind)) {
                    for (const suffix of groupedColumnSuffixes) {
                        if (key.endsWith(suffix)) {
                            field.dataField = key.slice(0, key.length - suffix.length);
                            field.groupRenderMode = GroupRenderMode.Name;
                            break;
                        }
                    }
                }

                const fieldIndex = fieldlist.findIndex(f => renderModeAvailable.includes(f.propertyGroupKind) && f.propertyGroupName === field.propertyGroupName);

                if (fieldIndex >= 0) {
                    fieldlist[fieldIndex] = { ...fieldlist[fieldIndex], ...field };
                } else {
                    fieldlist.push(field);
                    i++;
                }
            }
            else {
                fieldlist.push(field);
                i++;
            }
        });

        // Sort only if defaultColumns are visible => first load
        const returnFields = defaultColumns
            ? fieldlist.sort((s1, s2) => defaultVisibleColumns.indexOf(s1.dataField) - defaultVisibleColumns.indexOf(s2.dataField))
            : fieldlist;

        return returnFields;
    };

    /**
     * Maps a collection of devexpress dataSource items from the LIAS dataSource.
     * @param dataSourceData Defines the collection of data from the datasoure.
     */
    public static mapsDataSourceBaseFields = (dataSourceData: DataSourceSchemaData): LsBaseField[] => {
        if (dataSourceData?.items?.properties) {
            const fieldlist: LsBaseField[] = [];
            Object.keys(dataSourceData.items.properties).forEach((key: string) => {
                const dxDataType = DataSourceControlsUtils.mapDataSourceToDxType(dataSourceData.items.properties[key]);
                fieldlist.push({
                    dataField: key,
                    dataType: dxDataType,
                });
            });
            return fieldlist;
        } else {
            return [];
        }
    };

    /**
     * Refresh column settings data in case the datasource data has changed.
     *
     * @param dataSourceHasChanged Determines if the datasourse has changed.
     * @param columnSettings Defines the current data table column settings data.
     * @param dataSourceData Defines the collection of data from the datasoure.
     * @param numberOfVisibleColumns Defines the number of the first visible columns for the grid.
     */
    public static refreshColumnSettings = (
        dataSourceHasChanged: boolean,
        columnSettings: ILsColumnValue[],
        dataSourceSchema: DataSourceSchemaData,
        numberOfVisibleColumns: number,
        defaultColumns = false
    ): ILsColumnValue[] => {
        const schemaFields = DataSourceControlsUtils.mapsDataSourceColumnFields(dataSourceSchema, numberOfVisibleColumns, defaultColumns);
        if (dataSourceHasChanged) {
            return schemaFields;
        } else {
            const refreshedColumnSettings: ILsColumnValue[] = [];
            columnSettings.forEach((column: ILsColumnValue) => {
                const dataSourceField = schemaFields.find((field: ILsColumnProps) => field.dataField === column.dataField);
                if (dataSourceField) {
                    if (dataSourceField.dataType === column.dataType) {
                        refreshedColumnSettings.push(column);
                    } else {
                        refreshedColumnSettings.push(dataSourceField);
                    }
                }
            });
            schemaFields.forEach((field: ILsColumnProps) => {
                const dataSourceField = columnSettings.find((column: ILsColumnValue) => column.dataField === field.dataField);
                if (dataSourceField === undefined) {
                    refreshedColumnSettings.push(field);
                }
            });

            return refreshedColumnSettings;
        }
    };

    /**
     * Returns a valid parsed dataSource filter.
     * @param filter Defines the raw filter.
     */
    public static getDataSourceFilter = (filter: string, variables?: Domain.Shared.ComplexFieldItem[]): (string | string[])[] | undefined => (filter && filter !== `[]` && filter !== `null` && filter !== `` ? JsonUtils.toJson(this.replaceVariables(filter, variables || [])) : undefined);

    /**
     * Determines if the piechart series fields are part of the dataSource field set.
     * @param value Defines the piechart series value field.
     * @param argument Defines the piechart series argument field.
     * @param dataSourceFields Defines the collection of dataSource fields.
     */
    public static checkDataSourceForSeriesItems = (value: string, argument: string, dataSourceFields: ILsColumnProps[]): boolean => {
        return [argument, value].every((seriesItem: string) => dataSourceFields.map((field: ILsColumnProps) => field.dataField).includes(seriesItem));
    };

    /**
     * Returns the formated devexpress piechart tooltip text.
     * @param targetItem Defines the selected piechart item.
     */
    public static setPieChartTooltipText = (targetItem: { argumentText: string; valueText: string }) => ({ text: `${targetItem.argumentText} - ${targetItem.valueText}` });

    /**
     * Gets the filter object.
     * @param filter Defines the filter as a text.
     */
    public static getFilter = (filter: string, variables?: Domain.Shared.ComplexFieldItem[]): (string | string[])[] | undefined => (JsonUtils.checkJSONArray(filter) ? JSON.parse(this.replaceVariables(filter, variables || [])) : undefined);

    /**
     * Gets all the DataTable column data.
     *
     * @param columnsSettings Defines the column settings data.
     */
    public static getColumnData = (columnsSettings: ILsColumnValue[]): ColumnData => {

        const columnData: ColumnData = { columns: [], groups: [], summaryItems: [] };

        columnData.columns = DataSourceControlsUtils.getColumns(columnsSettings);

        columnData.groups = columnData.groups.concat(
            columnsSettings
                .filter((column: ILsColumnValue) => column.showGroupHeaderSummary === true)
                .map((column: ILsColumnValue) => ({
                    column: column.dataField,
                    valueFormat: getColumnFormat(column),
                    alignByColumn: true,
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    divide: column.divide,
                }))
        );

        columnData.groups = columnData.groups.concat(
            columnsSettings
                .filter((column: ILsColumnValue) => column.showGroupFooterSummary === true)
                .map((column: ILsColumnValue) => ({
                    column: column.dataField,
                    valueFormat: getColumnFormat(column),
                    alignByColumn: true,
                    summaryType: 'sum',
                    showInGroupFooter: true,
                    divide: column.divide,
                }))
        );

        columnData.summaryItems = columnsSettings
            .filter((column: ILsColumnValue) => column.showTotalsSummary === true)
            .map((column: ILsColumnValue) => ({
                column: column.dataField,
                alignment: column.alignment,
                valueFormat: getColumnFormat(column),
                summaryType: 'sum',
                divide: column.divide,
            }));

        return columnData;
    };

    public static getColumns = (columnsSettings: ILsColumnValue[]): ILsColumnProps[] => {
        const withGroupInfo = columnsSettings.some(column => column.groupIndex !== undefined);

        return columnsSettings.map((column: ILsColumnValue) => {
            const groupInfo = (withGroupInfo ? {
                groupIndex: column.groupIndex,
                autoExpandGroup: !!column.autoExpandGroup,
                groupCellTemplate: column.hasOwnProperty('groupIndex') ? noColumnNameGroupCellTemplate : undefined,
            } : undefined);

            return {
                dataField: column.dataField,
                dataType: column.dataType,
                alignment: column.alignment,
                visible: column.visible,
                format: getColumnFormat(column),
                groupRenderMode: column.groupRenderMode,
                divide: column.divide,
                caption: column.caption,
                propertyGroupName: column.propertyGroupName,
                propertyGroupKind: column.propertyGroupKind,
                width: column.width,
                cssClass: column.cssClass,
                minWidth: column.minWidth,
                sortIndex: column.sortIndex,
                sortOrder: column.sortOrder,
                columnWidth: column.columnWidth,
                ...groupInfo
            };
        });
    };

    /**
   * Gets all the PivotTable fields data (all 3 areas).
   *
   * @param fields Defines the fields settings data.
   */

    public static getFieldData = (fields: PivotGridFieldType[], isExpanded = false) => {
        const columnData: PivotGridFieldType[] = [
            ...fields?.map((field) => ({
                caption: field.caption,
                width: field.width,
                dataField: field.dataField,
                dataType: field.dataType.toString() === 'datetime' ? 'date' : field.dataType,
                area: field.area,
                summaryType: field.summaryType || 'sum',
                allowSorting: true,
                sortOrder: field.sortOrder,
                wordWrapEnabled: true,
                format: getColumnFormat(field),
                divide: field.divide,
                expanded: isExpanded,
            })),
        ];

        return columnData;
    };

    /**
     * Determines if the datasource schema has specific columns.
     * @param dataSourceData Defines the collection of data from the datasoure.
     * @param requiredColumns Defines the reuired colum names array.
     */
    public static hasDataSourceRequiredColumns = (dataSourceData: DataSourceSchemaData, requiredColumns: string[] = []): boolean => {
        if (!requiredColumns.length) { return true; }
        if (dataSourceData?.items?.properties) {
            const allKeys = Object.keys(dataSourceData.items.properties);
            const allFounded = requiredColumns.every(required => allKeys.includes(required));

            return allFounded;
        } else {
            return false;
        }
    };

    /**
     * Cleanup filter settings if not fit with schema
     */
    public static refreshFilterSettings = (
        filterValue: (string | string[])[],
        columnsSettings?: ILsColumnValue[],
    ): any[] => {

        if (!columnsSettings || columnsSettings.length === 0 || !filterValue) return [];

        const fieldInSchema = (filterExpr) => {
            return columnsSettings.some((field: ILsColumnProps) => field.dataField === filterExpr[0]);
        }

        const cleanValue = (value, isCorrect) => {
            if (value && Array.isArray(value[0])) {
                return value.flatMap((item) => (
                    Array.isArray(item[0])
                        ? cleanValue(item, isCorrect)
                        : (Array.isArray(item) ? fieldInSchema(item) : true /* group operations? */)
                )
                );
            }
            return [fieldInSchema(value)];
        }
        const checkFilterValue = cleanValue(filterValue, true);
        const hasIncorrectCondition = checkFilterValue.some(a => !a);
        return hasIncorrectCondition ? [] : filterValue;
    };

    /**
     * Gets the sortexpression object.
     * @param columnsSettings Defines the column settings data.
     */
    public static extractSortExpressionFromColumns = (columnsSettings: ILsColumnValue[]): SortDescriptor<ILsColumnValue>[] => {
        const sortExpression = columnsSettings.reduce((acc: SortDescriptor<ILsColumnValue>[], column: ILsColumnValue) => {
            if (!column.sortOrder) {
                return acc;
            }
            return [...acc, {
                selector: column.dataField,
                desc: column.sortOrder === 'desc',
            }];
        }, []);

        return sortExpression;
    }

    private static replaceVariables(inputString: string, variables: Domain.Shared.ComplexFieldItem[]): string {
        let result = inputString;

        variables.forEach(variable => {
            const searchTerm = `"$${variable.name}"`;
            result = result.replace(searchTerm, `"${_.isEmpty(variable.value) ? variable.original : variable.value}"`);
        });

        return result;
    }
}

type ColumnData = {
    columns: ILsColumnProps[] | undefined,
    groups: ILsGroupItem[] | undefined,
    summaryItems: ILsSummaryItem[] | undefined,
};

type DataSourceSchemaData = {
    '$schema': string,
    type: string,
    items: {
        properties: { [key: string]: DataSourceElement },
        type: string,
    };
};

type DataSourceElement = {
    type: string,
    format?: string,
    minimal?: number,
    maximum?: number,
    propertyGroupName?: string,
    propertyGroupKind?: string,
};

const getColumnFormat = (column: dxDataGridColumn): string => {
    if (!column) {
        return;
    }
    switch (column.dataType) {
        case 'datetime':
        case 'date':
            return dateFormat;
        case 'number':
            return column.format ? column.format as string : numberFormat;
        default:
            return column.format as string;
    }
};

export const groupedColumnSuffixes = [
    'Value',
    'Name',
    'Number',
    'Color',
    'Icon'
];

const dateFormat = 'dd-MM-yyyy';
const numberFormat = '#0,###.##';

const defaultVisibleColumns = ['Nummer', 'Naam'];

export { DataSourceControlsUtils, DataSourceSchemaData };

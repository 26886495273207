import React, { useEffect, useMemo, useState } from 'react';
import TextBox from "devextreme-react/text-box";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { AnyFormData, AttachmentValidator, FormHelper, FormInfo, FormMode, IconHelper, NumberValidator, TextValidator, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { CardHeaderTab, IconSize, Label, WarningWrapper, WarningLook, SVGIcon } from '@liasincontrol/ui-basics';
import { DecimalElement, MultiLineTextElement, SelectElement, TextElement, AttachmentElement, SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import { IconHelper as PerformanceIconHelper } from '../../../helpers/IconHelper';
import { HierarchyItemAttachmentItem } from './HierarchyItemAttachmentItem';
import { HierarchyItemAuditTrail } from '../HierarchyItemAuditTrail';
import { HierarchyItemContributors } from '../HierarchyItemContributors';
import Styled from './index.styled';

enum CardTabs {
    Details = "Details",
    History = "Historie",
    Contributors = "Toegang"
}

type Props = {
    fields: FormInfo<ValueType>,
    audit: Domain.Dto.Shared.AuditEvent[],
    users: Domain.Shared.User[],
    elementDefinition: Domain.Shared.ElementDefinition,
    workflowTemplate?: Domain.Shared.WorkflowTemplateWithStates,
    isMeasureMomentClosed: boolean,
    hierarchyRights?: Domain.Performance.HierarchyItemRight[],
    mode: FormMode,
    onAssignContributors: (contributors: Record<string, boolean>) => void,
    onFieldsDataChanged: (fields: FormInfo<ValueType>) => void,
    onRemoveAttachment: (attachmentId: string) => void,
    onDownloadAttachment: (attachmentId: string) => void,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onStartMultipleUpload: () => void,
    onFinishMultipleUpload: () => void,
    onWorkflowStateChanged: (workflowState: Domain.Shared.AbstractElementWorkflowStatus) => void,
    setSelectedFieldId: (fieldId: string) => void,
    leaseInfo?: Domain.Shared.AcquireLease,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders an entity form of a performance hierarchy item.
 */
const HierarchyItemForm: React.FC<Props> = (props) => {
    const attachmentsComplexFieldDefinition = props.elementDefinition.complexFields.find(item => item.systemId === Domain.SystemFieldDefinitions.Performance.Attachment);
    const attachmentsFileFieldDefinition = attachmentsComplexFieldDefinition.fields.find(item => item.systemId === Domain.SystemFieldDefinitions.Performance.AttachmentFile);

    const fieldDefinitions: Record<string, Domain.Shared.FieldDefinition> = [...props.elementDefinition.fields, attachmentsFileFieldDefinition].reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    );

    const [form, setForm] = useState<AnyFormData>(initFormData(props.fields));
    const [selectedCardTab, setSelectedCardTab] = useState<CardTabs>(CardTabs.Details);
    const [maximizedId, setMaximizedId] = useState<string>();

    const sortedUsers = useMemo(() => props.users.sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0)), [props.users]);

    useEffect(() => {
        if (!props.fields) {
            return;
        }

        // preserve AnyFormData values not available in FormInfo.
        setForm((prevForm) => ({ ...initFormData(props.fields), touched: prevForm.touched, validationErrors: prevForm.validationErrors }));
    }, [props.fields]);

    const getWorkflowElement = () => {
        if (!form.workflow || !props.workflowTemplate) {
            return null;
        }

        return (
            <>
                <Styled.HeaderWorkflowState>
                    <SelectElement<Domain.Shared.WorkflowTemplateState>
                        id={`input-status-header-${props.workflowTemplate.id}`}
                        label="Status"
                        editorSettings={{
                            disabled: itemIsLocked,
                            validationErrors: undefined,
                            restrictions: undefined,
                            onChange: (selectedItem) => {
                                props.onWorkflowStateChanged({
                                    ...form.workflow,
                                    workflowState: selectedItem.id
                                });
                            },
                        }}
                        searchable={false}
                        clearable={false}
                        optionItems={props.workflowTemplate.workflowStates}
                        value={props.workflowTemplate.workflowStates.find(ws => ws.id === form.workflow?.workflowState)}
                        customOptions={(item) => workflowInputCustomOptions(item)}
                        customSingleValue={(item) => workflowInputCustomOptions(item, { isFieldTemplate: true, placeholder: 'Kies...' })}
                    />
                </Styled.HeaderWorkflowState>
                <Styled.HeaderAssignedUser>
                    <SelectElement<Domain.Shared.User>
                        id={`input-assigneduser-${props.workflowTemplate.id}`}
                        label="Toegewezen aan"
                        displayExpr='name'
                        editorSettings={{
                            disabled: itemIsLocked,
                            validationErrors: undefined,
                            restrictions: undefined,
                            onChange: (item) => {
                                props.onWorkflowStateChanged({
                                    ...form.workflow,
                                    assignedUser: item?.id
                                });
                            }
                        }}
                        searchable={false}
                        clearable={true}
                        optionItems={sortedUsers}
                        value={sortedUsers.find(u => u.id === form.workflow?.assignedUser)}
                    />
                </Styled.HeaderAssignedUser>
            </>
        );
    };

    const nameFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Name];
    const numberFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Number];
    const descriptionFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Description];
    const explanationFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Explanation];
    const amountFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Amount];
    const resultFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Result];
    const goalStatusFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.GoalStatus];
    const achievementStatusFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.AchievementStatus];
    const activityStatusFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.ActivityStatus];
    const statusFieldDefinition = goalStatusFieldDefinition || achievementStatusFieldDefinition || activityStatusFieldDefinition;
    const isEditing = props.mode === FormMode.Edit || props.mode === FormMode.AddNew;

    const validators = getValidators(fieldDefinitions);

    //Condition will be: measuremonent is closed & user doesn't have valid lease & user hasn't task on the workflow step & user is not a contributor 
    const itemIsLocked = props.isMeasureMomentClosed || props.leaseInfo?.result === "Denied" || !isEditing;

    const getAttachmentItemsElements = () => {
        if (!form.attachments || form.attachments.length === 0) {
            return null;
        }

        return form.attachments.filter(item => !item.deleted).map((attachment) => {
            const audit = props.audit?.filter(auditItem => auditItem.fieldValue === attachment.id) || [];
            return (
                <HierarchyItemAttachmentItem
                    attachment={attachment}
                    key={attachment.id}
                    audit={audit}
                    users={sortedUsers}
                    disabled={itemIsLocked}
                    onDownloadAttachment={props.onDownloadAttachment}
                    onRemoveAttachment={props.onRemoveAttachment}
                />
            );
        });
    };

    const storeFormValue = (value: string, systemId: keyof typeof validators) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form, value, validators, systemId);
        setForm(newForm);
        props.onFieldsDataChanged({
            values: newForm.values,
            complex: newForm.complex,
            attachments: newForm.attachments,
            workflow: newForm.workflow,
            isValid: newForm.isValid,
            isTouched: Object.keys(newForm.touched).length > 0
        });
    };

    const cardHeaderTabElements = Object.keys(CardTabs).map((cardTab) => {
        if (props.mode === FormMode.AddNew && CardTabs[cardTab] === CardTabs.Contributors) {
            return null;
        }
        return (
            <CardHeaderTab
                id={`btn-nav-${cardTab}`}
                key={cardTab}
                active={selectedCardTab === CardTabs[cardTab]}
                onClick={() => setSelectedCardTab(CardTabs[cardTab])}
                title={CardTabs[cardTab]} />
        );
    });

    const maximizableElements: Record<string, JSX.Element> = {
        [descriptionFieldDefinition.id]: (
            <MultiLineTextElement
                id={`input-description-${descriptionFieldDefinition.id}`}
                rows={!maximizedId ? 9 : 24}
                label={descriptionFieldDefinition.label ? descriptionFieldDefinition.label : descriptionFieldDefinition.name}
                editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, descriptionFieldDefinition.id)}
                value={form.values[descriptionFieldDefinition.id] as string}
                canMaximize={true}
                onMaximize={() => handleMaximize(descriptionFieldDefinition.id)}
                maximized={maximizedId === descriptionFieldDefinition.id}
                onFocus={() => props.setSelectedFieldId(descriptionFieldDefinition.id)}
                onFocusOut={() => props.setSelectedFieldId(null)}
            />
        ),
        [resultFieldDefinition.id]: (
            <MultiLineTextElement
                id={`input-result-${resultFieldDefinition.id}`}
                rows={!maximizedId ? 9 : 24}
                label={resultFieldDefinition.label ? resultFieldDefinition.label : resultFieldDefinition.name}
                editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, resultFieldDefinition.id)}
                value={form.values[resultFieldDefinition.id] as string}
                canMaximize={true}
                onMaximize={() => handleMaximize(resultFieldDefinition.id)}
                maximized={maximizedId === resultFieldDefinition.id}
                onFocus={() => props.setSelectedFieldId(resultFieldDefinition.id)}
                onFocusOut={() => props.setSelectedFieldId(null)}
            />
        ),
        [explanationFieldDefinition.id]: (
            <MultiLineTextElement
                id={`input-explanation-${explanationFieldDefinition.id}`}
                rows={!maximizedId ? (statusFieldDefinition ? 13 : 21) : 24}
                label={explanationFieldDefinition.label ? explanationFieldDefinition.label : explanationFieldDefinition.name}
                editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, explanationFieldDefinition.id)}
                value={form.values[explanationFieldDefinition.id] as string}
                canMaximize={true}
                onMaximize={() => handleMaximize(explanationFieldDefinition.id)}
                maximized={maximizedId === explanationFieldDefinition.id}
                onFocus={() => props.setSelectedFieldId(explanationFieldDefinition.id)}
                onFocusOut={() => props.setSelectedFieldId(null)}
            />
        )
    };

    const handleMaximize = (id: string) => {
        setMaximizedId(prev => prev === id ? null : id);
    }

    let columnsElements: Record<number, JSX.Element[]>;

    if (!maximizedId) {
        columnsElements = {
            1: [
                maximizableElements[descriptionFieldDefinition.id],
                maximizableElements[resultFieldDefinition.id],
            ],
            2: [
                (statusFieldDefinition ?
                    <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                        id={`input-status-col-${statusFieldDefinition.id}`}
                        label={statusFieldDefinition.label ? statusFieldDefinition.label : statusFieldDefinition.name}
                        editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, (item: Domain.Shared.FieldDefinitionOptionItem, id) => storeFormValue(item.id, id), statusFieldDefinition.id)}
                        searchable={false}
                        clearable={false}
                        optionItems={statusFieldDefinition.optionItems}
                        value={statusFieldDefinition.optionItems.find(ot => ot.id === form.values[statusFieldDefinition.id])}
                        customOptions={statusInputCustomOptions}
                        customSingleValue={(item) => statusInputCustomOptions(item, { isFieldTemplate: true, placeholder: 'Kies...' })}
                    /> : <></>),
                (amountFieldDefinition ? <DecimalElement
                    id={`input-amount-${amountFieldDefinition.id}`}
                    label={amountFieldDefinition.label ? amountFieldDefinition.label : amountFieldDefinition.name}
                    format='€ #,##0'
                    editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, amountFieldDefinition.id)}
                    value={form.values[amountFieldDefinition.id] as number}
                /> : <></>),
                <Styled.ExplanationFieldWrapper isTypePolicy={props.elementDefinition.systemId === Domain.SystemElementDefinitions.Performance.Policy}>
                    {maximizableElements[explanationFieldDefinition.id]}
                </Styled.ExplanationFieldWrapper>
            ],
            3: [
                <Label>Bijlagen</Label>,
                <Styled.ContentAttachments>
                    {getAttachmentItemsElements()}
                </Styled.ContentAttachments>,
                <AttachmentElement
                    id='attachments'
                    label={attachmentsFileFieldDefinition.label}
                    editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, (val: string) => {
                        // do nothing;
                    }, attachmentsFileFieldDefinition.id)}
                    allowMultiple={true}
                    value={undefined} // the existing attachments shouldn't be loaded into the attachment editor.
                    onLoadAttachment={() => { return null; }}
                    onUploadAttachment={props.onUploadAttachment}
                    onStartMultipleUpload={props.onStartMultipleUpload}
                    onFinishMultipleUpload={props.onFinishMultipleUpload}
                />
            ],
        };
    } else {
        columnsElements = {
            1: [maximizableElements[maximizedId]]
        };
    }

    const convertToDomainAuditEvent = (auditEvent: Domain.Dto.Shared.AuditEvent) => {
        return {
            ...auditEvent,
            eventType: auditEvent.eventTypeId as Domain.Shared.EventType,
            user: sortedUsers?.find(user => user.id === auditEvent.userId)
        } as Domain.Shared.AuditEvent;
    };

    return (
        <Styled.Grid>
            <Styled.HeaderForm>
                <SVGIcon value={props.icons[props.elementDefinition.icon]?.svg} size={IconSize.medium} color={props.elementDefinition.color} />
                <Styled.StyledLabel>{props.elementDefinition.name}</Styled.StyledLabel>
            </Styled.HeaderForm>
            {props.leaseInfo?.result === "Denied" && <Styled.WarningForm>
                <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberOutlinedIcon />}
                    className='p-025'
                    messageText={`Wordt bewerkt door ${sortedUsers?.find(user => user.id === props.leaseInfo?.ownerId)?.name}`}
                />
            </Styled.WarningForm>}
            <Styled.HeaderNumber>
                <TextElement
                    id={`input-nummer-${numberFieldDefinition.id}`}
                    label={numberFieldDefinition.label ? numberFieldDefinition.label : numberFieldDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, numberFieldDefinition.id)}
                    value={form.values[numberFieldDefinition.id] as string}
                />
            </Styled.HeaderNumber>
            <Styled.HeaderName>
                <TextElement
                    id={`input-name-${nameFieldDefinition.id}`}
                    label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, nameFieldDefinition.id)}
                    value={form.values[nameFieldDefinition.id] as string} />
            </Styled.HeaderName>
            {getWorkflowElement()}
            <Styled.ContentHeaderBar />
            <Styled.ContentHeader>
                {cardHeaderTabElements}
            </Styled.ContentHeader>
            {selectedCardTab === CardTabs.Details && (
                <Styled.Content>
                    <Styled.ContentColumn1 key='hierarchyItemForm-contentColumn1' isMaximized={!!maximizedId}>
                        {columnsElements[1]}
                    </Styled.ContentColumn1>
                    {!!!maximizedId &&
                        <>
                            <Styled.ContentColumn2 key='hierarchyItemForm-contentColumn2'>
                                {columnsElements[2]}
                            </Styled.ContentColumn2>
                            <Styled.ContentColumn3 key='hierarchyItemForm-contentColumn3'>
                                {columnsElements[3]}
                            </Styled.ContentColumn3>
                        </>
                    }
                </Styled.Content>
            )}
            {selectedCardTab === CardTabs.History && (
                <HierarchyItemAuditTrail
                    key={`audit-${props.elementDefinition.id}`}
                    elementDefinition={props.elementDefinition}
                    attachentFileDefinitionId={attachmentsFileFieldDefinition.id}
                    auditTrail={props.audit.map(convertToDomainAuditEvent)}
                />
            )}
            {selectedCardTab === CardTabs.Contributors && (
                <HierarchyItemContributors
                    key={`contributors-${props.elementDefinition.id}`}
                    disabled={itemIsLocked}
                    users={sortedUsers}
                    hierarchyRights={props.hierarchyRights}
                    onAssignContributors={props.onAssignContributors}
                />
            )}
        </Styled.Grid>
    );
};

/**
 * Initialises the validators for the form (only editable values are listed).
 */
const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    const nameFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Name];
    const numberFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Number];
    const descriptionFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Description];
    const explanationFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Explanation];
    const resultFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Result];
    const attachmentsFileFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.AttachmentFile];

    const validators: Record<string, TextValidator | NumberValidator | AttachmentValidator> = {
        [nameFieldDefinition.id]: new TextValidator({
            required: nameFieldDefinition.required,
            stringMaxLength: nameFieldDefinition.stringMaxLength,
            stringMinLength: nameFieldDefinition.stringMinLength,
            stringType: nameFieldDefinition.stringType
        }),
        [numberFieldDefinition.id]: new TextValidator({
            required: numberFieldDefinition.required,
            stringMaxLength: numberFieldDefinition.stringMaxLength,
            stringMinLength: numberFieldDefinition.stringMinLength,
            stringType: numberFieldDefinition.stringType
        }),
        [descriptionFieldDefinition.id]: new TextValidator({
            required: descriptionFieldDefinition.required,
            stringMaxLength: descriptionFieldDefinition.stringMaxLength,
            stringType: descriptionFieldDefinition.stringType
        }),
        [explanationFieldDefinition.id]: new TextValidator({
            required: explanationFieldDefinition.required,
            stringMaxLength: explanationFieldDefinition.stringMaxLength,
            stringType: explanationFieldDefinition.stringType
        }),
        [resultFieldDefinition.id]: new TextValidator({
            required: resultFieldDefinition.required,
            stringMaxLength: resultFieldDefinition.stringMaxLength,
            stringType: resultFieldDefinition.stringType
        }),
        [attachmentsFileFieldDefinition.id]: new AttachmentValidator({
            required: attachmentsFileFieldDefinition.required,
            attachmentAllowedFileTypes: attachmentsFileFieldDefinition.attachmentAllowedFileTypes,
            attachmentMaxFileSize: attachmentsFileFieldDefinition.attachmentMaxFileSize
        }),
    };

    if (Object.keys(fieldDefinitions).includes(Domain.SystemFieldDefinitions.Performance.Amount)) {
        const amountFieldDefinition = fieldDefinitions[Domain.SystemFieldDefinitions.Performance.Amount];
        validators[amountFieldDefinition.id] =
            new NumberValidator({ required: amountFieldDefinition.required, minValue: amountFieldDefinition.integerMinValue, maxValue: amountFieldDefinition.integerMaxValue });
    }

    return validators;
};

/**
 * Initialises the form data with the entity data.
 */
const initFormData = (fields: FormInfo<ValueType>): AnyFormData => {
    const formWithValues: AnyFormData = {
        values: fields ? fields.values : {},
        complex: fields && fields.complex ? [...fields.complex] : [],
        attachments: fields && fields.attachments ? [...fields.attachments] : [],
        workflow: fields ? fields.workflow : undefined,
        touched: {},
        validationErrors: {},
        isValid: false,
    };
    return formWithValues;
};

export { HierarchyItemForm };

const statusInputCustomOptions = (props: Domain.Shared.FieldDefinitionOptionItem, templateProps?: SelectElementTemplateProps) => {
    return <Styled.SingleValueWrapper>
        {PerformanceIconHelper.getPerformanceProgressIcon(props?.name, 20)}
        {templateProps?.isFieldTemplate ?
            <TextBox stylingMode='outlined' value={props?.name} placeholder={templateProps.placeholder} />
            :
            <Styled.SingleValueLabel aria-label={`${props?.id}-progress`}>
                {props?.name}
            </Styled.SingleValueLabel>
        }
    </Styled.SingleValueWrapper>
};

const workflowInputCustomOptions = (props: Domain.Shared.WorkflowTemplateState, templateProps?: SelectElementTemplateProps) => {
    return <Styled.SingleValueWrapper>
        {IconHelper.getWorkFlowStatusIcon(props?.name, IconSize.medium)}
        {templateProps?.isFieldTemplate ?
            <TextBox stylingMode='outlined' value={props?.name} />
            :
            <Styled.SingleValueLabel>
                {props?.name}
            </Styled.SingleValueLabel>
        }
    </Styled.SingleValueWrapper>
};
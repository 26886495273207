import React from 'react';
import _, { Dictionary } from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import { AutoFocus } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { DateElement, SelectElement, TextElement } from '@liasincontrol/ui-elements';
import { AnyFormData, ValidatorsDictionary, ValidationUtils } from '@liasincontrol/core-service';
import { SystemFieldDefinitions } from '@liasincontrol/domain';

type Props = {
    siteDesigns: Domain.Publisher.SiteDesign[],
    fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition>,
    workflowTemplates: Domain.Shared.WorkflowTemplateWithStates[],
    measureMoments: Domain.Shared.MeasureMoment[],
    isEditing: boolean,
    form: AnyFormData,
    validators: ValidatorsDictionary,
    onChange: (value: string, fieldDefinitionId: string) => void,
};

/**
 * Represents a UI component that renders the General section form of the Publication information page.
 */
export const GeneralSection: React.FC<Props> = (props) => {
    if (_.isEmpty(props.form) || _.isEmpty(props.form.values)) {
        return null;
    }

    const nameFieldDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Name];
    const titleFieldDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Title];
    const workflowFieldDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PublicationWorkflowId];
    const measureMomentDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PublicationMeasureMomentId];
    const reminderDateFieldDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.ReminderDate];
    const endDateFieldDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.EndDate];
    const organizationNameDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.OrganizationName];
    const siteDesignDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.SiteDesignId];

    const selectedMeasureMoment = props.measureMoments?.find(t => t.id === props.form.values[SystemFieldDefinitions.Pub.PublicationMeasureMomentId]);

    return (
        <MuiGrid container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, md: 8 }}
            justifyContent="flex-start"
            alignItems="flex-end">
            <MuiGrid item xs={1} md={4} key={nameFieldDefinition.id}>
                <AutoFocus>
                    <TextElement
                        id='pub-name'
                        label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                        helpText={{ title: nameFieldDefinition.helpTextTitle, text: nameFieldDefinition.helpText }}
                        editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.Name)}
                        value={props.form.values[SystemFieldDefinitions.Pub.Name] as string} />
                </AutoFocus>
            </MuiGrid>
            <MuiGrid item xs={1} md={4} key={titleFieldDefinition.id} >
                <TextElement
                    id='pub-title'
                    label={titleFieldDefinition.label ? titleFieldDefinition.label : titleFieldDefinition.name}
                    helpText={{ title: titleFieldDefinition.helpTextTitle, text: titleFieldDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.Title)}
                    value={props.form.values[SystemFieldDefinitions.Pub.Title] as string} />
            </MuiGrid>

            <MuiGrid item xs={1} md={4} key={organizationNameDefinition.id} >
                <TextElement
                    id='pub-organizationName'
                    label={organizationNameDefinition.label ? organizationNameDefinition.label : organizationNameDefinition.name}
                    helpText={{ title: organizationNameDefinition.helpTextTitle, text: organizationNameDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.OrganizationName)}
                    value={props.form.values[SystemFieldDefinitions.Pub.OrganizationName] as string} />
            </MuiGrid>
            <MuiGrid item xs={1} md={4} key={`${measureMomentDefinition.id}-baseyear`} >
                <TextElement
                    id='pub-baseyear'
                    label='Basisjaar'
                    editorSettings={props.isEditing ? { disabled: true, validationErrors: undefined, restrictions: undefined, onChange: noOp } : undefined}
                    helpText={{ title: 'Basisjaar', text: 'Het basisjaar is niet te wijzigen en wordt bepaald door het moment waarvoor de publicatie is aangemaakt.' }}
                    value={selectedMeasureMoment?.baseYear.toString()} />
            </MuiGrid>

            <MuiGrid item xs={1} md={4} key={measureMomentDefinition.id}>
                <SelectElement<Domain.Shared.MeasureMoment>
                    displayExpr='name'
                    id='pub-measureMoment'
                    searchable={false}
                    clearable={true}
                    label='Moment'
                    optionItems={props.measureMoments}
                    editorSettings={props.isEditing ? {
                        disabled: false,
                        restrictions: props.validators[SystemFieldDefinitions.Pub.PublicationMeasureMomentId] ? props.validators[SystemFieldDefinitions.Pub.PublicationMeasureMomentId].getRestrictions() : undefined,
                        validationErrors: props.form.touched[SystemFieldDefinitions.Pub.PublicationMeasureMomentId] || !props.form.isValid ? props.form.validationErrors[SystemFieldDefinitions.Pub.PublicationMeasureMomentId] : [],
                        onChange: (e: Domain.Shared.MeasureMoment) => {
                            props.onChange(e?.id, SystemFieldDefinitions.Pub.PublicationMeasureMomentId)
                        }
                    } : null}
                    value={selectedMeasureMoment}
                />
            </MuiGrid>
            <MuiGrid item xs={1} md={4} key={workflowFieldDefinition.id}>
                {props.form.values[SystemFieldDefinitions.Pub.PublicationWorkflowId] &&
                    <TextElement
                        id='pub-workflowField'
                        label='Workflow'
                        editorSettings={props.isEditing ? { disabled: true, validationErrors: undefined, restrictions: undefined, onChange: noOp } : undefined}
                        value={props.workflowTemplates.find(t => t.id === props.form.values[SystemFieldDefinitions.Pub.PublicationWorkflowId] as string)?.name ?? 'unknown'} />
                }
            </MuiGrid>

            <MuiGrid item xs={1} md={4} key={reminderDateFieldDefinition.id}>
                <DateElement
                    id='pub-reminderDate'
                    label={reminderDateFieldDefinition.label ? reminderDateFieldDefinition.label : reminderDateFieldDefinition.name}
                    helpText={{ title: reminderDateFieldDefinition.helpTextTitle, text: reminderDateFieldDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, true, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.ReminderDate)}
                    value={props.form.values[SystemFieldDefinitions.Pub.ReminderDate] as Date} />
            </MuiGrid>
            <MuiGrid item xs={1} md={4} key={endDateFieldDefinition.id}>
                <DateElement
                    id='pub-endDate'
                    label={endDateFieldDefinition.label ? endDateFieldDefinition.label : endDateFieldDefinition.name}
                    helpText={{ title: endDateFieldDefinition.helpTextTitle, text: endDateFieldDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, true, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.EndDate)}
                    value={(props.form.values[SystemFieldDefinitions.Pub.EndDate] as Date)} />
            </MuiGrid>

            <MuiGrid item xs={1} md={4} key={siteDesignDefinition.id}>
                <SelectElement<Domain.Publisher.SiteDesign>
                    displayExpr='name'
                    id='pub-siteDesign'
                    label={siteDesignDefinition.label ? siteDesignDefinition.label : siteDesignDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={props.siteDesigns}
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, true, props.validators, props.form,
                        (e: Domain.Publisher.SiteDesign) => props.onChange(e?.id, SystemFieldDefinitions.Pub.SiteDesignId), SystemFieldDefinitions.Pub.SiteDesignId)}
                    value={props.siteDesigns.find(sd => sd.id === props.form.values[SystemFieldDefinitions.Pub.SiteDesignId])}
                />
            </MuiGrid>
        </MuiGrid>
    );
}

const noOp = () => {
    // No operation - do nothing
};
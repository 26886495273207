import React from 'react';
import { TextEditor } from './index.editor';
import { TextProps } from './index.props';

/**
 * Represents a UI component that renders an text element.
 */
const TextElement: React.FC<TextProps> = (props) => {
    return (<TextEditor {...props} />);
};

export { TextEditor, TextElement }
import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class LineChartControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.LineChartArgument)
    argument: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.LineChartValue)
    value: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.LineChartValueFormat)
    valueFormat: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ChartLegend)
    legend: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.LineChartDescription)
    description: string;
}

export default LineChartControl;
import React from 'react';
import Tabs from 'devextreme-react/tabs';
import { ItemClickEvent } from 'devextreme/ui/tabs';

export type TabType = { id: number, text: string, path?: string, hint?: string };
interface Props {

    readonly tabs: TabType[];
    readonly selectedTab?: number;

    /**
     * Specifies whether tiles are placed horizontally or vertically.
     */
    readonly direction?: 'horizontal' | 'vertical';

    readonly stylingMode?: 'primary' | 'secondary';
    /**
     * A function that is executed when a collection item is clicked or tapped.
     */
    readonly onItemClick?: (e: ItemClickEvent<any, any>) => void;

}

export const LsTabs = (props: Props) => {
    return (
        <div className="custom-tab-container">
            <Tabs
                className='lias-navigation-tabs'
                dataSource={props.tabs}
                defaultSelectedIndex={0}
                selectedIndex={props.selectedTab}
                showNavButtons={false}
                orientation={props.direction}
                stylingMode={props.stylingMode || "primary"}
                iconPosition="start"
                selectionMode="single"
                scrollByContent={false}
                onItemClick={props.onItemClick}
            />
        </div >
    );
};

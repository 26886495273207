import React, { useMemo } from 'react';
import styled from 'styled-components';
import buildQuery from 'odata-query';
import DataGrid, { Column, FilterRow, Pager, Paging, RemoteOperations, Scrolling } from 'devextreme-react/data-grid';
import * as Domain from '@liasincontrol/domain';
import { GridColumn, DevExtremeToODataQueryFilter, DevExtremeFilter, defaultPageSizes, useCustomStore } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Finance } from '@liasincontrol/data-service';
import { InternalScrollDataGrid } from '../../shared/styles';

type Props = {
    journalElementKinds: Domain.Finance.JournalElementKind[],
    baseYear: number;
    pageSize?: number;
    selectedRowKey?: string;
    selectedRowIndex?: number;
    onError: (err: any) => void,
    onRowClick?: (journalCombination: Domain.Finance.JournalCombination) => void,
};

export const JournalCombinationsGrid: React.FC<Props> = (props) => {

    const customStore = useCustomStore('elementCombinationRK', async (loadOptions) => {
        let filter = null;
        if (loadOptions.filter) {
            filter = DevExtremeToODataQueryFilter.convert(loadOptions.filter as DevExtremeFilter);
        }

        const query = buildQuery({
            top: loadOptions.take,
            count: true,
            skip: loadOptions.skip,
            filter: filter,
            orderBy: (loadOptions.sort as Array<any>)?.map(sort => `${sort.selector} ${sort.desc ? 'desc' : 'asc'}`),
        });

        const response = await Finance.JournalCombinationDataAccessor.get({ baseYear: props.baseYear }, query)

        return {
            data: response.data.value,
            totalCount: response.data["@count"],
        };
    }, [props.baseYear]);

    const columns = useMemo(() => {
        if (!props.journalElementKinds) {
            return;
        }
        const elementKinds = props.journalElementKinds.sort((a, b) => a.orderIndex - b.orderIndex);
        let columns: GridColumn<Domain.Finance.JournalCombination>[] = [{
            name: 'isBenefit',
            title: 'Rubriek',
            width: 125,
            dataType: 'boolean',
            renderCustom: (item) => <>{item.data.isBenefit ? 'Baten' : 'Lasten'}</>,
            trueText: 'Baten',
            falseText: 'Lasten',
        }];
        columns = columns.concat(
            elementKinds.map((kind, i) =>
            ({
                name: `element${kind.orderIndex}Name` as keyof Domain.Finance.JournalCombination,
                title: kind.name,
                dataType: 'string',
                allowSorting: true,
                defaultSortIndex: i,
                defaultSortOrder: "asc",
            } as GridColumn<Domain.Finance.JournalCombination>)
            )
        );

        columns = columns.concat([
            {
                name: 'baseYear',
                title: 'Basisjaar',
                width: 125,
                allowFiltering: false,
            },
            {
                name: 'isReserve',
                title: 'Reserve',
                width: 125,
                dataType: 'boolean',
                allowFiltering: false,
            },
            {
                name: 'isClosed',
                title: 'Afgesloten',
                width: 125,
                dataType: 'boolean',
                allowFiltering: false,
            },
            {
                name: 'isStructural',
                title: 'Structureel',
                width: 125,
                dataType: 'boolean',
                allowFiltering: false,
            }
        ]);
        return columns;
    }, [props.journalElementKinds]);

    return <StyledDataGrid
        keyExpr='elementCombinationRK'
        onRowClick={(e) => props.onRowClick(e.data)}
        selectedRowKeys={props.selectedRowKey ? [props.selectedRowKey] : null}
        dataSource={customStore}
        hoverStateEnabled={true}
    >
        <FilterRow visible={true} applyFilter='auto' />
        <Scrolling mode="standard" />
        {columns?.map((column, i) =>
            <Column
                key={i}
                dataField={column.name}
                caption={column.title}
                trueText={column.trueText}
                cellRender={column.renderCustom}
                width={column.width}
                dataType={column.dataType}
                falseText={column.falseText}
                allowFiltering={column.allowFiltering}
                allowSorting={column.allowSorting}
                defaultSortOrder={column.defaultSortOrder}
                defaultSortIndex={column.defaultSortIndex}
            >
            </Column>)}
        <RemoteOperations filtering={true} paging={true} sorting={true} />

        <Pager
            displayMode='compact'
            allowedPageSizes={defaultPageSizes}
            showNavigationButtons={true}
            showPageSizeSelector={true}
            visible={true}
            showInfo={true}

        />
        <Paging enabled={true} defaultPageSize={AppSettingsService.getAppSettings().General.PageSize} />
    </StyledDataGrid>
};

const StyledDataGrid = styled(DataGrid)`
    ${InternalScrollDataGrid};
`;
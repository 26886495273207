import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ElementLabel } from '../primitives';

export type Props = {
    label?: string;
    steps: { label: string, key?: any, completed?: boolean }[];
    activeStep?: number;
    id?: string;
}

export const LiasStepper: React.FC<Props> = (props) => {
    return (
        <Box sx={{ width: '100%', marginBottom: '1rem' }}>
            {props.label && <ElementLabel>{props.label}</ElementLabel>}
            <Stepper alternativeLabel>
                {props.steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel icon={getIcon(index, props.activeStep)}>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

const getIcon = (currentStep: number, activeStep = 0) => {
    if (currentStep < activeStep) return <TaskAltIcon sx={{ color: '#008000', fontSize: 24 }} />;
    if (currentStep === activeStep) return <RadioButtonCheckedIcon sx={{ color: '#0000ff ', fontSize: 24 }} />;
    if (currentStep > activeStep) return <CircleOutlinedIcon sx={{ color: '#c7c7c7', fontSize: 24 }} />;
    return <CircleOutlinedIcon sx={{ color: '#c7c7c7', fontSize: 24 }} />
};

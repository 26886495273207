import React from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

import { cssBreakpoint } from '../../responsive/css';
import { px } from '../../style';
import { ISectionProps } from './Props';
import { Button } from '../../button';
import { Bar } from '../../bar';

interface IStyledSectionProps {
    readonly look: 'transparent' | 'white',
    readonly padding: boolean,
    readonly grid: boolean,
    readonly colSpan: number,
    readonly rowSpan: number,
    readonly widget: boolean,
    readonly fixedWidth: boolean,
}

const Container = styled.section`
    position:relative;
    display:inline-block;
    text-align:left;
    border-radius:4px;
    background: ${(p: IStyledSectionProps) => p.look === 'white' ? '#fff' : ''};
    margin: ${(p: IStyledSectionProps) => p.grid === false ? '0 0 24px 0' : null};
    ${(p: IStyledSectionProps) => p.padding === true ?
        p.widget === true ? css`padding: 16px 24px;` : css`padding: 32px 40px;`
        : ''};
    max-width: ${(p: IStyledSectionProps) => (p.fixedWidth === true ? `calc(100vw - ${px(280)} - 8rem)` : '')};
    width: ${(p: IStyledSectionProps) => p.grid === false ? '100%' : ''};
    overflow-x: ${(p: IStyledSectionProps) => (p.fixedWidth && 'auto')};
    grid-row: span ${(p: IStyledSectionProps) => p.rowSpan};
    grid-column: span ${(p: IStyledSectionProps) => p.colSpan};
    box-shadow:${(p: ISectionProps) => (p.look === 'white' && '0 5px 20px rgba(75, 85, 246, .06)') || 'none'};

    ${cssBreakpoint('xs', 's', 'm')`
        margin: ${(p: IStyledSectionProps) => p.grid === false ? '0 0 12px 0' : null};
        max-width: ${(p: IStyledSectionProps) => (p.fixedWidth === true ? `100vw` : '')};
    `};

    ${cssBreakpoint('xs')`
        grid-column: 1 / -1;
    `};
`;

export const Section = React.forwardRef<HTMLElement, ISectionProps>((
    { className, children, look, padding = true, colSpan = 2, rowSpan = 2, grid = false, widget = false, fixedWidth = false, title, withCloseButton = false, onClose }, ref) => (
    <Container className={className} look={look} padding={padding} colSpan={colSpan} rowSpan={rowSpan} grid={grid} widget={widget} ref={ref} fixedWidth={fixedWidth}>
        {title || withCloseButton ? <Bar look='toolbar'>
            {title && <Bar start>{title}</Bar>}
            {withCloseButton && <Bar end> <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<CloseIcon />} onClick={onClose}></Button></Bar>}
        </Bar>
            : null}
        {children}
    </Container >
));

import styled from 'styled-components';
import { Label, px } from '@liasincontrol/ui-basics';

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const ButtonContainer = styled.div`    
    display: flex;
    gap: ${px(8)};
    flex-wrap: wrap;
`;

const ChoicesContainer = styled.div`    
    margin-top: 1rem;
`;

const styledComponents = {
    StyledLabel, ButtonContainer,
    ChoicesContainer,
};

export default styledComponents;

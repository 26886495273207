import React, { useEffect, useMemo, useState } from 'react';
import { InputField } from '@liasincontrol/ui-devextreme';
import { TextProps } from './index.props';
import { getFeedback, getValidationErrorsNode } from '../../shared/validationHelper';
import _, { noop } from 'lodash';

/**
 * Represents a UI component that renders a text editor.
 */
export const TextEditor: React.FC<TextProps> = (props) => {
    const [value, setValue] = useState<string>(props.value ? props.value : '');
    const onChangedDebounced = useMemo(() => _.debounce(props.editorSettings?.onChange || noop, 300), [props.editorSettings?.onChange]);

    useEffect(() => {
        if (!onChangedDebounced) return;

        if ((props.value || '') === value) {
            return onChangedDebounced.cancel;
        }

        onChangedDebounced(value);
        return onChangedDebounced.cancel;
    }, [value, onChangedDebounced, props.value]);

    useEffect(() => setValue(props.value ? props.value : ''), [props.value]);

    return (
        <InputField
            id={`input-${props.id}`}
            key={props.id}
            label={props.label}
            disabled={props.editorSettings ? props.editorSettings.disabled : true}
            maxLength={props.editorSettings?.restrictions?.maxLength}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            feedback={getFeedback(value, props.editorSettings?.restrictions)}
            value={value}
            onBlur={props.onFocusOut}
            helpText={props.helpText}
            mandatory={props.editorSettings?.restrictions?.required}
            showClearButton={props.editorSettings?.clearable}
            withoutFeedback={props.editorSettings?.withoutFeedback}
            onChange={setValue}
        />
    );
};

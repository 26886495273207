import styled from "styled-components";
import { ModalDialog } from '@liasincontrol/ui-basics';

export const StyledModalDialog = styled(ModalDialog)`
    .modal-popup {
        height: 90vh;
        display: flex;
        flex-direction: column;
        margin: 0;
    }
`
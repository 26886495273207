import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import { Finance as DataAccess } from '@liasincontrol/data-service';
import { LsGrid, GridColumn, ScrollView } from '@liasincontrol/ui-devextreme';
import { BasicValidator, FormHelper, TextValidator, ValidationErrorData, ValueType } from '@liasincontrol/core-service';
import { EditorSettings, MultiLineTextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { Text, Label, MandatoryIcon, ModalDialog, ModalDialogFooter, Button } from '@liasincontrol/ui-basics';
import { SelectJournalCombination } from '../SelectJournalCombination';
import Styled from './index.styled';

type Props = {
    baseYear: number,
    baseYears: number[],
    disableSaveButton?: boolean,
    isReadonly?: boolean,
    isEditMode?: boolean,
    budgetJournalId?: string,
    budgetLineId?: string,
    onSave: (budgetLine: Domain.Finance.BudgetLine) => void,
    onCancel: () => void,
    onError: (exception: any) => void,
};

type BudgetLineCustom = Domain.Finance.BudgetLine & {
    transformedAmounts?: { [key: string]: number | string }[]
};

export const generateRKs = (combination: Domain.Finance.JournalCombination) => {
    const ids = [];
    for (let i = 1; i <= 8; i++) {
        if (combination[`element${i}RK`]) ids.push(combination[`element${i}RK`]);
    }
    return ids;
}

/**
 * Represents a UI component that renders the modal for managing a budget line item.
 */
export const BudgetLineForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<BudgetLineCustom>(generateForm(props.baseYears));
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>;
        hasErrors: boolean;
        isTouched: boolean;
    }>({ errors: {}, hasErrors: false, isTouched: false });
    const [journalElementKinds, setJournalElementKinds] = useState<Domain.Finance.JournalElementKind[]>([]);
    const [combinationDialogOpen, setCombinationDialogOpen] = useState<boolean>(false);
    const [selectedCombination, setSelectedCombination] = useState<Domain.Finance.JournalCombination>();

    useEffect(() => {
        DataAccess.JournalElementKindDataAccessor.getAll()
            .then((response) => {
                const elementKinds = response.data.sort((a, b) => a.orderIndex - b.orderIndex);
                setJournalElementKinds(elementKinds);
            })
            .catch((exception) => {
                props.onError(exception);
            });
    }, []);

    useEffect(() => {
        if (!props.baseYears) {
            return;
        }

        if (props.budgetLineId && props.budgetJournalId) {
            DataAccess.BudgetLineDataAccessor.get(props.budgetJournalId, props.budgetLineId).then(response => {

                const combination = {
                    baseYear: props.baseYear,
                    elementCombinationRK: null,
                    ...response.data,
                    elementRKs: generateRKs(response.data as Domain.Finance.JournalCombination),
                };
                setSelectedCombination(combination);
                setForm(generateForm(props.baseYears, combination));
            });
        }
    }, [props.budgetLineId, props.budgetLineId, props.baseYears, journalElementKinds]);

    const columns: GridColumn<{ [key: string]: number | string }>[] = [
        { name: "name", title: "Jaar", allowEditing: false, allowSorting: false, renderCustom: (item) => (<b>{item.data['name']}</b>) },
    ].concat(
        props.baseYears.map((year) => ({
            name: year.toString(),
            title: year.toString(),
            allowSorting: false,
            allowEditing: true,
            formatter: 'integer',
            align: 'right',
            renderCustom: undefined,
            editorOptions: {
                format: {
                    cultureCode: "nl-NL",
                    type: "fixedPoint",
                    precision: 0,
                },
                mode: "number", // Sets editor to number mode to restrict input
            }
        })));

    const onSave = () => {
        const errors = validate(form, validationErrors.errors);

        if (errors.hasErrors) {
            setValidationErrors((prev) => ({ errors: errors.errors, hasErrors: errors.hasErrors, isTouched: prev.isTouched }));
            return;
        }
        props.onSave(form);
    };

    const onChange = (value: string | { [key: string]: number | string }[] | string[], fieldName: string) => {
        const data: BudgetLineCustom = { ...form };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;

        setForm(data);
        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        temporaryValidationError.isTouched = true;
        setValidationErrors(temporaryValidationError);
    };

    function editorSettings<T>(fieldName: string, onChange: (value: any) => void, required = false, isReadonly = false): EditorSettings<T> {
        return {
            disabled: isReadonly,
            restrictions: Object.keys(validators).includes(fieldName) ? validators[fieldName].getRestrictions() : undefined,
            validationErrors: validationErrors.errors[fieldName],
            onChange: (value) => onChange(value),
        };
    }

    const footerElement = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Opslaan'
            onRightButtonClick={onSave}
            rightButtonDisabled={props.disableSaveButton || props.isReadonly || validationErrors.hasErrors || !validationErrors.isTouched}
        />
    );

    /**
     * Event handler to receive budget line ammount changes.
     */
    const onCellSave = (e) => {
        const rows = form.transformedAmounts;
        let changedRows = [];

        if (Array.isArray(rows)) {
            changedRows = Object.keys(rows[0]).reduce((acc, key) => {
                if (key !== 'name') {
                    acc.push({
                        transactionYear: +key,
                        amount: formatNumber(rows[0][key])
                    });
                }
                return acc;
            }, []);
        }

        onChange(changedRows, 'amounts');
    };

    const combinationSelected = (combination: Domain.Finance.JournalCombination) => {
        setCombinationDialogOpen(false);
        onChange(generateRKs(combination), 'elementRKs');
        setSelectedCombination(combination);
    };

    const getJournalCombinationInfo = (propertyName: keyof Domain.Finance.JournalCombination, combination: Domain.Finance.JournalCombination) => {
        const keyTemplate = /element(\d+)Name/;
        const match = (propertyName as string).match(keyTemplate);
        const orderIndex = match ? parseInt(match[1], 10) : null;
        if (orderIndex) {
            return {
                journalElementKindName: journalElementKinds.find((kind) => kind.orderIndex === orderIndex)?.name || '',
                journalCombinationName: combination[propertyName],
            }
        }
        return {};
    }

    const journalCombinationDetails = () => {
        if (!selectedCombination) {
            return;
        }

        const items = [];
        for (const key in selectedCombination) {
            const { journalElementKindName, journalCombinationName } = getJournalCombinationInfo(key as keyof Domain.Finance.JournalCombination, selectedCombination);
            if (journalElementKindName) {
                items.push(<Styled.CombinationRow><Styled.CombinationKindLabel>{journalElementKindName}:</Styled.CombinationKindLabel>  {journalCombinationName} </Styled.CombinationRow>);
            }
        }
        return items;
    };

    return (
        <>
            <ModalDialog
                settings={{
                    look: 'interactive',
                    title: 'Budgetwijziging aanmaken',
                    footer: footerElement,
                    width: 1024,
                }}
            >
                <ScrollView
                    elementAttr={{
                        class: 'lias-modal-dialog-content'
                    }}
                    bounceEnabled={false}
                    showScrollbar="always"
                    scrollByThumb={true}
                    useNative={true}
                    direction="vertical"
                >
                    <MuiGrid container
                        spacing={{ xs: 2, md: 3 }}
                        justifyContent="flex-start"
                        alignItems="stretch">
                        <MuiGrid item xs={12} md={5}>
                            <Label text='Boekingscombinatie' />
                            <MandatoryIcon />
                            <Styled.JournalCombinationWrapper>
                                {journalCombinationDetails()}
                                <Button
                                    aria-label='edit-combination'
                                    btnbase='ghostbuttons'
                                    btntype='medium_noicon'
                                    onClick={() => setCombinationDialogOpen(true)}>
                                    {selectedCombination ? 'Wijzigen' : 'SELECTEER'}
                                </Button>
                                {validationErrors.errors['elementRKs'] && <Text danger value={validationErrors.errors['elementRKs'][0]?.error} />}
                            </Styled.JournalCombinationWrapper>
                        </MuiGrid>
                        <MuiGrid item xs={12} md={7}>
                            <Label text='Boekingdetails' />
                            <MandatoryIcon />
                            {validationErrors.errors['amounts'] && <Text danger value={validationErrors.errors['amounts'][0]?.error} />}
                            {validationErrors.errors['transformedAmounts'] && <Text danger value={validationErrors.errors['transformedAmounts'][0]?.error} />}
                            <LsGrid
                                dataSource={form.transformedAmounts}
                                columns={columns}
                                enableColumnChooser={false}
                                searching={false}
                                showRowLines={true}
                                showColumnLines={false}
                                showBorders={false}
                                editable={true}
                                editing={{
                                    mode: 'cell',
                                    allowUpdating: true,
                                    onSave: (e) => onCellSave(e),
                                }}
                            />
                        </MuiGrid>
                        <MuiGrid item xs={12}>
                            <MultiLineTextElement
                                id='short-description-field'
                                rows={5}
                                label='Omschrijving'
                                editorSettings={editorSettings<string>(
                                    'shortDescription' as keyof Domain.Finance.BudgetLine,
                                    (value: string) => {
                                        onChange(value, 'shortDescription');
                                    },
                                    true,
                                    props.isReadonly
                                )}
                                value={form.shortDescription || ''}
                            />
                        </MuiGrid>
                        <MuiGrid item xs={12}>
                            <MultiLineTextElement
                                id='long-description-field'
                                rows={5}
                                label='Toelichting'
                                editorSettings={editorSettings<string>(
                                    'longDescription' as keyof Domain.Finance.BudgetLine,
                                    (value: string) => {
                                        onChange(value, 'longDescription');
                                    },
                                    false,
                                    props.isReadonly
                                )}
                                value={form.longDescription || ''}
                            />
                        </MuiGrid>
                    </MuiGrid>

                </ScrollView>
            </ModalDialog >
            {combinationDialogOpen && <SelectJournalCombination
                journalElementKinds={journalElementKinds}
                baseYear={props.baseYear}
                onCancel={() => setCombinationDialogOpen(false)} onError={props.onError}
                onCombinationSelected={combinationSelected}
            />}
        </>
    );
};

// https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/editing/

const generateForm = (baseYears: number[], budgetLine?: Domain.Finance.BudgetLine): BudgetLineCustom => {
    const newBudgetLine: BudgetLineCustom = budgetLine ? budgetLine : {
        id: '',
        elementRKs: [],
        longDescription: '',
        shortDescription: '',
        isStructural: false,
        amounts: [],
        transformedAmounts: []
    };
    const amounts = baseYears.map((year) => ({ transactionYear: year, amount: budgetLine?.amounts.find((a) => a.transactionYear === year)?.amount || 0 }));
    newBudgetLine.amounts = amounts;

    newBudgetLine.transformedAmounts = [
        {
            name: "Bedrag",
            ...Object.fromEntries(baseYears.map((year) => [year, budgetLine?.amounts.find((a) => a.transactionYear === year)?.amount || 0])),
        },
    ];

    return newBudgetLine;
};

/**
 * Cell component override, to don't propagate onclick event
 */
const formatNumber = (nr) => isNaN(nr) ? nr : +Number(nr).toFixed(0);

const validate = (form: Domain.Finance.BudgetLine, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    elementRKs: new BasicValidator({ required: true },
        (value: string[]): ValidationErrorData[] => {
            const filled = value.length > 0;
            if (!filled) {
                return [{ error: 'Veld vereist.' }];
            }
            return [];
        }),
    'longDescription': new TextValidator({ required: false, stringMaxLength: 5000, stringType: Domain.Shared.StringType.MultiLine }),
    'shortDescription': new TextValidator({ required: false, stringMaxLength: 255, stringType: Domain.Shared.StringType.MultiLine }),
    'amounts': new BasicValidator<Domain.Finance.BudgetLineAmmount[]>({ required: false },
        (value: Domain.Finance.BudgetLineAmmount[]): ValidationErrorData[] => {
            const existsIsNaN = value.some((v) => isNaN(v.amount));
            if (existsIsNaN) {
                return [{ error: 'Ongeldig bedrag.' }];
            }
            const filled = value.some((a) => a.amount && a.amount !== 0);
            if (!filled) {
                return [{ error: 'Veld vereist.' }];
            }
            return [];
        }),
};

import React, { useContext, useEffect, useMemo, useState } from 'react';
import buildQuery from 'odata-query';
import { LookupEditor } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions } from '@liasincontrol/domain';
import { JsonUtils, ValidationErrorData } from '@liasincontrol/core-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { DxCustomStore, DxDataSource, ILsColumnValue } from '@liasincontrol/ui-devextreme';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../../../helpers';

type Props = {
    publicationId: string,
    id: string,
    label: string,
    disabled: boolean,
    selectedElement: Domain.Publisher.Element,
    fieldValue: string,
    validationErrors: ValidationErrorData[],
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onFieldsChanged: (changes: Domain.Publisher.FieldPatch[]) => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onFocusOut?: () => void,
};

/**
 * Represents a UI component that renders a data source selection dropdown and orchestrates the multiple field value changes associated with it.
 */
const DataSourceSelection: React.FC<Props> = (props) => {
    const [val, setVal] = useState(props.fieldValue);

    const pubContext = useContext(PublicationContext);

    const elementDefinition = props.getElementDefinition(props.selectedElement.elementDefinitionSystemId, props.selectedElement.elementDefinitionId);
    const selectableColumnNames = Object.keys(new Domain.Publisher.DataSource());

    useEffect(() => {
        setVal(props.fieldValue);
    }, [props.fieldValue]);

    useEffect(() => {
        onDataSourceChanged(val);
    }, [val]);

    const onDataSourceChanged = (dataSourceId: string) => {

        const changes: Domain.Publisher.FieldPatch[] = [];

        changes.push({
            elementId: props.selectedElement.elementId,
            fieldId: props.id,
            value: dataSourceId
        });

        let dependantChanges: Promise<Domain.Publisher.FieldPatch[]>;
        if (elementDefinition.systemId === SystemElementDefinitions.Pub.DataTableControl) {
            dependantChanges = getDataTableDependentChanges(dataSourceId);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.PieChartControl) {
            dependantChanges = getChartDependentChanges(dataSourceId, SystemFieldDefinitions.Pub.PieChartArgument, SystemFieldDefinitions.Pub.PieChartValue);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.BarChartControl) {
            dependantChanges = getChartDependentChanges(dataSourceId, SystemFieldDefinitions.Pub.BarChartArgument, SystemFieldDefinitions.Pub.BarChartValue);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.LineChartControl) {
            dependantChanges = getChartDependentChanges(dataSourceId, SystemFieldDefinitions.Pub.LineChartArgument, SystemFieldDefinitions.Pub.LineChartValue);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.DataSourceText) {
            dependantChanges = getDataSourceTextDependentChanges(dataSourceId);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.TreeViewControl) {
            dependantChanges = getTreeViewDependentChanges(dataSourceId);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.AccordionDsControl) {
            dependantChanges = getAccordionDependentChanges(dataSourceId);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.MapControl) {
            dependantChanges = getMapDependentChanges(dataSourceId);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.PivotTableControl) {
            dependantChanges = getPivotTableDependentChanges(dataSourceId, SystemFieldDefinitions.Pub.PivotGridFields);
        } else if (elementDefinition.systemId === SystemElementDefinitions.Pub.TabDsControl) {
            dependantChanges = getTabDependentChanges(dataSourceId);
        }
        dependantChanges
            .then((extraChanges) => props.onFieldsChanged(changes.concat(extraChanges)))
            .catch(err => {
                console.error(err);
                //the datsasource change will be propagated, but we won't get the updated columns/filter values.
                props.onFieldsChanged(changes);
            });
    };

    const getMapDependentChanges = async (dataSourceId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        const latitudeFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.Latitude);
        const longitudeFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.Longitude);

        const extraChanges = [];
        if (dataSourceId !== props.fieldValue) {
            extraChanges.push(
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: latitudeFieldDefinition.id,
                    value: ''
                },
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: longitudeFieldDefinition.id,
                    value: ''
                }
            );

            const filterSettingsChange = getFilterEditorValue(dataSourceId);
            if (filterSettingsChange) {
                extraChanges.push(filterSettingsChange);
            }
        }

        return Promise.resolve(extraChanges);
    };

    const getDataTableDependentChanges = async (dataSourceId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        return loadColumnEditorSettings(dataSourceId)
            .then(columnSettingsChange => {
                const extraChanges: Domain.Publisher.FieldPatch[] = [columnSettingsChange];
                const filterSettingsChange = getFilterEditorValue(dataSourceId);
                if (filterSettingsChange) {
                    extraChanges.push(filterSettingsChange);
                }

                return extraChanges;
            });
    };

    const getChartDependentChanges = async (dataSourceId: string, argumentFieldDefId: string, valueFieldDefId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        const chartArgumentFieldDefinition = elementDefinition.fields.find((item) => item.systemId === argumentFieldDefId);
        const chartValueFieldDefinition = elementDefinition.fields.find((item) => item.systemId === valueFieldDefId);

        const extraChanges = [];
        if (dataSourceId !== props.fieldValue) {
            extraChanges.push(
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: chartArgumentFieldDefinition.id,
                    value: ''
                },
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: chartValueFieldDefinition.id,
                    value: ''
                }
            );

            const filterSettingsChange = getFilterEditorValue(dataSourceId);
            if (filterSettingsChange) {
                extraChanges.push(filterSettingsChange);
            }
        }

        return Promise.resolve(extraChanges);
    };

    const getDataSourceTextDependentChanges = async (dataSourceId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        const titleFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.DataSourceTitleColumn);
        const columnFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.DataSourceTextColumn);

        const extraChanges = [];
        if (dataSourceId !== props.fieldValue) {
            extraChanges.push(
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: titleFieldDefinition.id,
                    value: ''
                },
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: columnFieldDefinition.id,
                    value: ''
                }
            );

            const filterSettingsChange = getFilterEditorValue(dataSourceId);
            if (filterSettingsChange) {
                extraChanges.push(filterSettingsChange);
            }
        }

        return Promise.resolve(extraChanges);
    };

    const getTreeViewDependentChanges = async (dataSourceId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        return loadColumnEditorSettings(dataSourceId, true)
            .then(columnSettingsChange => {
                const extraChanges: Domain.Publisher.FieldPatch[] = [columnSettingsChange];
                const filterSettingsChange = getFilterEditorValue(dataSourceId);
                if (filterSettingsChange) {
                    extraChanges.push(filterSettingsChange);
                }

                return extraChanges;
            });
    };

    const getTabDependentChanges = async (dataSourceId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        return loadColumnEditorSettings(dataSourceId, true)
            .then(columnSettingsChange => {
                const extraChanges: Domain.Publisher.FieldPatch[] = [columnSettingsChange];
                if (dataSourceId !== props.fieldValue) { }
                const filterSettingsChange = getFilterEditorValue(dataSourceId);
                const headerSettingsChange = getHeaderEditorValue(dataSourceId);
                const titleColumnSettingsChange = getTitleColumnValue(dataSourceId);

                if (filterSettingsChange) {
                    extraChanges.push(filterSettingsChange);
                }
                if (headerSettingsChange) {
                    extraChanges.push(headerSettingsChange);
                }
                if (titleColumnSettingsChange) {
                    extraChanges.push(titleColumnSettingsChange);
                }

                return extraChanges;
            });
    };

    const getAccordionDependentChanges = async (dataSourceId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        return loadColumnEditorSettings(dataSourceId, true)
            .then(columnSettingsChange => {
                const extraChanges: Domain.Publisher.FieldPatch[] = [columnSettingsChange];
                if (dataSourceId !== props.fieldValue) { }
                const filterSettingsChange = getFilterEditorValue(dataSourceId);
                const headerSettingsChange = getHeaderEditorValue(dataSourceId);
                if (filterSettingsChange) {
                    extraChanges.push(filterSettingsChange);
                }
                if (headerSettingsChange) {
                    extraChanges.push(headerSettingsChange);
                }
                return extraChanges;
            });
    };

    const getPivotTableDependentChanges = async (dataSourceId: string, fieldDefId: string): Promise<Domain.Publisher.FieldPatch[]> => {
        const fieldDefinition = elementDefinition.fields.find((item) => item.systemId === fieldDefId);
        const extraChanges = [];
        if (dataSourceId !== props.fieldValue) {
            extraChanges.push(
                {
                    elementId: props.selectedElement.elementId,
                    fieldId: fieldDefinition.id,
                    value: ''
                }
            );

            const filterSettingsChange = getFilterEditorValue(dataSourceId);
            if (filterSettingsChange) {
                extraChanges.push(filterSettingsChange);
            }
        }
        return Promise.resolve(extraChanges);
    };

    const loadColumnEditorSettings = async (dataSourceId: string, defaultColumns = false): Promise<Domain.Publisher.FieldPatch> => {

        const columnSettingsFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.TableColumnSettings);

        const defaultEmptySettings = {
            elementId: props.selectedElement.elementId,
            fieldId: columnSettingsFieldDefinition.id,
            value: ''
        };

        if (!dataSourceId) {
            return Promise.resolve<Domain.Publisher.FieldPatch>(defaultEmptySettings);
        }

        const newDataSourceElement = await loadDataSourceElement(dataSourceId);
        if (!newDataSourceElement.schemaFileId) {
            return Promise.resolve<Domain.Publisher.FieldPatch>(defaultEmptySettings);
        }

        return props.onLoadAttachment(newDataSourceElement.schemaFileId)
            .then((blob) => blob.text())
            .then((jsonText) => {
                //get the current column editor field value                
                const columnSettingsFieldValue = props.selectedElement.fields[columnSettingsFieldDefinition.id];
                const previousColumnSettings = JsonUtils.toJson<ILsColumnValue[]>(columnSettingsFieldValue, []);
                const numberOfVisibleColumns = AppSettingsService.getAppSettings().Publisher.Controls.DataTable.NumberOfVisibleColumns;
                const columnSettings = DataSourceControlsUtils.refreshColumnSettings(false, previousColumnSettings, JSON.parse(jsonText), numberOfVisibleColumns, defaultColumns);

                return {
                    elementId: props.selectedElement.elementId,
                    fieldId: columnSettingsFieldDefinition.id,
                    value: JSON.stringify(columnSettings)
                };
            })
            .catch(err => {
                console.error(`Failed to retrieve Column editor data. Err = ${err}`);
                return defaultEmptySettings;
            });
    };

    const getFilterEditorValue = (dataSourceId: string): Domain.Publisher.FieldPatch => {
        if (dataSourceId === props.fieldValue) {
            return null;
        }

        const filterSettingsFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.Filter);

        // removed datasource? then fallback to template filter
        if (!dataSourceId) {
            return {
                elementId: props.selectedElement.elementId,
                fieldId: filterSettingsFieldDefinition.id,
                value: ''
            };
        }

        // changed datasource? then reset filter 
        return {
            elementId: props.selectedElement.elementId,
            fieldId: filterSettingsFieldDefinition.id,
            value: '[]'
        };

    };

    const getHeaderEditorValue = (dataSourceId: string): Domain.Publisher.FieldPatch => {
        if (dataSourceId === props.fieldValue) return null;
        const headerFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.HeaderField);

        if (!headerFieldDefinition) {
            return null;
        }

        return {
            elementId: props.selectedElement.elementId,
            fieldId: headerFieldDefinition.id,
            value: ''
        };
    };

    const getTitleColumnValue = (dataSourceId: string): Domain.Publisher.FieldPatch => {
        if (dataSourceId === props.fieldValue) return null;
        const titleColumnFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.TitleColumn);

        return {
            elementId: props.selectedElement.elementId,
            fieldId: titleColumnFieldDefinition.id,
            value: ''
        };
    };

    const loadDataSourceElement = async (dataSourceId: string): Promise<Domain.Publisher.DataSourceElement> => {
        const dataSourceElement = await pubContext.loadDataSourceElement(dataSourceId);
        return dataSourceElement;
    };

    const customDataSource = useMemo(() => {
        const source = new DxDataSource({
            paginate: true,
            pageSize: 5,
            store: new DxCustomStore({
                key: 'value',
                load: (loadOptions) => {
                    if (!loadOptions) {
                        return Promise.resolve([]);
                    }
                    const filterBy = { 'dataSourceName': { contains: loadOptions.searchValue } };
                    const query = buildQuery({
                        filter: !!loadOptions.searchValue ? filterBy : null,
                        select: selectableColumnNames.join(','),
                        top: loadOptions.take,
                        count: true,
                        skip: loadOptions.skip,
                    });
                    return DataAccess.DataSources.getDataSourcesV2(props.publicationId, query)
                        .then((response) => {
                            const list = response.data.value?.map((dataSource) => ({
                                value: dataSource.dataSourceId, label: dataSource.dataSourceName
                            }))
                            return { data: list };
                        });
                },
                byKey: async (key) => {
                    const dsElement = await pubContext.loadDataSourceElement(key);
                    return { value: key, label: dsElement.name };
                }
            }),
        });
        return source;
    }, [props.publicationId]);

    return (
        <>
            <LookupEditor
                id={props.id}
                key={props.id}
                label={props.label}
                placeholder="Kies…"
                value={val}
                displayExpr='label'
                valueExpr='value'
                dataSource={customDataSource}
                onFocusOut={props.onFocusOut}
                editorSettings={{
                    disabled: props.disabled,
                    clearable: true,
                    restrictions: {},
                    validationErrors: props.validationErrors,
                    onChange: (val: any) => {
                        setVal(val);
                    }
                }}
            />

        </>
    );
}

export { DataSourceSelection };
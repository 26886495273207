import React, { useState } from 'react';
import styled from 'styled-components';
import buildQuery from 'odata-query';
import DataGrid, { Column, FilterRow, Pager, Paging, RemoteOperations, Scrolling } from 'devextreme-react/data-grid';

import * as Domain from '@liasincontrol/domain';
import { ModalDialogFooter } from '@liasincontrol/ui-basics';
import { defaultPageSizes } from '@liasincontrol/ui-devextreme';
import { Finance } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { StyledModalDialog } from '../StyledModalDialog';
import { DevExtremeToODataQueryFilter, DevExtremeFilter, useCustomStore } from '@liasincontrol/ui-devextreme';
import { InternalScrollDataGrid } from '../../../shared/styles';

type Props = {
    baseYear: number,
    onCancel: () => void,
    onError: (exception: any) => void,
    onKindSelected?: (budgetJournalKind: Domain.Finance.BudgetJournalKind) => void
};

export const SelectBudgetJournalKind: React.FC<Props> = (props) => {
    const [selectedKind, setSelectedKind] = useState<Domain.Finance.BudgetJournalKind>();

    const customStore = useCustomStore('id', async (loadOptions) => {

        let filter = null;
        if (loadOptions.filter) {
            filter = DevExtremeToODataQueryFilter.convert(loadOptions.filter as DevExtremeFilter);
        }

        const finalFilter = [{ 'isActive': true }, { 'baseYear': props.baseYear }];
        if (filter) finalFilter.push(filter);

        const query = buildQuery({
            top: loadOptions.take,
            count: true,
            skip: loadOptions.skip,
            filter: finalFilter,
            orderBy: (loadOptions.sort as Array<any>)?.map(sort => `${sort.selector} ${sort.desc ? 'desc' : 'asc'}`),
        });

        const response = await Finance.BudgetJournalKindDataAccessor.getAll(query)

        return {
            data: response.data.value,
            totalCount: response.data["@count"],
        };

    }, [props.baseYear]);

    return (
        <StyledModalDialog
            settings={{
                width: 1300,
                look: 'message',
                title: 'Kies een journaalsoort',
                footer: <ModalDialogFooter
                    leftButtonText='Sluiten'
                    onLeftButtonClick={props.onCancel}
                    rightButtonDisabled={!selectedKind}
                    rightButtonText='Opslaan'
                    onRightButtonClick={() => props.onKindSelected(selectedKind)} />,
            }}
        >
            <StyledDataGrid
                keyExpr='id'
                onRowClick={(e) => {
                    setSelectedKind(e.data as Domain.Finance.BudgetJournalKind);
                }}
                hoverStateEnabled={true}
                selectedRowKeys={[selectedKind?.id]}
                dataSource={customStore}
            >
                <FilterRow visible={true} applyFilter='auto' />
                <Scrolling mode="standard" />
                <Column dataField='name' caption='Journaalsoort' />
                <RemoteOperations filtering={true} paging={true} sorting={true} />

                <Pager
                    displayMode='compact'
                    allowedPageSizes={defaultPageSizes}
                    showNavigationButtons={true}
                    showPageSizeSelector={true}
                    visible={true}
                    showInfo={true}
                />
                <Paging enabled={true} defaultPageSize={AppSettingsService.getAppSettings().General.PageSize} />
            </StyledDataGrid>
        </StyledModalDialog>
    );
};

const StyledDataGrid = styled(DataGrid)`
    ${InternalScrollDataGrid};
`;
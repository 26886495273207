
export const injectTargetBlank = (html: string): string => {
    const anchorRegex = /<a(\s[^>]*)?>/g;

    const replaceAnchor = (match: string) => {
        if (match.includes('href="#')) {
            return match.replace('<a', '<a target="_self" ');
        }

        if (!match.includes('target="_blank"')) {
            return match.replace('<a', '<a target="_blank" ');
        }

        return match;
    };

    const modifiedHTML = html?.replace(anchorRegex, replaceAnchor);

    return modifiedHTML;
};

export const wrapTablesWithDiv = (html: string) => {
    return html.replace(/<table([^>]*)>/g, '<div class="table-wrapper"><table$1>').replace(/<\/table>/g, '</table></div>');
};

import CustomStore from "devextreme/data/custom_store";
import { useEffect, useMemo } from "react";

export const useCustomStore = (key, load, deps) => 
    useMemo(() => {
        const store = new CustomStore({ key, load }); 
        return store;
    }, [key, ...deps]);

export const useTabPanelNavButtonsStopPropagation = (tabRef) => {

    useEffect(() => {
        if (!tabRef.current?.instance()?.element()) return;

        const handleNavigationButtonClick = (e) => {
            e.stopPropagation();
        };

        const attachEventHandlers = () => {
            if (!tabRef.current?.instance()?.element()) return;

            const navLeftButton = tabRef.current.instance().element().querySelector('.dx-tabs-nav-button-left');
            const navRightButton = tabRef.current.instance().element().querySelector('.dx-tabs-nav-button-right');

            if (navLeftButton) {
                navLeftButton.removeEventListener('click', handleNavigationButtonClick);
                navLeftButton.addEventListener('click', handleNavigationButtonClick);
            }

            if (navRightButton) {
                navRightButton.removeEventListener('click', handleNavigationButtonClick);
                navRightButton.addEventListener('click', handleNavigationButtonClick);
            }
        };

        // Attach event handlers immediately
        attachEventHandlers();

        const resizeObserver = new ResizeObserver(() => {
            attachEventHandlers();
        });
        resizeObserver.observe(tabRef.current.instance().element());

        return () => {
            // Clean up event listeners
            if (tabRef.current?.instance()?.element()) {
                resizeObserver.unobserve(tabRef.current.instance().element());
            }
        };
    }, []);

}
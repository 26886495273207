import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useImmer } from 'use-immer';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import { BreadcrumbBar, ErrorOverlay, Heading2, PageTitle, ResetZIndex, Section, Wrapper, WrapperContent, Text, palette } from '@liasincontrol/ui-basics';
import { Finance as DataAccess, oDataResponseStructured } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, DataUtils, DomUtils, useUserSettings } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ContextMenu, Button as DxButton, generateSafeId, GridCellClickEvent, GridColumn, LoadPanel, LsGrid, LsGridToolbarItem, SummaryGroupItem } from '@liasincontrol/ui-devextreme';
import { AjaxRequestStatus } from '@liasincontrol/redux-service';
import { CheckboxElement, SelectElement } from '@liasincontrol/ui-elements';
import { Filter } from './Filter';
import { useFinanceSettings, useJournalElementKinds } from '../../shared/hooks';
import { Transactions, TransactionsType } from './Transactions';
import { useStructureNodes } from '../../shared/hooks/useStructureNodes';

const groupByOptions: GroupByOptionsType[] = [
    { id: 'nogroup', text: "Geen groepering" },
    { id: 'transactionKind', text: "Lasten/Baten" },
    { id: 'exploitationReserve', text: "Soort" },
];

const overviewTypeDictionary = {
    Elements: {
        name: 'Elements',
        keyExpr: 'journalElementRK',
        displayExpr: 'journalElementCode',
        nameExpr: 'journalElementName',
        groupBy: [
            { id: 'journalElementCode', text: "Boekingselement" },
        ],
    },
    StructureNode: {
        name: 'StructureNode',
        keyExpr: 'structureNodeRK',
        displayExpr: 'structureNodeCode',
        nameExpr: 'structureNodeName',
        groupBy: [
            { id: 'structureNodeCode', text: "Structuurelement" },
        ],
    },
    Combinations: {
        name: 'Combinations',
        keyExpr: undefined,
        displayExpr: undefined,
        nameExpr: undefined,
        groupBy: [],
    },
};

const viewModeOptions: { id: keyof typeof overviewTypeDictionary, text: string }[] = [
    { id: 'Elements', text: "Boekingselement" },
    { id: 'StructureNode', text: "Structuurelement" },
    { id: 'Combinations', text: "Combinatie" },
];

type GroupByOptionsType = {
    id: string,
    text: string,
};

type BreadCrumbDataType = {
    kind?: Domain.Finance.JournalElementKind,
    value?: Domain.Finance.BudgetOverviewValue, // raw value
    returnedView?: keyof typeof overviewTypeDictionary, //What view is requested on clicking in BC
    hidden?: boolean,
    groupBy?: GroupByOptionsType, // If a grouing was requested on this level
    budgetYear?: number,
};

type GridDataType = {
    data: Domain.Finance.BudgetOverviewValue[];
    availableColumns: GridColumn<Domain.Finance.BudgetOverviewValue>[];
    groupSummaries: SummaryGroupItem[];
    totalSummaries: SummaryGroupItem[];
};

/**
 * The `Overview` component provides a detailed view of the budget overview, allowing users to filter,
 * drill down into specific data, and view summaries. It fetches and displays data based on user settings
 * and interactions, such as selecting different elements or structure nodes.
 */
export const Overview: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { baseYear, structureRK, structureNodeRK } = useUserSettings();
    const journalElementKinds = useJournalElementKinds();
    const { structureNodes } = useStructureNodes();
    const [extraFilters, setExtraFilters] = useState<{ transactionKind: string[], exploatatie: string[] }>
        ({ transactionKind: [], exploatatie: [] });
    const [gridData, setGridData] = useImmer<GridDataType>({ data: [], availableColumns: [], groupSummaries: [], totalSummaries: [] });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [breadCrumb, setBreadCrumb] = useState<BreadCrumbDataType[]>([]);
    const [view, setView] = useState<keyof typeof overviewTypeDictionary>();
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [transactions, setTransactions] = useState<TransactionsType>();
    const divideBy = useRef<number>(1);
    const [groupBy, setGroupBy] = useState<GroupByOptionsType>(groupByOptions[0]);
    const [selectedCell, setSelectedCell] = useState<{ rowIndex: number, columnIndex: number }>(undefined);
    const isAdditionalDataLoaded = journalElementKinds.status === AjaxRequestStatus.Done;
    const [budgetYear, setBudgetYear] = useState<number>(0);
    const financeSettings = useFinanceSettings();

    const getJournalElementRoute = (breadCrumb: BreadCrumbDataType[], bv: BreadCrumbDataType) => {
        return breadCrumb.reduce((acc, bc) => {
            if (bc.value?.journalElementRK !== undefined) {
                acc.push(bc.value.journalElementRK);
            }
            return acc;
        }, [bv.value?.journalElementRK].filter(Boolean));
    };

    const getWorker = (overviewType, selectedItem, journalElementRoute, selectedData, budgetYear) => {
        if (!selectedData) {
            switch (overviewType) {
                case 'Elements':
                    return DataAccess.BudgetOverview.getV2(baseYear, budgetYear ?? baseYear, structureRK, structureNodeRK, extraFilters.transactionKind, extraFilters.exploatatie);
                case 'StructureNode':
                    return DataAccess.BudgetOverview.getBudgetOverviewStructureNodes(baseYear, budgetYear ?? baseYear, structureRK, extraFilters.transactionKind, extraFilters.exploatatie);
                default:
                    return Promise.resolve({ data: { layout: [], values: [] } });
            }
        }
        const lastKnownTransactionKind = selectedData.transactionKind;
        switch (overviewType) {
            case 'Elements':
                return selectedItem && !_.isEmpty(journalElementRoute)
                    ? DataAccess.BudgetOverview.getJournalElementKindDrilldown(baseYear, budgetYear ?? baseYear, selectedItem.rk, lastKnownTransactionKind, journalElementRoute, [selectedData.exploitationReserve])
                    : DataAccess.BudgetOverview.getV2(baseYear, budgetYear ?? baseYear, structureRK, selectedData.structureNodeRK as string, [lastKnownTransactionKind], [selectedData.exploitationReserve]);
            case 'Combinations':
                return _.isEmpty(journalElementRoute)
                    ? DataAccess.BudgetOverview.getJournalCombinationDrilldownNode(baseYear, budgetYear ?? baseYear, structureRK, lastKnownTransactionKind, [selectedData.exploitationReserve], selectedData.structureNodeRK as string)
                    : DataAccess.BudgetOverview.getJournalCombinationDrilldown(baseYear, budgetYear ?? baseYear, lastKnownTransactionKind, journalElementRoute, [selectedData.exploitationReserve]);
            case 'StructureNode':
                return DataAccess.BudgetOverview.getBudgetOverviewStructureNodes(baseYear, budgetYear ?? baseYear, structureRK, [lastKnownTransactionKind], [selectedData.exploitationReserve], selectedData.structureNodeRK as string);
            default:
                return Promise.resolve({ data: { layout: [], values: [] } });
        }
    };

    const getWorkerForExport = (overviewType, selectedItem, journalElementRoute, selectedData, budgetYear) => {

        //Main views, no drilldowns
        if (!selectedData) {
            switch (overviewType) {
                case 'Elements':
                    return DataAccess.BudgetOverview.getV2Export(baseYear, budgetYear ?? baseYear, structureRK, structureNodeRK, extraFilters.transactionKind, extraFilters.exploatatie);
                case 'StructureNode':
                    return DataAccess.BudgetOverview.getBudgetOverviewStructureNodesExport(baseYear, budgetYear ?? baseYear, structureRK, extraFilters.transactionKind, extraFilters.exploatatie);
                default:
                    return Promise.resolve({ data: new Blob() } as AxiosResponse<Blob>);
            }
        }

        const lastKnownTransactionKind = selectedData.transactionKind;

        switch (overviewType) {
            case 'Elements':
                return selectedItem && !_.isEmpty(journalElementRoute)
                    ? DataAccess.BudgetOverview.getJournalElementKindDrilldownExport(baseYear, budgetYear ?? baseYear, selectedItem.rk, lastKnownTransactionKind, journalElementRoute, [selectedData.exploitationReserve])
                    : DataAccess.BudgetOverview.getV2Export(baseYear, budgetYear ?? baseYear, structureRK, selectedData.structureNodeRK as string, [lastKnownTransactionKind], [selectedData.exploitationReserve]);
            case 'Combinations':
                return _.isEmpty(journalElementRoute)
                    ? DataAccess.BudgetOverview.getJournalCombinationDrilldownNodeExport(baseYear, budgetYear ?? baseYear, structureRK, lastKnownTransactionKind, [selectedData.exploitationReserve], selectedData.structureNodeRK as string)
                    : DataAccess.BudgetOverview.getJournalCombinationDrilldownExport(baseYear, budgetYear ?? baseYear, lastKnownTransactionKind, journalElementRoute, [selectedData.exploitationReserve]);
            case 'StructureNode':
                return DataAccess.BudgetOverview.getBudgetOverviewStructureNodesExport(baseYear, budgetYear ?? baseYear, structureRK, [lastKnownTransactionKind], [selectedData.exploitationReserve], selectedData.structureNodeRK as string);
            default:
                return Promise.resolve({ data: new Blob() } as AxiosResponse<Blob>);
        }
    };

    const handleDownload = async (exportworker) => {
        try {
            // Make GET request with axios
            const response = await exportworker;
            // Get the content-disposition header to extract the filename
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'publicatietaken.csv'; // Default filename

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch && filenameMatch[1]) {
                    fileName = filenameMatch[1].replace(/['"]/g, '');
                }
            }

            // Create a blob from the response
            const fileType = response.headers['content-type'] || 'text/csv';
            const fileBlob = new Blob([response.data], { type: fileType });
            DomUtils.download(fileBlob, fileName);
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    };

    //#region fetching data
    const filterOverview = (groupBy: GroupByOptionsType = groupByOptions[0], budgetYear?: number) => {
        if (!baseYear || !structureRK) return;
        setLoading(true);
        setBreadCrumb([]);
        setTransactions(null);
        setSelectedCell(null);

        const [worker, view]: [Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>>, keyof typeof overviewTypeDictionary] = !structureNodeRK
            ? [DataAccess.BudgetOverview.getBudgetOverviewStructureNodes(baseYear, budgetYear ?? baseYear, structureRK, extraFilters.transactionKind, extraFilters.exploatatie), 'StructureNode']
            : [DataAccess.BudgetOverview.getV2(baseYear, budgetYear ?? baseYear, structureRK, structureNodeRK, extraFilters.transactionKind, extraFilters.exploatatie), 'Elements'];
        setView(view);

        worker.then((response) => {
            const columns = mapColumns(response.data.layout, divideBy.current, groupBy);
            const summaries = mapSummaries(response.data.layout, divideBy.current);

            const updatedBreadCrumb: BreadCrumbDataType[] = [];
            let contextMenuAvailableItems = [];
            if (view === 'Elements') {
                //find current element kind, to eliminate it from the context menu
                const currentKind = journalElementKinds.items.find(kind => kind.name === columns[0]?.title);
                updatedBreadCrumb.push({ kind: currentKind, value: undefined, returnedView: view, hidden: false, budgetYear: budgetYear ?? baseYear });
                contextMenuAvailableItems = journalElementKinds.items.filter(i => i.rk !== currentKind?.rk);
            } else if (view === 'StructureNode') {
                contextMenuAvailableItems = structureNodes[structureRK].items;
                updatedBreadCrumb.push({ kind: undefined, value: undefined, returnedView: view, hidden: false, budgetYear: budgetYear ?? baseYear });
            }

            setGridData(draft => {
                // Update availableColumns
                draft.availableColumns.length = 0;
                if (columns) draft.availableColumns.push(...columns);
                if (draft.availableColumns[0]?.columns[0]) {
                    draft.availableColumns[0].columns[0] = {
                        ...draft.availableColumns[0].columns[0],
                        ...mapColumnExtraProperties(
                            view,
                            overviewTypeDictionary[view].keyExpr,
                            contextMenuAvailableItems,
                            (data, item, destView) => fetchDrillDown(data, updatedBreadCrumb, false, destView ?? view, item, undefined, budgetYear)
                        ),
                    };
                }

                // Update groupSummaries
                draft.groupSummaries = [];

                // Update totalSummaries
                draft.totalSummaries = summaries;

                // Update data
                draft.data = response.data.values;
            });

            setBreadCrumb(updatedBreadCrumb);
        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setGroupBy(groupBy);
            setLoading(false);
        });
    };

    const fetchDrillDown = (
        selectedData: Domain.Finance.BudgetOverviewValue,
        selectedBreadCrumb: BreadCrumbDataType[],
        hideInBreadcrumb = false,
        overviewType: keyof typeof overviewTypeDictionary,
        selectedItem?: Domain.Finance.JournalElementKind,
        groupBy: GroupByOptionsType = groupByOptions[0],
        budgetYear?: number
    ) => {
        setLoading(true);
        let contextMenuAvailableItems = [];
        let journalElementRoute = [];

        if (overviewType === 'Elements' || overviewType === 'Combinations') {
            contextMenuAvailableItems = journalElementKinds.items
                .filter(obj1 =>
                    obj1?.rk !== selectedItem?.rk &&
                    !selectedBreadCrumb.flatMap(o => o.kind ? o.kind : []).some(obj2 => obj1?.rk === obj2.rk)
                );
            journalElementRoute = getJournalElementRoute(selectedBreadCrumb, { value: selectedData });
        }

        if (overviewType === 'StructureNode') {
            //TODO filter out for subtree of selected data should help
            contextMenuAvailableItems = structureNodes[structureRK].items;
        }

        const worker = getWorker(overviewType, selectedItem, journalElementRoute, selectedData, budgetYear);

        worker.then((response) => {
            const columns = mapColumns(response.data.layout, divideBy.current, groupBy);
            const summaries = mapSummaries(response.data.layout, divideBy.current);

            const newBreadCrumbItem: BreadCrumbDataType = {
                value: selectedData,
                returnedView: overviewType,
                hidden: hideInBreadcrumb,
                budgetYear: budgetYear,
            };

            if (overviewType === 'Elements' && !selectedItem) {
                //drilldown from node to elements
                const currentKind = journalElementKinds.items.find(kind => kind.name === columns[0]?.title);
                contextMenuAvailableItems = contextMenuAvailableItems.filter(i => i.rk !== currentKind?.rk);
                newBreadCrumbItem.kind = currentKind;
            } else {
                newBreadCrumbItem.kind = selectedItem;
            }

            const updatedBreadCrumb = selectedBreadCrumb.concat(newBreadCrumbItem);
            setGridData(draft => {
                // Update availableColumns
                draft.availableColumns.length = 0;
                if (columns) draft.availableColumns.push(...columns);
                if (draft.availableColumns[0]?.columns?.[0]) {
                    _.set(draft.availableColumns, '[0].columns[0]', {
                        ...draft.availableColumns[0].columns[0],
                        ...mapColumnExtraProperties(
                            overviewType,
                            overviewTypeDictionary[overviewType].keyExpr,
                            contextMenuAvailableItems,
                            (data, item, destView) => fetchDrillDown(data, updatedBreadCrumb, false, destView ?? overviewType, item, undefined, budgetYear)
                        )
                    });
                }

                // Update groupSummaries
                draft.groupSummaries = [];

                // Update totalSummaries
                draft.totalSummaries = summaries;

                // Update data
                draft.data = response.data.values;
            });
            setBreadCrumb(updatedBreadCrumb);
            setTransactions(null);
            setSelectedCell(null);
        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setView(overviewType);
            setGroupBy(groupBy);
            setLoading(false);
        });
    };
    //#endregion fetching data

    //#region exporting

    const onExporting = () => {
        setLoading(true);
        const lastIndex = breadCrumb.length - 1;
        const bv = breadCrumb[lastIndex];

        let exportworker: Promise<AxiosResponse<Blob>>;

        if (lastIndex === 0) {
            exportworker =
                !structureNodeRK
                    ? DataAccess.BudgetOverview.getBudgetOverviewStructureNodesExport(baseYear, budgetYear ?? baseYear, structureRK, extraFilters.transactionKind, extraFilters.exploatatie)
                    : DataAccess.BudgetOverview.getV2Export(baseYear, budgetYear ?? baseYear, structureRK, structureNodeRK, extraFilters.transactionKind, extraFilters.exploatatie);
        } else {
            const journalElementRoute = getJournalElementRoute(breadCrumb.slice(0, lastIndex), bv);
            exportworker = getWorkerForExport(bv.returnedView, bv.kind, journalElementRoute, bv.value, budgetYear);
        }

        handleDownload(exportworker);
        setLoading(false);
    };

    const onExportingTransactions = () => {
        setLoadingTransactions(true);
        const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];

        let worker: Promise<AxiosResponse<Blob, any>>;
        if (view === 'StructureNode') {
            const keyexpression = transactions.sourceData[overviewTypeDictionary[lastBreadcrumbItem.returnedView].keyExpr] as string;
            worker = DataAccess.BudgetOverview.getNodeTransactionsExport(budgetYear, structureRK, keyexpression, transactions.sourceData.transactionKind, transactions.ammountType, [transactions.sourceData.exploitationReserve]);
        }
        else {
            const updatedBreadCrumb = [...breadCrumb, { kind: lastBreadcrumbItem?.kind, value: transactions.sourceData }];
            const transactionKind = lastBreadcrumbItem.value.transactionKind;
            const exploitationReserve = [lastBreadcrumbItem.value.exploitationReserve];
            const journalElementRoute = updatedBreadCrumb.reduce((acc, bc) => {
                if (bc.value?.journalElementRK !== undefined) {
                    acc.push(bc.value.journalElementRK);
                }
                return acc;
            }, []);
            if (view === 'Combinations') {
                const additionalRoute = Object.keys(transactions.sourceData).reduce((acc, key) => {
                    if (key.startsWith('journalElement') && key.endsWith('RK')) {
                        acc.push(transactions.sourceData[key]);
                    }
                    return acc;
                }, []);
                journalElementRoute.push(...additionalRoute);
            }
            worker = DataAccess.BudgetOverview.getTransactionsExport(budgetYear, journalElementRoute, transactionKind, transactions.ammountType, exploitationReserve);
        }

        handleDownload(worker);
        setLoadingTransactions(false);
    };

    //#endregion exporting

    const onCellClick = useCallback((e: GridCellClickEvent) => {
        const { data: item, component, column, rowIndex, columnIndex } = e;

        const transactionKind = item.transactionKind;
        const exploitationReserveFilter = [item.exploitationReserve];

        const transactionColumnWasClicked = gridData.availableColumns.some(colGroup =>
            colGroup.columns.some(col => col.name === column.dataField && col.isClickable)
        );

        if (transactionColumnWasClicked) {
            const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];
            const updatedBreadCrumb = [...breadCrumb, { kind: lastBreadcrumbItem?.kind, value: item }];
            const amountType = changeCaseFirstLetter(column.dataField, false);
            const journalElementRoute = updatedBreadCrumb.reduce((acc, bc) => {
                if (bc.value?.journalElementRK !== undefined) {
                    acc.push(bc.value.journalElementRK);
                }
                return acc;
            }, []);

            if (view === 'Combinations') {
                //rk on the returned + rk on the visited
                const additionalRoute = Object.keys(item).reduce((acc, key) => {
                    if (key.startsWith('journalElement') && key.endsWith('RK')) {
                        acc.push(item[key]);
                    }
                    return acc;
                }, []);
                journalElementRoute.push(...additionalRoute);
            }

            setLoadingTransactions(true);
            const worker = view === 'StructureNode'
                ? DataAccess.BudgetOverview.getNodeTransactions(budgetYear, structureRK, item[overviewTypeDictionary[view].keyExpr], transactionKind, amountType, exploitationReserveFilter)
                : DataAccess.BudgetOverview.getTransactions(budgetYear, journalElementRoute, transactionKind, amountType, exploitationReserveFilter)

            worker.then((response) => {
                setTransactions({
                    expression: overviewTypeDictionary[view],
                    column: `${column.ownerBand ? component.columnOption(column.ownerBand).caption : ''} - ${column.caption}`,
                    layout: response.data.layout,
                    data: response.data.values,
                    sourceData: item,
                    ammountType: amountType,
                });
                setSelectedCell({ rowIndex, columnIndex });
            }).catch((error) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
            }).finally(() => {
                setLoadingTransactions(false);
            });
            return;
        }
    }, [breadCrumb, gridData, view, budgetYear, structureRK]);

    const scrollToSelectedRow = (rowIndex) => {
        const selector = `#overview-grid .dx-datagrid-rowsview .dx-row[aria-rowindex="${rowIndex}"]`;
        DomUtils.scrollToElement(selector, 200);
    };

    // Reset grid data whenever filters change
    useEffect(() => {
        setGridData({ data: [], availableColumns: [], groupSummaries: [], totalSummaries: [] });
        setBudgetYear(baseYear);
        setTransactions(undefined);
        setSelectedCell(null);
        //Adjust group based on filter
    }, [baseYear, structureRK, structureNodeRK, extraFilters]);

    const groupByList = useMemo(() => {
        const returnValue = [...groupByOptions];
        if (extraFilters.transactionKind.length > 0) {
            const index = returnValue.findIndex(option => option.id === 'transactionKind');
            if (index !== -1) {
                returnValue.splice(index, 1);
            }
        }
        if (extraFilters.exploatatie.length > 0) {
            const index = returnValue.findIndex(option => option.id === 'exploitationReserve');
            if (index !== -1) {
                returnValue.splice(index, 1);
            }
        }

        return returnValue;
    }, [extraFilters]);

    const visibleBreadCrumb = useMemo(() => breadCrumb.filter(item => !item.hidden), [breadCrumb]);

    const budgetYears = useMemo(() => {
        if (!financeSettings.item?.yearsAhead || !baseYear) return [];
        return Array(financeSettings.item.yearsAhead + 1).fill('').map((_, i) => baseYear + i);
    }, [financeSettings, baseYear]);

    //Minimize unnecessary rerenders, fe for onCellClick
    const memoizedOverviewGrid = useMemo(() => {
        if (!view || !gridData.data?.length) return <Text value='Voer een zoekopdracht uit om het budgetoverzicht te raadplegen.' />;
        //Switching visible on strucvtureview from drilldowns
        const viewSwitchVisible = visibleBreadCrumb.length > 1 && visibleBreadCrumb[visibleBreadCrumb.length - 1].returnedView === "StructureNode";
        //Grouping visible only on main views
        const availableGroupingOptions = groupByList.concat(...overviewTypeDictionary[view].groupBy);
        const groupingVisible = (view === "Elements" || view === "StructureNode") && availableGroupingOptions.length > 2 && visibleBreadCrumb.length <= 1;

        return <LsGrid
            id='overview-grid'
            keyExpr={overviewTypeDictionary[view].keyExpr}
            searching={true}
            showRowLines={true}
            showColumnLines={true}
            showBorders={true}
            allowColumnResizing={true}
            columnAutoWidth={false}
            enableColumnChooser={true}
            columns={gridData.availableColumns}
            dataSource={gridData.data}
            selectedRowIndex={selectedCell?.rowIndex}
            selectedColumnIndex={selectedCell?.columnIndex}
            onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
            onClickCell={onCellClick}
            showCellHintText={false}
            customToolbarItems={[
                <LsGridToolbarItem
                    visible={viewSwitchVisible}
                    location="before"
                    locateInMenu="auto"
                    cssClass='pr-100'>
                    <SelectElement<{ id: keyof typeof overviewTypeDictionary, text: string }>
                        id='input-viewmode'
                        label='Weergave'
                        displayExpr='text'
                        optionItems={viewModeOptions}
                        value={viewModeOptions?.find(vm => vm.id === view)}
                        clearable={false}
                        searchable={false}
                        editorSettings={{
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (val) => {
                                const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];
                                //No need bredcrumb update, just change view
                                //keep the budgetyear!
                                fetchDrillDown(lastBreadcrumbItem.value, breadCrumb, true, val.id, lastBreadcrumbItem?.kind, undefined, lastBreadcrumbItem?.budgetYear);
                            }
                        }}
                    />
                </LsGridToolbarItem>,
                <LsGridToolbarItem
                    visible={groupingVisible}
                    location="before"
                    locateInMenu="auto"
                    cssClass='pr-100'>
                    <SelectElement<GroupByOptionsType>
                        id='input-grouping'
                        label='Groeperen'
                        displayExpr='text'
                        optionItems={availableGroupingOptions}
                        value={groupBy}
                        clearable={false}
                        searchable={false}
                        editorSettings={{
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (val) => {
                                setGroupBy(val);

                                setGridData(draft => {
                                    draft.availableColumns.forEach((col) => {
                                        if (col.columns) {
                                            col.columns.forEach((c) => {
                                                c.groupIndex = undefined;
                                                c.groupCellRender = (data) => data.value;
                                                c.hidden = false;
                                                if (val.id !== 'nogroup') {
                                                    if (c.name === val.id) {
                                                        c.groupIndex = 0;
                                                    }
                                                }
                                            });
                                        }
                                    });
                                    const summaries = draft.totalSummaries.length === 0 ? draft.groupSummaries : draft.totalSummaries;

                                    if (val.id === 'nogroup') {
                                        draft.totalSummaries = summaries;
                                        draft.groupSummaries = [];
                                    } else {
                                        draft.groupSummaries = summaries;
                                        draft.totalSummaries = [];
                                    }
                                });
                                setBreadCrumb((prev) => {
                                    prev[visibleBreadCrumb.length - 1].groupBy = val;
                                    return prev;
                                });
                            }
                        }}
                    />
                </LsGridToolbarItem>,
                <LsGridToolbarItem
                    location="before"
                    locateInMenu="auto"
                    cssClass='pr-100'>
                    <SelectElement<number>
                        id='input-year'
                        label='Jaarschijf'
                        optionItems={budgetYears}
                        value={budgetYear}
                        clearable={false}
                        searchable={false}
                        editorSettings={{
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (val) => {
                                setBudgetYear(val);
                                const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];
                                fetchDrillDown(lastBreadcrumbItem.value, breadCrumb, true, lastBreadcrumbItem.returnedView, lastBreadcrumbItem?.kind, undefined, val);
                            }
                        }}
                    />
                </LsGridToolbarItem>,
                <LsGridToolbarItem location="before" locateInMenu="auto" cssClass='pr-100'>
                    <CheckboxElement
                        id='input-divideBy'
                        label='Bedragen delen'
                        altLabel='Delen door 1000'
                        value={divideBy.current === 1000 ? true : false}
                        editorSettings={{
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (val) => {
                                const divideValue = val ? 1000 : 1;
                                divideBy.current = divideValue;
                                setGridData(draft => {
                                    // Update availableColumns
                                    draft.availableColumns.forEach((col) => {
                                        if (col.columns) {
                                            col.columns.forEach((c) => {
                                                c.customizeText = (cellInfo) => formatText(cellInfo, divideValue);
                                            });
                                        }
                                    });

                                    // Update groupSummaries
                                    draft.groupSummaries.forEach((sum) => {
                                        sum.customizeText = (cellInfo) => formatText(cellInfo, divideValue);
                                    });

                                    // Update totalSummaries
                                    draft.totalSummaries.forEach((sum) => {
                                        sum.customizeText = (cellInfo) => formatText(cellInfo, divideValue);
                                    });
                                });
                            },
                        }}
                    />
                </LsGridToolbarItem>,
            ]}
            remoteOperations={{ summary: false, }}
            groupSummaries={gridData.groupSummaries}
            totalSummaries={gridData.totalSummaries}
            export={true}
            onExporting={onExporting}
        />;
    }, [gridData, view, onCellClick, loading, onExporting, groupBy, groupByList, divideBy.current, budgetYear, breadCrumb, visibleBreadCrumb, selectedCell]);

    if (!isAdditionalDataLoaded) return <></>;

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Budgetoverzicht</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Section look='white'>
                        <Filter
                            onFilter={() => {
                                setBudgetYear(baseYear);
                                divideBy.current = 1;
                                filterOverview();
                            }}
                            extraFilter={extraFilters}
                            setExtraFilter={(val: { [key in 'transactionKind' | 'exploatatie']: string[] }) => setExtraFilters((prev) => ({ ...prev, ...val }))}
                            disabled={loading} />
                    </Section>
                    <Section look="white" fixedWidth={true}>
                        <ResetZIndex>
                            <BreadcrumbBar>
                                {visibleBreadCrumb.length > 1 && visibleBreadCrumb.map((bv, index) => {
                                    const [bctext, hinttext, safeId] = bv.value
                                        ? bv.value.journalElementCode
                                            ? [
                                                bv.value.journalElementCode,
                                                `${bv.value.journalElementCode} - ${bv.value.journalElementName} \n${breadCrumb[index - 1]?.kind?.rk} - ${breadCrumb[index - 1]?.kind?.name} \nJaarschijf: ${bv.budgetYear}`,
                                                generateSafeId(bv.value.journalElementCode)
                                            ]
                                            : [
                                                bv.value.structureNodeCode,
                                                `${bv.value.structureNodeCode} - ${bv.value.structureNodeName} \nJaarschijf: ${bv.budgetYear}`,
                                                generateSafeId(bv.value.structureNodeCode as string)
                                            ]
                                        : ['', '', ''];
                                    return <DxButton
                                        key={`key - btn - ${index > 0 ? safeId : 'overzicht'}`}
                                        id={`breadcrumb - btn - ${index > 0 ? safeId : 'overzicht'} `}
                                        className='lias-button'
                                        type="default"
                                        stylingMode="text"
                                        icon={index === 0 ? "home" : undefined}
                                        text={index > 0 ? `${bctext}` : undefined}
                                        disabled={index === visibleBreadCrumb.length - 1}
                                        hint={index > 0 ? `${hinttext}` : 'Overzicht'}
                                        onClick={() => {
                                            setBudgetYear(bv.budgetYear);
                                            if (index === 0) {
                                                filterOverview(bv.groupBy, bv.budgetYear);
                                            } else {
                                                fetchDrillDown(bv.value, breadCrumb.slice(0, index), false, bv.returnedView, bv.kind, bv.groupBy, bv.budgetYear);
                                            }
                                        }}
                                    />
                                })}
                            </BreadcrumbBar>
                            {loading
                                ? <LoadPanel visible={loading} />
                                : memoizedOverviewGrid}
                        </ResetZIndex>
                    </Section>

                    <Transactions
                        transactions={transactions}
                        loading={loadingTransactions}
                        divideBy={divideBy.current}
                        onClose={() => {
                            setTransactions(null);
                            scrollToSelectedRow(selectedCell.rowIndex);
                        }}
                        onExporting={onExportingTransactions} />
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>
    );
};

export const formatText = (cellInfo, divideBy) => {
    // Format the value using European formatting
    const value = Number(cellInfo.value);
    if (!isNaN(value)) {
        const dividedValue = value / divideBy;
        return dividedValue.toLocaleString('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }
    return cellInfo.value; // Return original value if it's not a number
};

export const mapColumnProperties = (column, divideBy = 1, groupBy?: GroupByOptionsType) => {
    const colProps: GridColumn<any> = {
        align: DataUtils.getDefaultDxAlignment(column.dataType),
        dataType: DataUtils.mapStringToDxType(column.dataType, false, false),
        groupIndex: undefined,
        calculateGroupValue: undefined,
        groupCellRender: (data) => data.value,
        hidden: false,
    };

    if (colProps.dataType === 'number') {
        colProps.customizeText = (cellInfo) => formatText(cellInfo, divideBy);
    }

    //specific requests for column properties
    if (!/(name)$/i.test(column.dataField)) {
        colProps.width = "auto";
    } else {
        colProps.minWidth = 150;
    }

    if (!column.caption) {
        colProps.align = 'center';
        colProps.hideInColumnChooser = true;
    }

    if (colProps.dataType === 'number' && /(remaining)$/i.test(column.dataField)) {
        colProps.renderCustom = renderAmmountCell;
    }

    if (groupBy && groupBy.id !== 'nogroup' && changeCaseFirstLetter(column.dataField) === groupBy.id) {
        colProps.groupIndex = 0;
    }
    return colProps;
};

const changeCaseFirstLetter = (sourceText: string, toLower = true) => {
    if (!sourceText) return;
    if (toLower) return sourceText.charAt(0).toLowerCase() + sourceText.slice(1);
    return sourceText.charAt(0).toUpperCase() + sourceText.slice(1);
};

const mapColumns = (layout, divideBy, groupBy?: GroupByOptionsType) => {
    const columns = layout?.map(c => ({
        title: c.caption,
        columns: c.columns.map(col => ({
            name: changeCaseFirstLetter(col.dataField),
            title: col.caption,
            hideInColumnChooser: !col.caption,
            isClickable: col.isClickable,
            ...mapColumnProperties(col, divideBy, groupBy),
        })),
    }));
    return columns;
};

const mapSummaries = (layout, divideBy) => {
    const summaries: { column: string, summaryType: string }[] = layout?.map(c =>
        c.columns.map(col => {
            if (col.dataField && DataUtils.mapStringToDxType(col.dataType, false, false) === 'number')
                return {
                    column: changeCaseFirstLetter(col.dataField),
                    summaryType: "sum",
                    alignByColumn: true,
                    skipEmptyValues: true,
                    customizeText: (itemInfo) => `${formatText(itemInfo, divideBy)}`
                };
            return null;
        })).flat().filter(Boolean);
    return summaries;
};

//For jurnal element kinds and structure nodes, add context menu items
const mapColumnExtraProperties = (view,
    keyExpr: string,
    availableItems: Domain.Finance.JournalElementKind[] | Domain.Finance.StructureNode[] = [],
    onContextClick?: (selectedData: Domain.Finance.BudgetOverviewValue, selectedItem?: Domain.Finance.JournalElementKind, view?: keyof typeof overviewTypeDictionary) => void) => {

    switch (view) {
        case 'Elements': {
            if (!availableItems.length) return;
            return {
                'renderCustom': ({ data }) => (
                    <ContextMenu<Domain.Finance.BudgetOverviewValue>
                        item={data}
                        keyExpr={keyExpr}
                        uniqueId={`${data[keyExpr]}-${data['transactionKind']}`}
                        actions={availableItems.map(
                            (item) => {
                                return {
                                    action: (data) => { return onContextClick(data, item); },
                                    ariaLabel: `Drilldown journaal ${item.rk}`,
                                    actionName: `drilldown-budgetJournal-${item.rk}`,
                                    displayName: `${item.name}`
                                };
                            }).concat(
                                {
                                    action: (data) => { return onContextClick(data, undefined, 'Combinations'); },
                                    ariaLabel: `Drilldown combinaties ${data[keyExpr]}`,
                                    actionName: `drilldown-structurenode-combinations-${data[keyExpr]}`,
                                    displayName: 'Combinatie'
                                }
                            )}
                    />)
            }
        }
        case 'StructureNode': {
            return {
                'renderCustom': ({ data }) => (
                    <ContextMenu<Domain.Finance.BudgetOverviewValue>
                        item={data}
                        keyExpr={keyExpr}
                        uniqueId={`${data[keyExpr]}-${data['transactionKind']}`}
                        actions={[
                            {
                                action: (data) => { return onContextClick(data); },
                                ariaLabel: `Drilldown struktuurelement ${data[keyExpr]}`,
                                actionName: `drilldown-structurenode-${data[keyExpr]}`,
                                displayName: 'Structuurelement',
                                hidden: availableItems.filter(item => item['parentRK'] === data.structureNodeRK).length === 0,
                            },
                            {
                                action: (data) => { return onContextClick(data, undefined, 'Elements'); },
                                ariaLabel: `Drilldown elementkinds ${data[keyExpr]}`,
                                actionName: `drilldown-structurenode-kinds-${data[keyExpr]}`,
                                displayName: 'Boekingselement'
                            },
                            {
                                action: (data) => { return onContextClick(data, undefined, 'Combinations'); },
                                ariaLabel: `Drilldown combinaties ${data[keyExpr]}`,
                                actionName: `drilldown-structurenode-combinations-${data[keyExpr]}`,
                                displayName: 'Combinatie'
                            }
                        ]}
                    />)
            };
        }
        case 'Combinations':
        default: return;
    }
};

const renderAmmountCell = (item) => {
    return <>
        <AmmountText
            color={item.value < 0 ? '#960000' : '#006E00'}>
            {item.text}
            <CircleIcon
                sx={{
                    fontSize: '0.8rem',
                    color: item.value < 0 ? '#DB0000' : '#3DC700',
                    stroke: item.value < 0 ? '#960000' : '#006E00',
                    strokeWidth: 1,
                    marginLeft: '0.5rem',
                    marginBottom: '-0.1rem',
                }}
            />
        </AmmountText>
    </>;
};
export { Overview as index };


const AmmountText = styled.span<{ color: string }>`  
  font-weight: inherit;
  word-break: break-word;
  color: ${(p) => p.color ? p.color : palette.grey2};
`;
import React from 'react';
import { DecimalEditor } from './index.editor';
import { DecimalProps } from './index.props';

/**
 * Represents a UI component that renders a decimal element.
 */
export const DecimalElement: React.FC<DecimalProps> = (props) => {
    return <DecimalEditor {...props} />;
};

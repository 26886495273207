import React from 'react';
import { DropDownProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';
import { DropDownField } from '@liasincontrol/ui-devextreme';
import _ from 'lodash';

/**
 * Represents a UI component that renders a single select dropdown editor.
 */
export const DropDownEditor = <V,>(props: DropDownProps<V>) => (
    <DropDownField
        id={props.id}
        key={props.id}
        displayExpr={props.displayExpr}
        valueExpr={props.valueExpr}
        keyExpr={props.keyExpr}
        parentIdExpr={props.parentIdExpr}
        placeholder={props.placeholder ?? 'Kies…'}
        label={props.label}
        helpText={props.helpText}
        disabled={_.isEmpty(props.editorSettings) || props.editorSettings.disabled}
        readOnly={props.editorSettings?.readOnly}
        mandatory={props.editorSettings?.restrictions?.required}
        error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
        value={props.value}
        optionItems={props.optionItems}
        onChange={(val) => {
            props.editorSettings?.onChange?.(val);
        }}
        clearable={props.clearable}
        searchable={props.searchable === undefined ? true : props.searchable}
        withoutFeedback={props.editorSettings?.withoutFeedback}
        openOnFieldClick={props.openOnFieldClick}
    />
);


import _, { Dictionary } from 'lodash';
import { v4 as uuid } from 'uuid';
import * as Domain from '@liasincontrol/domain';
import { ValidationUtils } from '../validators/ValidationUtils';
import { AttachmentsHelper } from './AttachmentsHelper';

/**
 * Represents a helper that is responsible for managing page operations.
 */
export class OperationsHelper {

    public static getElementList = (layers: Domain.Publisher.Layer[]): Dictionary<Domain.Publisher.Element> => {
        const elementList: Dictionary<Domain.Publisher.Element> = {};
        Domain.Publisher.LayerKinds.forEach((layerKind) => {
            const layer = layers.find(item => item.kind === layerKind);
            if (!layer) {
                return;
            }

            layer.operations.forEach(operation => {
                if (operation.operationKind === Domain.Publisher.OperationKind.Patch) {
                    // Apply the patch over the existing element that was set before:
                    OperationsHelper.applyPatchOperation(operation, elementList);
                    return;
                }

                elementList[operation.element.elementId] = operation.element;
            });
        });

        return elementList;
    };

    public static getElementStructure = (layers: Domain.Publisher.Layer[]): Domain.Publisher.ElementNode => {
        let root: Domain.Publisher.ElementNode;
        Domain.Publisher.LayerKinds.forEach((layerKind) => {
            const layer = layers.find(item => item.kind === layerKind);
            if (!layer) {
                return;
            }

            layer.operations.forEach(operation => {
                root = OperationsHelper.applyOperation(operation, root);
            });
        });

        return root;
    };

    public static applyPatchOperation = (operation: Domain.Publisher.Operation, elementList: Dictionary<Domain.Publisher.Element>): void => {
        Object.keys(operation.element.fields).forEach((fieldId) => {
            elementList[operation.other].fields[fieldId] = operation.element.fields[fieldId];
        });
        Object.keys(operation.element.complexFields).forEach((rowIndex) => {
            elementList[operation.other].complexFields[rowIndex] = operation.element.complexFields[rowIndex];
        });
        Object.keys(operation.element.attachments).forEach((attachmentIndex) => {
            elementList[operation.other].attachments[attachmentIndex] = operation.element.attachments[attachmentIndex];
        });
    };

    /**
     * Updates a collection of operations based on a series of field changes originating in the template editor.
     * @param operations Operations to update.
     * @param fieldChanges Array of field changes to apply.
     * @param elementDefinitions Dictionary of element definitions.
     * @param attachments Dictionary of attachments.
     * 
     */
    public static applyFieldChangesToOperations = (operations: Domain.Publisher.Operation[], fieldChanges: Domain.Publisher.FieldPatch[], elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, variables: Domain.Shared.ComplexFieldItem[], attachments: Record<string, File>): Domain.Publisher.Operation[] => {
        return operations?.map((operation) => {
            const applicableChanges = fieldChanges.filter((change) => change.elementId === operation.element.elementId);

            if (!applicableChanges.length) {
                return operation;
            }

            const elementDefinition = elementDefinitions[operation.element.elementDefinitionId];
            const fieldsClone = Object.assign({}, operation.element.fields);
            const complexFieldsClone = operation.element.complexFields ? _.cloneDeep(operation.element.complexFields) as Domain.Shared.ComplexField[] : null;
            const attachmentsClone = operation.element.attachments ? _.cloneDeep(operation.element.attachments) as Domain.Shared.Attachment[] : []; //empty array, not null!

            applicableChanges.forEach((fieldChange) => {
                const newFieldValue = fieldChange.value;
                const fieldId = fieldChange.fieldId;

                let oldFieldValue = null; //need to keep the old field value so we can locate any old attachments and remove/change them.
                let fieldDefinition = elementDefinition.fields.find((field) => field.id === fieldId);

                if (fieldDefinition) {
                    // normal field.
                    oldFieldValue = fieldsClone[fieldId];

                    fieldsClone[fieldId] = newFieldValue;
                } else {
                    // find complex field definition.
                    const { fieldDefinition: complexFieldDefinition, complexDefinition } = elementDefinition.complexFields.map((complexDef) => {
                        const matchedField = complexDef.fields.find((field) => field.id === fieldId);
                        if (matchedField) {
                            return {
                                fieldDefinition: matchedField,
                                complexDefinition: complexDef
                            }
                        }
                        return null;
                    }).filter(def => def).pop();

                    if (complexDefinition) {
                        //change complex field value
                        fieldDefinition = complexFieldDefinition;
                        const complexField = complexFieldsClone.find((complex) => complex.elementDefinitionId === complexDefinition.id)
                        complexField.fields[fieldId] = newFieldValue;

                        oldFieldValue = complexField.fields[fieldId]
                    }
                }

                // Check if we have to update the attachments.
                if (fieldDefinition) {
                    if (fieldDefinition.dataType === 'Attachment') {
                        const newAttachmentId = attachments[newFieldValue];

                        // Check if we can find at attachment with the old field value
                        const existingIndex = !ValidationUtils.isEmpty(oldFieldValue) ? attachmentsClone?.findIndex(att => att.id === oldFieldValue) : -1;
                        if (existingIndex >= 0) {
                            // If so, replace it with the attachment we've uploaded.
                            if (newAttachmentId) {
                                const attachmendDescriptor = AttachmentsHelper.mapFileToAttachment(newAttachmentId, newFieldValue, attachmentsClone[existingIndex].isFieldAttachment);

                                attachmentsClone[existingIndex] = attachmendDescriptor;
                            } else {
                                // remove it
                                attachmentsClone.splice(existingIndex, 1);
                            }
                        } else {
                            // Nothing in the attachments array. If we're uploading a new file, just add it here.
                            if (newAttachmentId) {
                                attachmentsClone.push(AttachmentsHelper.mapFileToAttachment(newAttachmentId, newFieldValue, true));
                            }
                        }
                    }
                } else {
                    console.error(`Cannot match field ${fieldId} to any definition on element ${elementDefinition.id}. Ignoring new value of ${newFieldValue}`);
                }
            });

            return {
                ...operation,
                element: {
                    ...operation.element,
                    attachments: attachmentsClone,
                    fields: fieldsClone,
                    complexFields: complexFieldsClone,
                },
            };
        });
    };

    /**
     * Updates a collection of operations based on a series of complex field changes originating in the template editor.
     * @param operations Operations to update.
     * @param complexFieldChanges Array of complex field changes to apply.
     * @param elementDefinitions Dictionary of element definitions.
     * @param attachments Dictionary of attachments.
     * 
     */
    public static applyComplexFieldChangesToOperations = (operations: Domain.Publisher.Operation[], complexFieldChanges: Domain.Publisher.ComplexFieldPatch[], elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, attachments: Record<string, File>): Domain.Publisher.Operation[] => {
        return operations?.map((operation) => {
            const applicableComplexChanges = complexFieldChanges.filter((change) => change.elementId === operation.element.elementId);
            if (!applicableComplexChanges.length) {
                return operation;
            }

            // TODO: As there are no controls with complex fields to be changed at the template level.
            // So this method will be finished when controls will have complex field to be edited on this level.
            applicableComplexChanges.forEach((fieldChange) => {
                console.log(fieldChange);
            });

            const fieldsClone = Object.assign({}, operation.element.fields);
            const complexFieldsClone = operation.element.complexFields ? _.cloneDeep(operation.element.complexFields) as Domain.Shared.ComplexField[] : null;
            const attachmentsClone = operation.element.attachments ? _.cloneDeep(operation.element.attachments) as Domain.Shared.Attachment[] : []; //empty array, not null!

            return {
                ...operation,
                element: {
                    ...operation.element,
                    attachments: attachmentsClone,
                    fields: fieldsClone,
                    complexFields: complexFieldsClone,
                },
            };
        });
    };

    public static applyOperation = (operation: Domain.Publisher.Operation, root: Domain.Publisher.ElementNode): Domain.Publisher.ElementNode => {
        switch (operation.operationKind) {
            case Domain.Publisher.OperationKind.AddRoot:
                root = {
                    elementId: operation.element.elementId,
                    children: [],
                    parentId: undefined
                };
                break;

            case Domain.Publisher.OperationKind.ReplaceWith:
            case Domain.Publisher.OperationKind.AddTo:
                if (root.elementId === operation.other) {
                    root.children.push({
                        elementId: operation.element.elementId,
                        children: [],
                        parentId: root.elementId
                    });
                } else {
                    // Add to other element node:
                    const nodes: Domain.Publisher.ElementNode[] = root.children.flatten(item => item.children);
                    const node = nodes.find(item => item.elementId === operation.other);

                    if (node) {
                        node.children.push({
                            elementId: operation.element.elementId,
                            children: [],
                            parentId: node.elementId
                        });
                    } else {
                        console.error(`Unknown node. Node element id: ${operation.other}`);
                    }
                }
                break;

            case Domain.Publisher.OperationKind.InsertAfter:
                const nodes: Domain.Publisher.ElementNode[] = root.children.flatten(item => item.children);
                const node = nodes.find(item => item.elementId === operation.other);
                if (node) {
                    const parent = nodes.find(item => item.elementId === node.parentId);
                    const index = parent.children.indexOf(node);
                    parent.children.splice(index + 1, 0, {
                        elementId: operation.element.elementId,
                        children: [],
                        parentId: parent.elementId
                    });
                } else {
                    console.error(`Unknown node. Node element id: ${operation.other}`);
                }

                break;

            case Domain.Publisher.OperationKind.Patch:
            case Domain.Publisher.OperationKind.Undefined:
            default:
                // Do nothing;
                break;
        }

        return root;
    };

    private static getFields = <T extends {}>(element: T, fieldDefinitions: Domain.Shared.FieldDefinition[]): Record<string, string> => {
        const getFieldDefinition = (fieldName) => fieldDefinitions.find(item => item.systemId === Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, element, fieldName));
        return Object.keys(element)
            .reduce((collection, fieldName) => {
                if (!ValidationUtils.isEmpty(element[fieldName])) {
                    return ({
                        ...collection,
                        [getFieldDefinition(fieldName).id]: `${element[fieldName]}`
                    });
                } else {
                    return collection;
                }
            }, {});
    };

    public static getOperation = <T extends {}>(elementDefinition: Domain.Shared.ElementDefinition, element: T, other: string, operationKind: Domain.Publisher.OperationKind) => {
        const operation: Domain.Publisher.Operation = {
            operationKind: operationKind,
            element: {
                elementDefinitionSystemId: elementDefinition.systemId,
                elementDefinitionId: elementDefinition.id,
                attachments: [],
                complexFields: [],
                elementId: uuid(),
                fields: OperationsHelper.getFields<T>(element, elementDefinition.fields)
            },
            other: other
        };

        return operation;
    };

    /**
     * Creates an element with the page operations that are related to.
     * 
     * @param elementSystemId Defines the system unique identifier of the element that has to be created.
     * @param other Defines the other of the element.
     * @param operationKind Defines the operation kind.     
     * @param getElementDefinition A callback that returns an element defintion based on the element system id.
     *  
     * @returns a list of page operations.
     */
    public static createElement = (
        elementSystemId: Domain.SystemElementDefinitions.Pub,
        other: string,
        operationKind: Domain.Publisher.OperationKind,
        getElementDefinition: (systemId: Domain.SystemElementDefinitions.Pub) => Domain.Shared.ElementDefinition,
        includeParentContainerItem = true): Domain.Publisher.Operation[] => {

        const operations: Domain.Publisher.Operation[] = [];
        if (includeParentContainerItem) {
            operations.push(OperationsHelper.getOperation<Domain.Publisher.ContainerItemElement>(
                getElementDefinition(Domain.SystemElementDefinitions.Pub.ContainerItem),
                new Domain.Publisher.ContainerItemElement(),
                other,
                operationKind
            ));
        }

        switch (elementSystemId) {
            case Domain.SystemElementDefinitions.Pub.TabContainer: {
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.TabContainerElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.TabContainer),
                        new Domain.Publisher.TabContainerElement(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                const ciElement = new Domain.Publisher.ContainerItemElement('Tab', '');
                ciElement.showWhitespaceBottom = false;
                ciElement.showWhitespaceLeft = false;
                ciElement.showWhitespaceRight = false;
                ciElement.showWhitespaceTop = false;
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.ContainerItemElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.ContainerItem),
                        ciElement,
                        operations[1].element.elementId,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                const stackContainerDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.StackContainer);
                const directionDefinition = stackContainerDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.StackContainerDirection);
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.StackContainerElement>(
                        stackContainerDefinition,
                        new Domain.Publisher.StackContainerElement(directionDefinition.optionItems.find((optionItem) => optionItem.value === Domain.Publisher.Direction.Vertical).id),
                        operations[2].element.elementId,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;
            }
            case Domain.SystemElementDefinitions.Pub.AccordionContainer: {
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.AccordionContainerElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.AccordionContainer),
                        new Domain.Publisher.AccordionContainerElement(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                const ciElement = new Domain.Publisher.ContainerItemElement('Accordeon', '');
                ciElement.showWhitespaceBottom = false;
                ciElement.showWhitespaceLeft = false;
                ciElement.showWhitespaceRight = false;
                ciElement.showWhitespaceTop = false;
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.ContainerItemElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.ContainerItem),
                        ciElement,
                        operations[1].element.elementId,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                const stackContainerDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.StackContainer);
                const directionDefinition = stackContainerDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.StackContainerDirection);
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.StackContainerElement>(
                        stackContainerDefinition,
                        new Domain.Publisher.StackContainerElement(directionDefinition.optionItems.find((optionItem) => optionItem.value === Domain.Publisher.Direction.Vertical).id),
                        operations[2].element.elementId,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;
            }

            case Domain.SystemElementDefinitions.Pub.StackContainer:
                const directionFieldDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.StackContainer).fields.find(
                    (item) => item.systemId === Domain.SystemFieldDefinitions.Pub.StackContainerDirection
                );
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.StackContainerElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.StackContainer),
                        new Domain.Publisher.StackContainerElement(directionFieldDefinition.optionItems.find((optionItem) => optionItem.value === Domain.Publisher.Direction.Vertical).id),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.ImageControl:
                const imageSizeFieldDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.ImageControl).fields.find(
                    (item) => item.systemId === Domain.SystemFieldDefinitions.Pub.ImageSize
                );
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.ImageControlElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.ImageControl),
                        new Domain.Publisher.ImageControlElement(imageSizeFieldDefinition.optionStartValue),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.TitleControl:
                const stylingFieldDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.TitleControl).fields.find(
                    (item) => item.systemId === Domain.SystemFieldDefinitions.Pub.TitleStyling
                );
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.TitleControlElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.TitleControl),
                        new Domain.Publisher.TitleControlElement('', stylingFieldDefinition.optionStartValue),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.TextControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.TextControlElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.TextControl),
                        new Domain.Publisher.TextControlElement(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.HtmlElementControlElement>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.HtmlElementControl),
                        new Domain.Publisher.HtmlElementControlElement(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.ReferenceAttachmentsControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.ReferenceAttachments),
                        new Domain.Publisher.ReferenceAttachmentsControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.DataSourceText:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.DataSourceTextControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.DataSourceText),
                        new Domain.Publisher.DataSourceTextControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.MenuControl:
                const menuTypeFieldDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.MenuControl).fields.find(
                    (item) => item.systemId === Domain.SystemFieldDefinitions.Pub.MenuType
                );
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.MenuControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.MenuControl),
                        new Domain.Publisher.MenuControl('', menuTypeFieldDefinition.optionStartValue),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.TileMenuControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.TileMenuControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.TileMenuControl),
                        new Domain.Publisher.TileMenuControl(''),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.DataTableControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.DataTableControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.DataTableControl),
                        new Domain.Publisher.DataTableControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.PieChartControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.PieChartControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.PieChartControl),
                        new Domain.Publisher.PieChartControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.BarChartControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.BarChartControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.BarChartControl),
                        new Domain.Publisher.BarChartControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.LineChartControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.LineChartControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.LineChartControl),
                        new Domain.Publisher.LineChartControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.PerformanceInformationControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.PerformanceInformationControl),
                        new Domain.Publisher.PerformanceInformationControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
                const newControl = new Domain.Publisher.AccordionDataControl();
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.AccordionDataControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.AccordionDataControl),
                        newControl,
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.TabDsControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.TabDsControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.TabDsControl),
                        new Domain.Publisher.TabDsControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.TreeViewControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.TreeViewControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.TreeViewControl),
                        new Domain.Publisher.TreeViewControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.AccordionDsControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.AccordionDsControl),
                        new Domain.Publisher.AccordionDsControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.MapControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.MapControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.MapControl),
                        new Domain.Publisher.MapControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;

            case Domain.SystemElementDefinitions.Pub.PivotTableControl:
                operations.push(
                    OperationsHelper.getOperation<Domain.Publisher.PivotTableControl>(
                        getElementDefinition(Domain.SystemElementDefinitions.Pub.PivotTableControl),
                        new Domain.Publisher.PivotTableControl(),
                        includeParentContainerItem ? operations[0].element.elementId : other,
                        Domain.Publisher.OperationKind.AddTo
                    )
                );
                break;
            default:
                return null;
        }

        return operations;
    };

    /**
     * Gets all the children operations, from all hierchical levels, for a specific operation.
     * 
     * @param allOperations Defines the collection of operations.
     * @param containerItemElementId Defines the container item's element id.
     * @param includeSelf Determines if the current element operation should be included at the beginning of the result.
     */
    public static getChildrenOperations = (allOperations: Domain.Publisher.Operation[], containerItemElementId: string, includeSelf = false): Domain.Publisher.Operation[] => {
        let childrenOperations: Domain.Publisher.Operation[] = [];
        if (includeSelf) {
            childrenOperations.push(allOperations.find((operation) => operation.element.elementId === containerItemElementId));
        }
        const children = allOperations.filter((operation) => operation.other === containerItemElementId && operation.operationKind === Domain.Publisher.OperationKind.AddTo);
        children.forEach((child) => {
            childrenOperations.push(child);
            const secondLevelChildren = OperationsHelper.getChildrenOperations(allOperations, child.element.elementId, false);
            childrenOperations = childrenOperations.concat(secondLevelChildren);
        });

        return childrenOperations;
    };

    /**
     * Gets the next or previous container item sibling.
     * 
     * @param allOperations Defines the collection of operations.
     * @param containerItemElementId Defines the container item's element id.
     * @param next Determines if the next or the previous sibling should be returned.
     */
    public static getSiblingOperation = (allOperations: Domain.Publisher.Operation[], containerItemElementId: string, next: boolean): Domain.Publisher.Operation => {
        const allowedNextKinds = [Domain.Publisher.OperationKind.InsertAfter];
        const allowedPrevKinds = [Domain.Publisher.OperationKind.InsertAfter, Domain.Publisher.OperationKind.ReplaceWith];

        if (next) {
            return allOperations.find((op) => op.other === containerItemElementId && allowedNextKinds.includes(op.operationKind));
        } else {
            return allOperations.find((op) => op.element.elementId === containerItemElementId && allowedPrevKinds.includes(op.operationKind));
        }
    };

    // TODO: discuss if we will keep the operations stored in the container controls or not.
    public static getContainerChildSiblingOperation = (allOperations: Domain.Publisher.Operation[], containerItemElementId: string, parentControId: string, next: boolean): Domain.Publisher.Operation => {
        const siblingElementIds = allOperations
            .filter((op) => op.other === parentControId && op.operationKind === Domain.Publisher.OperationKind.AddTo)
            .map((op) => op.element.elementId);
        const siblingIndex = siblingElementIds.indexOf(containerItemElementId);

        if (next) {
            return allOperations.find((op) => op.element.elementId === siblingElementIds[siblingIndex + 1]);
        } else {
            return allOperations.find((op) => op.element.elementId === siblingElementIds[siblingIndex - 1]);
        }
    };

    /**
     * Processes the operation collection to remove a specific element.
     * 
     * @param templateOperations Defines all the page template operations.
     * @param elementId Defines the element id.
     * @param isControl Determines if the element is a control or a container item.
     */
    public static removeElement = (templateOperations: Domain.Publisher.Operation[], elementId: string, isControl: boolean): Domain.Publisher.Operation[] => {
        const controlOperation = templateOperations.find((operation) => operation.element.elementId === elementId);
        const containerItemOperation = templateOperations.find((operation) => operation.element.elementId === (isControl ? controlOperation.other : elementId));
        // Custom rules using XOR for the TabContainer.
        // 1. On delete tab cotent don't delete it's CI.
        // 2. On delete tab(CI) delete CI and content.
        const parentContainerOperation = templateOperations.find((operation) => operation.element.elementId === containerItemOperation.other);
        const includeParent = isControl !== (parentContainerOperation?.element?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TabContainer ||
            parentContainerOperation?.element?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.AccordionContainer);

        // End custom rule
        const toDeleteElementIds = OperationsHelper.getChildrenOperations(templateOperations, containerItemOperation.element.elementId, includeParent).map((op) => op.element.elementId);
        const prevContainerItemElementId = OperationsHelper.getSiblingOperation(templateOperations, containerItemOperation.other, false)?.element.elementId;
        const nextContainerItemElementId = OperationsHelper.getSiblingOperation(templateOperations, containerItemOperation.element.elementId, true)?.element?.elementId;
        const ciOther = containerItemOperation.other;

        return templateOperations.map((operation) => {
            // Delete the control, it's CI and all of it's children.
            if (toDeleteElementIds.includes(operation.element.elementId)) return null;

            if (nextContainerItemElementId && nextContainerItemElementId === operation.element.elementId) {
                // Replace the next CI parent id with the id from the previous CI element id.
                if (prevContainerItemElementId) {
                    return { ...operation, other: prevContainerItemElementId };
                } else {
                    // If this is the first CI, replace the next CI op. kind and parent id from the current CI.
                    return { ...operation, operationKind: Domain.Publisher.OperationKind.ReplaceWith, other: ciOther };
                }
            }

            return operation;
        }).filter((operation) => operation);
    };

    /**
     * Processes the operation collection to move a specific element in a specific direction.
     * 
     * @param templateOperations Defines all the page template operations.
     * @param elementId Defines the control's element id.
     * @param isControl Determines if the element is a control or a container item.
     * @param moveUp Determines the control's moving direction.
     */
    public static moveElement = (templateOperations: Domain.Publisher.Operation[], elementId: string, isControl: boolean, moveUp: boolean): Domain.Publisher.Operation[] => {
        const controlOperation = templateOperations.find((operation) => operation.element.elementId === elementId);
        const containerItemOperation = templateOperations.find((operation) => operation.element.elementId === (isControl ? controlOperation.other : elementId));
        const containerItemOperationIndex = templateOperations.indexOf(containerItemOperation);
        const ciElementId = containerItemOperation.element.elementId;
        const ciOther = containerItemOperation.other;
        const ciKind = containerItemOperation.operationKind;

        //if container move only in containers?

        // const parentContainerOperation = templateOperations.find((operation) => operation.element.elementId === containerItemOperation.other);
        // // Custom rules for the container controls.
        // const isContainerControl = parentContainerOperation?.element?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TabContainer
        //     || parentContainerOperation?.element?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.AccordionContainer
        //     || parentContainerOperation?.element?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.StackContainer;
        // // End custom rule
        if (moveUp) {
            // Previous CI before the selected CI.
            const prevContainerItemOperation = OperationsHelper.getSiblingOperation(templateOperations, containerItemOperation.other, false);
            const prevOperationIndex = templateOperations.indexOf(prevContainerItemOperation);
            const prevElementId = prevContainerItemOperation.element.elementId;
            const prevOther = prevContainerItemOperation.other;
            const prevKind = prevContainerItemOperation.operationKind;

            // Next CI after the selected CI.
            const nextContainerItemOperation = OperationsHelper.getSiblingOperation(templateOperations, containerItemOperation.element.elementId, true);
            // Update the operation collection with the swaped element ids and kinds.
            templateOperations = templateOperations.map((op) => {
                if (op.element.elementId === containerItemOperation.element.elementId) {
                    return {
                        ...op,
                        other: prevOther,
                        operationKind: prevKind
                    };
                } else if (op.element.elementId === prevContainerItemOperation.element.elementId) {
                    return {
                        ...op,
                        other: ciElementId,
                        operationKind: ciKind
                    };
                } else if (nextContainerItemOperation && op.element.elementId === nextContainerItemOperation.element.elementId) {
                    return {
                        ...op,
                        other: prevElementId
                    };
                }

                return op;
            });

            const allMainOperations = OperationsHelper.getChildrenOperations(templateOperations, containerItemOperation.element.elementId, true);

            // Swap positions between the main CI operations and the previous CI operations.
            templateOperations.splice(containerItemOperationIndex, allMainOperations.length);
            templateOperations.splice(prevOperationIndex, 0, ...allMainOperations);
        } else {
            // Next CI after the selected CI.
            //TODO: For tab container get the next sibling that has AddTo to the parent, not InsertAfter from the containerItemOperation.
            const nextContainerItemOperation = OperationsHelper.getSiblingOperation(templateOperations, containerItemOperation.element.elementId, true);
            const nextOperationIndex = templateOperations.indexOf(nextContainerItemOperation);
            const nextCiElementId = nextContainerItemOperation.element.elementId;
            const nextCiKind = nextContainerItemOperation.operationKind;

            // Second next CI after the selected CI.
            const nextNextContainerItemOperation = OperationsHelper.getSiblingOperation(templateOperations, nextContainerItemOperation.element.elementId, true);
            // Update the operation collection with the swaped element ids and kinds.
            templateOperations = templateOperations.map((op) => {
                if (op.element.elementId === containerItemOperation.element.elementId) {
                    return {
                        ...op,
                        other: nextCiElementId,
                        operationKind: nextCiKind
                    };
                } else if (op.element.elementId === nextContainerItemOperation.element.elementId) {
                    return {
                        ...op,
                        other: ciOther,
                        operationKind: ciKind
                    };
                } else if (nextNextContainerItemOperation && op.element.elementId === nextNextContainerItemOperation.element.elementId) {
                    return {
                        ...op,
                        other: ciElementId,
                    };
                }

                return op;
            });

            const allNextOperations = OperationsHelper.getChildrenOperations(templateOperations, nextContainerItemOperation.element.elementId, true);

            // Swap positions between the main CI operations and the next CI operations.
            templateOperations.splice(nextOperationIndex, allNextOperations.length);
            templateOperations.splice(containerItemOperationIndex, 0, ...allNextOperations);
        }

        return templateOperations;
    };

    /**
     * Process a collection of template operations by swapping the location of a collection of operations at a specific index.
     * 
     * @param templateOperations Defines all the page template operations.
     * @param opIndex Defines the template operation index.
     * @param moveUp Determines the control's moving direction.
     */
    public static swapOperations = (templateOperations: Domain.Publisher.Operation[], opIndex: number, moveUp: boolean, steps = 1, recursive = false) => {

        const otherChildren = templateOperations.filter(operation => operation.other === templateOperations[opIndex].other);
        const otherChildrenIndex = otherChildren.findIndex(item => item.element.elementId === templateOperations[opIndex].element.elementId);

        //What to move
        const operation = otherChildren[otherChildrenIndex];
        const fromIndex = templateOperations.indexOf(operation);
        const fromOperations = recursive
            ? OperationsHelper.getChildrenOperations(templateOperations, operation.element.elementId, true)
            : templateOperations.filter(item => item.other === operation.element.elementId);
        fromOperations.forEach(op => {
            templateOperations.splice(templateOperations.indexOf(op), 1);
        });

        //            x               x    
        //    0    1  2    3   4  5   6    7  8    9
        //op: tab1 v1 tab2 xxx v2 yyy tab3 v3 tab4 v4 tab5 v5
        // from tab3 to tab2
        // fromOperations = [tab3, v3]
        //fromIndex = 6     

        //moveUp: true move to the left
        //op: tab1 v1 tab2 xxx v2 yyy tab4 v4 tab5 v5
        // toOperations = [tab2, v2]
        //toIndex = 2

        //op: tab1 v1 xxx yyy tab4 v4 tab5 v5
        //           \/[...from, ....to] 
        //op: tab1 v1   xxx yyy tab4 v4 tab5 v5

        //moveUp: false move to the right
        //op: tab1 v1 xxx yyy tab4 v4 tab5 v5

        //where to move
        const toOperation = moveUp ? otherChildren[otherChildrenIndex - steps] : otherChildren[otherChildrenIndex + steps];
        const toIndex = templateOperations.indexOf(toOperation);
        const toOperations = recursive
            ? OperationsHelper.getChildrenOperations(templateOperations, toOperation.element.elementId, true)
            : templateOperations.filter(item => item.other === toOperation.element.elementId);
        toOperations.forEach(op => {
            templateOperations.splice(templateOperations.indexOf(op), 1);
        });

        if (moveUp) { //move items to the left
            templateOperations.splice(toIndex, 0, ...fromOperations, ...toOperations);
        } else { //move items to the right
            templateOperations.splice(fromIndex, 0, ...toOperations, ...fromOperations);
        }
    };
}

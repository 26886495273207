import React, { useMemo } from "react";
import { DropDownElement, SelectElement } from '@liasincontrol/ui-elements';
import { Button, IDataItemProps } from "@liasincontrol/ui-basics";
import * as Domain from '@liasincontrol/domain';
import { useUserSettings } from '@liasincontrol/core-service';
import { useBaseYears } from "../../shared/hooks";
import { useStructures } from "../../shared/hooks/useStructures";
import { useStructureNodes } from "../../shared/hooks/useStructureNodes";
import { Grid as MuiGrid } from '@mui/material';

export type FilterProps = {
    disabled?: boolean;
    extraFilter: { transactionKind: string[], exploatatie: string[] };
    setExtraFilter: (val: { [key in 'transactionKind' | 'exploatatie']: string[] }) => void;
    onFilter?: () => void;
    resetData?: () => void;
};

export const costBenefitFilterItems: IDataItemProps<string>[] = [{
    label: 'Lasten',
    value: 'Lasten',
}, {
    label: 'Baten',
    value: 'Baten',
}];

export const exploatatieFilterItems: IDataItemProps<string>[] = [{
    label: 'Exploitatie',
    value: 'Exploitatie',
}, {
    label: 'Reserve',
    value: 'Reserve',
}];

export const Filter: React.FC<FilterProps> = (props: FilterProps) => {
    const baseYears = useBaseYears();
    const structures = useStructures();
    const { structureNodes, fetchStructureNodes } = useStructureNodes();

    const userSettingsContext = useUserSettings();

    const structureOptions = useMemo(() => (structures.items?.filter(item => item.baseYear === userSettingsContext.baseYear)), [structures, userSettingsContext.baseYear]);

    const structureNodeOptions = useMemo(() => (
        userSettingsContext.structureRK && structureNodes[userSettingsContext.structureRK] ?
            structureNodes[userSettingsContext.structureRK].items?.map(item => item.parentRK === userSettingsContext.structureRK ? { ...item, parentRK: null } : item)
            : []
    ), [userSettingsContext.structureRK, structureNodes]);

    return (<>
        <MuiGrid container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, sm: 2, lg: 5, xl: 10, xxl: 16 }}
            justifyContent="flex-start"
            alignItems="flex-end">
            {/* Column 1 */}
            <MuiGrid item xs={1} md={4} lg={1}>
                <SelectElement<number>
                    id='filter-base-year'
                    label='Basisjaar'
                    placeholder='Kies...'
                    optionItems={baseYears.items}
                    value={userSettingsContext.baseYear}
                    clearable={true}
                    searchable={false}
                    editorSettings={{
                        restrictions: { required: true },
                        disabled: baseYears.items?.length <= 0,
                        withoutFeedback: true,
                        onChange: (val) => {
                            userSettingsContext.setBaseYear(val);
                        },
                    }}
                />
            </MuiGrid>
            {/* Column 2 */}
            <MuiGrid item xs={1} md={4} lg={2}>
                <SelectElement<Domain.Finance.Structure>
                    id='filter-structure'
                    label='Structuren'
                    displayExpr='name'
                    optionItems={structureOptions}
                    value={structureOptions?.find(str => str.rk === userSettingsContext.structureRK)}
                    clearable={true}
                    searchable={false}
                    editorSettings={{
                        disabled: !userSettingsContext.baseYear,
                        withoutFeedback: true,
                        restrictions: { required: true },
                        validationErrors: [],
                        onChange: (val) => {
                            userSettingsContext.setStructureRK(val?.rk);
                            fetchStructureNodes(val?.rk);
                        }
                    }}
                />
            </MuiGrid>
            {/* Column 3 */}
            <MuiGrid item xs={1} lg={2} xxl={3}>
                <DropDownElement<Domain.Finance.StructureNode>
                    id='filter-structurenode'
                    label='Structuuronderdeel'
                    displayExpr='name'
                    valueExpr='rk'
                    keyExpr="rk"
                    parentIdExpr="parentRK"
                    value={structureNodeOptions.find(strn => strn.rk === userSettingsContext.structureNodeRK)}
                    editorSettings={{
                        disabled: !(userSettingsContext.baseYear && userSettingsContext.structureRK),
                        withoutFeedback: true,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (item) => {
                            userSettingsContext.setStructureNodeRK(item?.rk);
                        },
                    }}
                    optionItems={structureNodeOptions}
                    openOnFieldClick={true}
                    clearable={true}
                    searchable={true}
                />
            </MuiGrid>
            {/* Column 4 */}
            <MuiGrid item xs={1} md={4} lg={2}>
                <SelectElement<string>
                    id='filter-costs-benefits'
                    label='Lasten/Baten'
                    placeholder='Kies...'
                    editorSettings={{
                        withoutFeedback: true,
                        disabled: !(userSettingsContext.baseYear && userSettingsContext.structureRK),
                        onChange: (val: string) =>
                            props.setExtraFilter({
                                transactionKind: [val],
                                exploatatie: props.extraFilter.exploatatie
                            }),
                    }}
                    searchable={false}
                    clearable={true}
                    optionItems={costBenefitFilterItems.map(item => item.value)}
                    value={props.extraFilter?.transactionKind[0]}
                />
            </MuiGrid>
            {/* Column 5 */}
            <MuiGrid item xs={1} md={4} lg={2}>
                <SelectElement<string>
                    id='filter-exploatatie-reserve'
                    label='Exploitatie/Reserve'
                    placeholder='Kies...'
                    editorSettings={{
                        withoutFeedback: true,
                        disabled: !(userSettingsContext.baseYear && userSettingsContext.structureRK),
                        onChange: (val: string) =>
                            props.setExtraFilter({
                                exploatatie: [val],
                                transactionKind: props.extraFilter.transactionKind
                            }),
                    }}
                    searchable={false}
                    clearable={true}
                    optionItems={exploatatieFilterItems.map(item => item.value)}
                    value={props.extraFilter?.exploatatie[0]}
                />
            </MuiGrid>
            {/* Column 6 */}
            <MuiGrid item xs={1} md={4} lg={1}>
                <Button
                    disabled={!userSettingsContext.baseYear || !userSettingsContext.structureRK || props.disabled}
                    btnbase="textbuttons"
                    btntype="medium_noicon"
                    onClick={() => props.onFilter()}>
                    Ophalen
                </Button>
            </MuiGrid>
        </MuiGrid>
    </>
    );
};

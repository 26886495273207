import React from 'react';
import { DropDownProps } from './index.props';
import { DropDownEditor } from './index.editor';

/**
 * Represents a UI component that renders a DropDown element.
 */
const DropDownElement = <V,>(props: DropDownProps<V>) => {
    return <DropDownEditor {...props} />;
};

export { DropDownElement }; 
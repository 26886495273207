/**
 * Enum with all known Publisher element definition unique identifiers (System IDs).
 */
export enum Pub {
    DataStore = '187a517c-0b32-44a1-b3e2-b1f109057bb8',
    Publication = 'cd68f61d-4b0c-4ec8-88aa-875c7825168d',
    Page = 'dd47edf2-acf5-4093-99df-124e17050823',
    PageTemplate = 'c42e0d13-1595-4866-b78c-046f5fadada9',
    SiteDesign = 'b155ff47-34c8-4d45-ad1d-350cc0cc228a',
    PageDesign = '55729eab-3d8d-4d71-bb4a-3f42c3c68e3c',
    DataSource = '1492696a-64e5-4b1a-b5d9-3931b0fb86b9',
    Version = 'ca47afb6-0901-4517-9574-c436966bc818',

    HtmlContainer = 'b460e929-6ced-4227-b8cd-f0eecabc4d8c',
    HeadContainer = 'a7f3eca7-6f94-4528-a4da-e3c5a34c0177',
    BodyContainer = 'ec168571-f85b-4a2f-8975-b9ef95b2f892',
    SectionContainer = '5f36c74e-cd28-4fdf-bc02-eef1cb82dfe8',
    AccordionContainerOld = 'b791aae9-be61-4ea1-885e-c2e4ffc3d2a8',
    AccordionContainer = 'bec55ee0-f6ea-44b0-b77a-aa5f09fd00c6',
    StackContainer = '88fd50a2-9dd7-43fc-99e1-61149b8703eb',
    TabContainer = 'ab9b419a-c0f3-4ba5-8810-ec95d804f670',

    ContainerItem = 'f941d67b-3551-4d5f-b240-6cad963e5af9',
    ContainerItemPlaceHolder = '12b37e05-7013-4e7e-adbd-3d9283f377da',

    HtmlElementControl = 'fc90b95f-08f0-4bfe-9ba0-3c7ceb7321e2',
    NonVisualPageTitleControl = '6b94513a-eca4-4902-9e75-70a3eb58f9b4',
    PageTitleControl = 'bdbb1012-4760-4254-90ad-d607fbe8be9e',
    NonVisualMetadataControl = 'c00e78f6-798b-4b16-8c24-122612484d2c',
    NonVisualLinkControl = '78826d9b-23f1-4187-b5a5-3e094f5f67c3',
    NonVisualFontControl = '16c82b13-7bff-4713-ab5d-aa00522ed59d',
    NonVisualStylesheetControl = '00f49584-cd3e-4ea8-a16d-260ae13e325a',
    NonVisualFaviconControl = '8b768d87-f8a7-4142-ac1f-e51952b2a17a',
    OnDemandJavascriptControl = 'b0a82f99-cd68-4247-b626-56926b7b2d0f',
    OnDemandStylesheetControl = '2bb9c3a3-d842-4b2a-b0af-f48ea89bcc6a',
    CustomJavascriptControl = '6a34b7be-0a2b-462b-8c72-b0cba8468a48',
    LogoControl = '632bda70-7d68-43e1-92aa-553359bf8706',
    DocumentTitleControl = '4a86db33-f46d-4177-8750-1cb874bae92e',
    TitleVersionControl = '9558b559-9c54-4382-b520-1439dd5a5706',
    PrimaryMenuControl = '8c4f1f4d-6cee-4ffb-8268-6cab1da72b0f',
    SecondaryMenuControl = 'a1fdc915-a532-4710-8c3c-4c1c308ab4af',
    QuickNavControl = '2ea852d9-9053-49e4-bc1d-2ad4fffe2b93',
    SearchControl = '0f811330-f502-4c2b-b9ab-5e1ee109f456',
    BreadcrumbsControl = '0b0578d4-c1a7-4006-841b-0413ceae1337',
    FooterControl = 'ed622cbc-a37f-4771-9243-0c980929b692',
    GoToTopButtonControl = '5b2a75b0-3dae-486e-afcc-f49fb9236c6a',
    NonVisualJavascriptControl = 'dd21165e-acc0-4e1e-91b6-cffb06c6aeec',
    MenuControl = '6cd1036d-9dbf-4203-80a5-b8c6f5eacb13',
    TileMenuControl = '259a33e4-38b6-4249-98b5-8ed2cb99f947',
    TitleControl = 'd4b65cdb-eda4-4a04-8c9e-03033852b4cc',
    TextControl = '9f152ba9-0058-4e7c-8a31-e6a1e61fe7eb',
    ImageControl = 'e0e8e7fb-5a4d-4cac-a930-06f78abff32a',
    DataTableControl = '50277bbe-e409-4afd-99cf-51379501b281',
    TabDsControl = 'dbb0eda5-aa2a-4481-924f-594eb32ff1f7',
    PieChartControl = 'b8c6f528-493d-4b7a-b166-2613443f43e1',
    BarChartControl = 'bc9c1b24-5316-43b3-8220-582c9216d2d5',
    ReferenceAttachments = '755a6751-4191-48a8-af5d-441c34ad98fc',
    DataSourceText = '187b201c-8760-4b89-a2c7-38edecff3aca',
    CustomStyleControl = '0599afb1-caab-4a16-936e-154d8f5c1622',
    WorkflowTemplate = '07010704-08fc-4b8e-a07b-805d97422260',
    WorkflowTemplateState = '5dbdf1e3-4b66-4ee2-b269-9ad7feba4e07',
    ElementWorkflowStatus = '91c28046-5313-4468-94ce-f94137455187',
    PerformanceInformationControl = 'fb156075-8ad3-4dc4-b0cf-ec069c293bc8',
    AccordionDataControl = 'c1c7c9d7-e06d-4f03-b2cc-5083fef6fa0d',
    PublishProfile = '47a180da-c47c-4e57-8f0c-799ee7cdc5f0',
    PublishProfileItem = '495ac2a6-ed84-4cb0-921b-6e25e1293406',
    WrapperDivContainer = '143fcff9-db32-45c5-a661-1ec153db6a1d',
    CookiesBannerControl = '5d119a0f-bdbc-484b-af12-cc8ce5329dab',
    AnalyticsControl = '91a29373-c800-4ea4-8c7b-b4454fc1f120',
    SearchFormControl = '2663ebc9-3fca-4ca3-8b66-42095daa826f',
    TreeViewControl = 'f5124bde-92d3-4574-8574-af8005c2bcab',
    AccordionDsControl = '585a2570-c23c-4fc3-8fca-26a70f7ff29d',
    MapControl = '09159bef-488f-4cfc-b1b4-ceb35620221a',
    PivotTableControl = 'd1d57828-2926-4235-ac3e-43a0cb69f90c',
    NonVisualNoteServiceJSControl = 'ef6354ed-fa61-4805-b6c0-f5d6f1c5950f',
    NonVisualNoteServiceMetadataControl = '5399a0b1-0829-4cf9-b80f-570502d42fe2',
    SkipLinkControl = 'b7a5b84f-41d1-448e-ac5e-d7bb21c8e42f',
    LineChartControl = '79e1a5df-ee07-4c37-b1eb-eee11cb176d8',
}

/**
 * Enum with all known Performance element definition unique identifiers (System IDs).
 */
export enum Performance {
    MeasureMoment = '40e6316e-f554-4e95-aafa-6dacbbb87d2c',
    Policy = '228e88f7-4d8a-4d8b-a18c-96a88930a107',
    Goal = '429b1a79-7ef9-41e3-96f6-8fbf82d26a9e',
    Achievement = 'fec8b7fa-c285-4224-a06d-85fbb27edc0b',
    Activity = '5f614cb9-0afc-4867-98d5-3bf21f01e8f3',
    Indicator = '00000000-0000-0000-0000-000000000000',
    PerformanceItemRight = 'a490f4e9-f35a-4108-a284-97c07cad24d8',
}

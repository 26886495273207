import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import { HelpText, Label } from '@liasincontrol/ui-basics';
import { JsonUtils } from '@liasincontrol/core-service';
import { AttachmentProps } from './index.props';
import { Image, Wrapper, PlaceholderWrapper, DownloadItem, DownloadLink, ImagePlaceholderIcon, ImagePlaceholderText } from './index.styled';

/**
 * Represents a UI component that renders an attachment viewer.
 */
export const AttachmentViewer: React.FC<AttachmentProps> = (props) => {
    const [fileData, setFileData] = useState<{ name: string, imageUrl: string, size?: string, blob?: Blob }>();

    useEffect(() => {
        if (props.value) {
            props.onLoadAttachment(props.value).then((response) => {
                // TODO: decide how to detect if the attachment is an image.
                // get attachment name old FE : attachmentValue: field.children.find(NodeValue.IsType(`complex`))?.value,
                setFileData({
                    name: response['name'],
                    imageUrl: response.type.indexOf('image') > -1 ? URL.createObjectURL(response) : undefined,
                    blob: props.allowDownload ? response : undefined,
                    size: JsonUtils.formatBytes(+response['size']),
                });
            });
        }
    }, [props.value, props.allowDownload]);


    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            {props.helpText && props.helpText.text && (
                <HelpText title={props.helpText.title}>{props.helpText.text}</HelpText>
            )}
            {!props.value
                ? <PlaceholderWrapper>
                    <ImagePlaceholderIcon sx={{ fontSize: 48 }} className='mr-050' />
                    <ImagePlaceholderText>Geen bestand</ImagePlaceholderText>
                </PlaceholderWrapper>
                : <Wrapper maxPreviewHeight={props.maxPreviewHeight}>
                    {fileData
                        ? fileData.imageUrl
                            ? <Image title={fileData.name} alt-label={fileData.name} src={fileData.imageUrl} />
                            : <DownloadItem>
                                {fileData.name} | {fileData.size}
                                {props.allowDownload && fileData && <DownloadLink onClick={() => saveAs(fileData.blob, fileData.name)} aria-label={`Downloaden bestand ${fileData?.name}`}>
                                    <DownloadIcon sx={{ fontSize: 24 }} />
                                </DownloadLink>}
                            </DownloadItem>
                        : <></>}
                </Wrapper>}
        </>
    );
};
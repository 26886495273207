
import React, { useEffect, useState, useContext } from 'react';
import _, { Dictionary } from 'lodash';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import TuneIcon from '@mui/icons-material/Tune';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemHierarchyDefinitions } from '@liasincontrol/domain';
import { BasicValidator, DateValidator, FieldsHelper, NumberValidator, TextValidator, IconHelper, ValidationErrorData, JsonUtils } from '@liasincontrol/core-service';
import { AutoFocus, Button, EdgeTab, EdgeTabs, EdgeToolbar, IDataItemProps, Label, palette, UserRightsControl } from '@liasincontrol/ui-basics';
import { TextElement, SelectElement, ToggleElement, MultiLineTextElement, ImageFocusPointElement, AttachmentElement, ColumnBuilderElement, FilterBuilderElement, defaultAllowedAction, IntegerElement, MultiSelectElement } from '@liasincontrol/ui-elements';
import { ActionType, Actions } from '@liasincontrol/userrights-service';
import { LsBaseField, renderModeAvailable } from '@liasincontrol/ui-devextreme';
import { ActionSource } from '@liasincontrol/redux-service';
import { DataSourceEditor } from './Editors/DataSourceEditor';
import { PieChartValueFormatEditor, FormatType } from './Editors/PieChartValueFormatEditor';
import { DataSourceSelection } from './Editors/DataSourceSelection';
import { WhitespaceEditor } from './Editors/WhitespaceEditor';
import { ReferenceAttachmentsEditor } from './Editors/ReferenceAttachmentsEditor';
import { AuditTrail } from './History/AuditTrail';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../../helpers';
import Helper from './index.helper';
import * as Styled from './index.styled';
import { FieldListEditor } from './Editors/FieldListEditor';
import { StatusTrail } from './History/StatusTrail';
import { SetWorkflowStateDialog } from './SetWorkflowStateDialog/index';
import helpers from './index.helper';
import { CommentList } from './Comments/CommentList';
import { PivotTableFieldsEditor } from './Editors/PivotTableFieldsEditor';
import { PivotTableFieldsAdvancedEditor } from './Editors/PivotTableFieldsAdvancedEditor';
import { IconSelectionEditor } from './Editors/IconSelectionEditor';
import { VariableListEditor } from './Editors/VariableListEditor';
import { ArrowBack } from '@mui/icons-material';

export enum HierarchySource { Performance, Studio }

enum Tabs {
    Main = 1,
    AdvancedSettings = 2,
    Users = 3,
    Comments = 4,
    History = 5,
}

type SourcedHierarchyMap = Dictionary<Dictionary<Domain.Performance.HierarchyItem[] | Domain.Studio.HierarchyItem[]>>;
type HierarchyMap = Dictionary<SourcedHierarchyMap>;

type Props = {
    publicationId: string,
    selectedElement: Domain.Publisher.Element,
    selectedPageId?: string,
    isVisible: boolean,
    isDesigner: boolean,
    userControlTask?: Domain.Publisher.WorkflowTask,
    editableElementIds?: Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any, componentHasDiscussion: boolean }>,
    auditEvents?: { content: Domain.Shared.AuditEvent[] },
    isProcessing?: boolean,
    elementTypes?: IDataItemProps<string>[],
    measureMoments?: Domain.Shared.MeasureMoment[],
    availablePublishProfiles?: IDataItemProps<string>[],
    selectedPublishProfiles?: IDataItemProps<string>[],
    sitemap?: Domain.Publisher.Sitemap,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onToggleVisibility: () => void,
    onSingleFieldChanged: (changes: Domain.Publisher.FieldPatch[]) => void,
    onComplexFieldChanged: (change: Domain.Publisher.ComplexFieldPatch) => void,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (attachmentId: string) => void,
    onErrorsInSettings: (value: boolean, elementId: string) => void,
    getParentElement?: (elementId: string) => Domain.Publisher.Element,
    onToggleTaskStatus?: (workflowTask: Domain.Publisher.WorkflowTask, completeTask: boolean) => void,
    loadPerformanceHierarchy?: (measureMomentId?: string) => void,
    onUpdateControlPublishProfiles?: (publishProfiles: IDataItemProps<string | number>[]) => void,
    canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    getDefinition?: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
    hierarchies: HierarchyMap,
    hierarchyDefinitions: Dictionary<Domain.Shared.HierarchyDefinition[]>,
    getAvailableHierarchyTypes?: (items: Domain.Shared.HierarchyLinkDefinition[], source: ActionSource) => void,
    onAuditRefresh?: () => void,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
    onChangeElementState?: (elementId: string, stateId: string, userIds: string[], remark: string) => Promise<boolean>,
    publicationWorkflowStates?: Domain.Publisher.TaskManagerWorkflowState[],
    componentWorkflowTasks?: Domain.Publisher.WorkflowTask[],
    statusAuditEvents?: Domain.Shared.WorkflowAuditEvent[],
    currentUserId?: string,
    templateName?: string,
    templateOperations?: Domain.Publisher.Operation[],
    invalidElements?: Record<string, any>,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    variables?: Domain.Shared.ComplexFieldItem[],
    onVariablesChanged?: (change: Domain.Shared.ComplexField) => void,
    resetSelectedElement?: () => void,
};

type StepDetailsType = {
    disabled: boolean,
    isLastStep: boolean,
    isCurrentStep: boolean,
}
/**
 * Represents a UI component that renders the element settings form.
 */
export const Settings: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [formValidationErrors, setFormValidationErrors] = useState<Dictionary<ValidationErrorData[]>>({});
    const [complexFieldsValidationErrors, setComplexFieldsValidationErrors] = useState<Dictionary<Record<string, Dictionary<ValidationErrorData[]>>>>({});
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Main);
    const [currentStateName, setCurrentStateName] = useState<string>();
    const [changeWorkflowState, setChangeWorkflowState] = useState<boolean>(false);
    const [previousWorkflowStateWithTasks, setPreviousWorkflowStateWithTasks] = useState<{ state: Domain.Publisher.TaskManagerWorkflowState, stateUsers: Domain.Publisher.WorkflowTask[] }>({ state: null, stateUsers: [] });
    const elementDefinition = props.getElementDefinition(props.selectedElement.elementDefinitionSystemId, props.selectedElement.elementDefinitionId);

    useEffect(() => {
        if (!props.invalidElements) {
            return;
        }

        const invalidElementsIds = Object.keys(props.invalidElements);
        if (!invalidElementsIds.length) {
            return;
        }

        if (invalidElementsIds.findIndex((id) => id === props.selectedElement.elementId) < 0) {
            setFormValidationErrors({});
        }

    }, [props.selectedElement, props.invalidElements])

    useEffect(() => {
        setSelectedTab(Tabs.Main);
    }, [props.selectedElement.elementId]);

    useEffect(() => {
        if (!props.publicationWorkflowStates) return;

        if (props.userControlTask) {
            setCurrentStateName(props.userControlTask.currentStateName);
            const currentState = props.publicationWorkflowStates
                .find(state => state.id === props.userControlTask.taskStateId);

            if (currentState.category === Domain.Shared.WorkflowCategoryType.Done) {
                setPreviousWorkflowStateWithTasks({ state: null, stateUsers: [] });
                return;
            }

            const previousStates = props.publicationWorkflowStates
                .filter(state => state.order < currentState.order)
                .sort((a, b) => b.order - a.order);

            for (const previousState of previousStates) {
                if (previousState.category === Domain.Shared.WorkflowCategoryType.New) {
                    setPreviousWorkflowStateWithTasks({ state: null, stateUsers: [] });
                    return;
                }

                const previousStateTasks = props.componentWorkflowTasks.filter(task => task.taskStateId === previousState.id);
                if (previousStateTasks) {
                    setPreviousWorkflowStateWithTasks({ state: previousState, stateUsers: previousStateTasks });
                    return;
                }
            }
            return;
        }

        if (props.componentWorkflowTasks && props.componentWorkflowTasks.length) {
            setCurrentStateName(props.componentWorkflowTasks[0].currentStateName);
            return;
        }

        setCurrentStateName(props.publicationWorkflowStates.find(state => state.category === Domain.Shared.WorkflowCategoryType.New)?.name);
    }, [props.componentWorkflowTasks, props.userControlTask, props.publicationWorkflowStates]);

    if (!props.measureMoments) {
        return null;
    }

    if (!props.selectedElement) {
        return null;
    }

    const getExtraComplexFields = (elementDefinition: Domain.Shared.ElementDefinition): Domain.Shared.FieldDefinition[] => {
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.ReferenceAttachments) {
            const complexFieldDefinition = elementDefinition.complexFields
                .find((complexField) => complexField.systemId === Domain.SystemFieldDefinitions.Pub.AttachmentsComplex);
            return complexFieldDefinition.fields.filter((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.FileSource);
        }

        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.TileMenuControl) {
            const complexFieldDefinition = elementDefinition.complexFields.find((complexField) => complexField.systemId === Domain.SystemFieldDefinitions.Pub.TilesComplex);
            return complexFieldDefinition.fields.filter((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.TilePage);
        }

        return [];
    };

    const getExtraDesignerLayoutFieldDefinitions = (): { fields: Domain.Shared.FieldDefinition[], ownerElement: Record<string, Domain.Publisher.Element> } => {
        const fields: Domain.Shared.FieldDefinition[] = [];
        let owners = {};

        if (props.isDesigner && props.getParentElement) {
            let parent: Domain.Publisher.Element = null;
            let parentDefinition: Domain.Shared.ElementDefinition = null;

            do {
                parent = props.getParentElement(props.selectedElement.elementId);
                parentDefinition = parent ? props.getElementDefinition(parent.elementDefinitionSystemId, parent.elementDefinitionId) : null;

                if (parent && parent?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.ContainerItemPlaceHolder) {
                    //try again if we reached a placeholder container, until we either find a real container or reach the end.
                    continue;
                }
                break;
            } while (true);

            if (parent && parent?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.ContainerItem) {
                // Here we are adding certain fields present on the parent ContainerItem to the selected element.
                // Create a dummy field to represent the group of 4 whitespace fields.
                const virtualField = Helper.getVirtualWhitespaceFieldDefinition();
                fields.push(virtualField);
                owners[virtualField.id] = parent;

                switch (props.selectedElement.elementDefinitionSystemId) {
                    case Domain.SystemElementDefinitions.Pub.StackContainer:
                    case Domain.SystemElementDefinitions.Pub.TabContainer:
                    case Domain.SystemElementDefinitions.Pub.AccordionContainer:
                    case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
                        const extraContainerFields = parentDefinition.fields.filter((field) => ([SystemFieldDefinitions.Pub.ShowBackground, SystemFieldDefinitions.Pub.ShowShadow] as string[]).includes(field.systemId));
                        fields.push(...extraContainerFields);
                        owners = extraContainerFields.reduce((acc, fieldDef) => ({ ...acc, [fieldDef.id]: parent }), owners);
                        break;
                    case Domain.SystemElementDefinitions.Pub.ImageControl:
                    case Domain.SystemElementDefinitions.Pub.TitleControl:
                    case Domain.SystemElementDefinitions.Pub.TextControl:
                    case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
                    case Domain.SystemElementDefinitions.Pub.DataTableControl:
                    case Domain.SystemElementDefinitions.Pub.QuickNavControl:
                    case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
                    case Domain.SystemElementDefinitions.Pub.DataSourceText:
                    case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
                    case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
                    case Domain.SystemElementDefinitions.Pub.TreeViewControl:
                    case Domain.SystemElementDefinitions.Pub.PivotTableControl:
                    case Domain.SystemElementDefinitions.Pub.TabDsControl:
                        const printAsLandscapeField = parentDefinition.fields.find((field) => field.systemId === SystemFieldDefinitions.Pub.PrintAsLandscape);
                        fields.push(printAsLandscapeField);
                        owners[printAsLandscapeField.id] = parent;
                        break;
                    case Domain.SystemElementDefinitions.Pub.MenuControl:
                    case Domain.SystemElementDefinitions.Pub.TileMenuControl:
                    case Domain.SystemElementDefinitions.Pub.PieChartControl:
                    case Domain.SystemElementDefinitions.Pub.BarChartControl:
                    case Domain.SystemElementDefinitions.Pub.LineChartControl:
                    case Domain.SystemElementDefinitions.Pub.MapControl:
                        break;
                }
                return {
                    fields: fields,
                    ownerElement: owners
                };
            }
        }

        return {
            fields: [],
            ownerElement: {}
        };
    };

    const getClonedFieldDefinitions = (elementDefinition: Domain.Shared.ElementDefinition): Domain.Shared.FieldDefinition[] => {
        const fields = [];
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.PivotTableControl) {

            const field = elementDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.PivotGridFields);
            fields.push({
                ...field,
                id: SystemFieldDefinitions.Pub.VirtualPivotGridFields,
                systemId: SystemFieldDefinitions.Pub.VirtualPivotGridFields
            });
        }

        return fields;
    };

    const getExtraFieldDefinitions = (elementDefinition: Domain.Shared.ElementDefinition): Domain.Shared.FieldDefinition[] => {
        const fields = [];
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.PageTemplate) {
            const virtualField = Helper.getVirtualVariablesFieldDefinition();
            fields.push(virtualField);
        }

        return fields;
    };

    const filterOutMainFields = (fieldDefinition: Domain.Shared.FieldDefinition, elementDefinition: Domain.Shared.ElementDefinition): boolean => {
        const unwantedFields = [
            SystemFieldDefinitions.Pub.ShowWhitespaceTop,
            SystemFieldDefinitions.Pub.ShowWhitespaceBottom,
            SystemFieldDefinitions.Pub.ShowWhitespaceLeft,
            SystemFieldDefinitions.Pub.ShowWhitespaceRight,
        ] as string[];
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.ContainerItem) {
            unwantedFields.push(SystemFieldDefinitions.Pub.ShowShadow);
            unwantedFields.push(SystemFieldDefinitions.Pub.ShowBackground);
            unwantedFields.push(SystemFieldDefinitions.Pub.PrintAsLandscape);
        }
        if (unwantedFields.includes(fieldDefinition.systemId)) {
            return false;
        }

        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.PageTemplate) {
            // In the admin template editor, it's possible to select the template itself. 
            // Only allow editing the name and variables field in this case.
            return fieldDefinition.systemId === SystemFieldDefinitions.Pub.Name || fieldDefinition.systemId === SystemFieldDefinitions.Pub.VirtualVariablesField;
        }

        return true;
    };

    const { fields: extraDesignerFields, ownerElement: extraFieldsOwner } = getExtraDesignerLayoutFieldDefinitions();

    const complexFields = getExtraComplexFields(elementDefinition);
    const clonedFields = getClonedFieldDefinitions(elementDefinition);
    const extraFields = getExtraFieldDefinitions(elementDefinition);

    const advancedFields = [
        SystemFieldDefinitions.Pub.TableColumnSettings,
        SystemFieldDefinitions.Pub.IsStudioControl,
        SystemFieldDefinitions.Pub.MeasureMomentId,
        SystemFieldDefinitions.Pub.HierarchyDefinitionId,
        SystemFieldDefinitions.Pub.ParentEntityTypeId,
        SystemFieldDefinitions.Pub.ParentEntityId,
        SystemFieldDefinitions.Pub.EntityTypeId,
        SystemFieldDefinitions.Pub.EntityId,
        SystemFieldDefinitions.Pub.FieldId,
        SystemFieldDefinitions.Pub.HeaderFieldId,
        SystemFieldDefinitions.Pub.FieldsListJson,
        SystemFieldDefinitions.Pub.VirtualPivotGridFields
    ] as string[];

    const fieldIdsMainTabList: string[] = elementDefinition.fields
        .concat(extraDesignerFields)
        .concat(complexFields)
        .concat(extraFields)
        .filter(fieldDefinition => filterOutMainFields(fieldDefinition, elementDefinition))
        // Filter out the field-definitions which should not be on the main-tab.
        .filter(fieldDefinition => !advancedFields.includes(fieldDefinition.systemId))
        .sort(Helper.sortFieldDefinitionsList)
        .map(fieldDefinition => fieldDefinition.id);

    // Get a list of fieldId's that should be on the advanced-tab.
    const fieldIdAdvancedSettingsTabList: string[] = elementDefinition.fields
        .concat(clonedFields)
        .filter(fieldDefinition => advancedFields.includes(fieldDefinition.systemId))
        .sort(Helper.sortFieldDefinitionsList)
        .map(fieldDefinition => fieldDefinition.id);

    const hasDataSourceValue = () => {
        if (fieldEditorElementsAdvancedSettingsTab && conditionalAdvancedSettingsElementIds.includes(props.selectedElement.elementDefinitionSystemId)) {
            const dataSourceFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.DataSource);
            if (!dataSourceFieldDefinition) {
                return false;
            }
            return !!props.selectedElement.fields[dataSourceFieldDefinition.id];
        } else {
            return false;
        }
    };

    const renderEditorRenderer = (fieldIdTabList: string[], extraFieldsOwner?: Record<string, Domain.Publisher.Element>) => {
        return fieldIdTabList
            .map((fieldId, index) => {
                const element = (extraFieldsOwner && extraFieldsOwner[fieldId]) || props.selectedElement;

                let isStudioComponent = false;

                if (props.selectedElement.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.AccordionDataControl) {
                    const isStudioControlFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
                    isStudioComponent = isStudioControlFieldValue && isStudioControlFieldValue.toLowerCase() === 'true';
                }

                return (
                    <EditorRenderer
                        publicationId={props.publicationId}
                        key={index}
                        fieldId={fieldId}
                        selectedElement={element}
                        validationErrors={formValidationErrors[fieldId]}
                        complexFieldsValidationErrors={complexFieldsValidationErrors[fieldId]}
                        isDesigner={props.isDesigner}
                        disabled={props.editableElementIds && props.editableElementIds[element.elementId] && !props.editableElementIds[element.elementId].editable}
                        getElementDefinition={props.getElementDefinition}
                        onUploadAttachment={props.onUploadAttachment}
                        onLoadAttachment={props.onLoadAttachment}
                        onRemoveAttachment={props.onRemoveAttachment}
                        templateOperations={props.templateOperations}
                        onComplexFieldChanged={(change: Domain.Publisher.ComplexFieldPatch) => {
                            props.onComplexFieldChanged(change);
                            const changedElementDefinition = extraFieldsOwner && extraFieldsOwner[fieldId]
                                ? props.getElementDefinition(extraFieldsOwner[fieldId].elementDefinitionSystemId, extraFieldsOwner[fieldId].elementDefinitionId)
                                : elementDefinition;
                            const changedFieldsIds = Object.keys(change.complexField.fields);
                            const fieldDefinitions = changedElementDefinition.complexFields.flatMap(x => x.fields);
                            let allErrors: Record<string, any[]> = {};
                            const complexFieldsValidationErrorsClone = _.cloneDeep(complexFieldsValidationErrors);
                            changedFieldsIds.forEach(changedFieldId => {
                                const fieldDefinition = fieldDefinitions.find(f => f.id === changedFieldId);
                                const value = change.complexField.fields[fieldDefinition.id];
                                let errors = [];
                                switch (fieldDefinition.dataType) {
                                    case 'String':
                                        errors = new TextValidator({
                                            required: fieldDefinition.required,
                                            stringMaxLength: fieldDefinition.stringMaxLength,
                                            stringType: fieldDefinition.stringType
                                        }).validate(value);
                                        break;
                                    default:
                                        errors = new BasicValidator({ required: fieldDefinition.required }).validate(value);
                                        break;
                                }
                                allErrors = { ...allErrors, [changedFieldId]: errors };
                            });

                            complexFieldsValidationErrorsClone[fieldId] = {
                                [change.rowIndex]: allErrors
                            };
                            setComplexFieldsValidationErrors(complexFieldsValidationErrorsClone);
                        }}
                        onSingleFieldChanged={(changes: Domain.Publisher.FieldPatch[]) => {
                            props.onSingleFieldChanged(changes);
                            const changedElementDefinition = extraFieldsOwner && extraFieldsOwner[fieldId]
                                ? props.getElementDefinition(extraFieldsOwner[fieldId].elementDefinitionSystemId, extraFieldsOwner[fieldId].elementDefinitionId)
                                : elementDefinition;

                            const formValidationErrorsClone = _.cloneDeep(formValidationErrors);

                            changes.forEach((change) => {
                                const fieldDefinition = changedElementDefinition.fields.find(field => field.id === change.fieldId);
                                let errors = [];
                                switch (fieldDefinition.dataType) {
                                    case 'String':
                                        errors = new TextValidator({
                                            required: fieldDefinition.required,
                                            stringMaxLength: fieldDefinition.stringMaxLength,
                                            stringType: fieldDefinition.stringType
                                        }).validate(change.value);
                                        break;
                                    case 'DateTimeOffset':
                                        errors = new DateValidator({
                                            required: fieldDefinition.required,
                                            dateMinValue: fieldDefinition.dateMinValue,
                                            dateMaxValue: fieldDefinition.dateMaxValue
                                        }).validate(new Date(change.value));
                                        break;
                                    case 'Integer':
                                        errors =
                                            new NumberValidator({ required: fieldDefinition.required, minValue: fieldDefinition.integerMinValue, maxValue: fieldDefinition.integerMaxValue })
                                                .validate(+change.value);
                                        break;
                                    case 'Decimal':
                                        errors =
                                            new NumberValidator({ required: fieldDefinition.required, minValue: fieldDefinition.decimalMinValue, maxValue: fieldDefinition.decimalMaxValue })
                                                .validate(+change.value);
                                        break;
                                    default:
                                        errors = new BasicValidator({ required: fieldDefinition.required }).validate(change.value);
                                        break;
                                }
                                formValidationErrorsClone[change.fieldId] = errors;
                            });

                            //if treeviewControl is owner and changed fields contains datasource
                            if (changedElementDefinition.systemId === SystemElementDefinitions.Pub.TreeViewControl) {
                                const dataSourceFieldDefinition = changedElementDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSource);
                                const columnSettingsFieldDefinition = changedElementDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.TableColumnSettings);
                                const dataSourceChange = changes.find(change => change.fieldId === dataSourceFieldDefinition.id);
                                if (dataSourceChange?.value) {
                                    const columnChange = changes.find(change => change.fieldId === columnSettingsFieldDefinition.id);
                                    if (columnChange?.value) {
                                        const columnData = JSON.parse(columnChange.value || '{}');
                                        const validColumns = ['ElementId', 'ParentElementId'].every(required => columnData.some(col => col.dataField === required));
                                        if (!validColumns) formValidationErrorsClone[dataSourceFieldDefinition.id] = [{ error: 'Verplichte Velden \'ElementId\' en/of \'ParentElementId\' zijn niet aanwezig in de databron.' }];
                                    }
                                }
                            }
                            // old useEffect
                            const hasErrors = Object.values(formValidationErrorsClone).some(errorArray => errorArray.length > 0);
                            props.onErrorsInSettings(hasErrors, props.selectedElement.elementId);

                            setFormValidationErrors(formValidationErrorsClone);
                        }}
                        measureMoments={props.measureMoments}
                        elementTypes={props.elementTypes}
                        hierarchies={isStudioComponent ? props.hierarchies[ActionSource.Studio] : props.hierarchies[ActionSource.Performance]}
                        hierarchyDefinitions={props.hierarchyDefinitions}
                        hierarchySource={isStudioComponent ? HierarchySource.Studio : HierarchySource.Performance}
                        loadPerformanceHierarchy={props.loadPerformanceHierarchy}
                        getDefinition={props.getDefinition}
                        getAvailableHierarchyTypes={props.getAvailableHierarchyTypes}
                        loadStudioHierarchy={props.loadStudioHierarchy}
                        sitemap={props.sitemap}
                        selectedPageId={props.selectedPageId}
                        templateName={props.templateName}
                        icons={props.icons}
                        onVariablesChanged={props.onVariablesChanged}
                        variables={props.variables}
                    />
                );
            });
    };

    const fieldEditorElementsMainTab = renderEditorRenderer(fieldIdsMainTabList, extraFieldsOwner);

    const fieldEditorElementsAdvancedSettingsTab = fieldIdAdvancedSettingsTabList.length > 0 ? renderEditorRenderer(fieldIdAdvancedSettingsTabList) : null;
    const conditionalAdvancedSettingsElementIds = [
        SystemElementDefinitions.Pub.DataTableControl,
        SystemElementDefinitions.Pub.PieChartControl,
        SystemElementDefinitions.Pub.TreeViewControl,
        SystemElementDefinitions.Pub.AccordionDsControl,
        SystemElementDefinitions.Pub.PivotTableControl
    ] as string[];

    const visibleTabsCount = 1 + (fieldEditorElementsAdvancedSettingsTab ? 1 : 0) + (props.publicationWorkflowStates ? 1 : 0);
    const elementSettings = getBaseElementSettings(props.selectedElement, elementDefinition);

    const typeInputCustomSingleValue = (props) => (
        <Styled.SingleValueWrapper>
            {IconHelper.getWorkFlowStatusIcon(props.data.label, 20)}
            <Styled.SingleValueLabel>
                {props.data.label}
            </Styled.SingleValueLabel>
        </Styled.SingleValueWrapper>
    );

    const getElementAuditTrail = (auditEvents: { content: Domain.Shared.AuditEvent[] }) => {
        const filteredContent = auditEvents?.content?.filter(c => c.elementId === props.selectedElement.elementId);
        return { content: filteredContent };
    };

    const getCaptionText = () => {
        return `${elementDefinition.label ? elementDefinition.label : elementDefinition.name}`;
    };

    const formatElementName = (): string => {
        if (elementDefinition.systemId === SystemElementDefinitions.Pub.ContainerItem) {
            const parentElement = props.getParentElement(props.selectedElement.elementId);
            if (parentElement?.elementDefinitionSystemId === SystemElementDefinitions.Pub.TabContainer) {
                return 'Tab';
            } else if (parentElement?.elementDefinitionSystemId === SystemElementDefinitions.Pub.AccordionContainer) {
                return 'Accordeon';
            } else {
                return getCaptionText();
            }
        } else {
            return getCaptionText();
        }
    };

    const toggleControlTaskStatus = (completeTask: boolean): void => {
        props.onToggleTaskStatus(props.userControlTask, completeTask);
    };

    const hasPublisherSetPublishProfileItemsAccess = props.canPerformAction && props.canPerformAction(Actions.COMPLEX_PublisherSetPublishProfileItems, ActionType.Update);
    const tabCount = visibleTabsCount + (!props.isDesigner ? 1 : 0);

    const tasksContributorsForState = (stateId: string) => {
        return props.componentWorkflowTasks ? (props.componentWorkflowTasks?.filter(cc => cc.taskStateId === stateId)?.map((cc) => ({
            enabled: true,
            id: cc.userId,
            name: cc.userName,
            email: cc.userId,
            frontIcon: { icon: cc.taskIsCompleted ? <TaskAltIcon sx={{ color: palette.green }} /> : <CircleOutlinedIcon sx={{ color: palette.grey2 }} />, color: cc.taskIsCompleted ? palette.green : palette.grey2 },
        } as Domain.Shared.RightUser)) || []) : [];
    };

    const getStateDetails = (stateId: string): StepDetailsType => {
        if (props.componentWorkflowTasks.length <= 0)
            return {
                isCurrentStep: false,
                disabled: true,
                isLastStep: false,
            };

        const currentState = props.publicationWorkflowStates.find(state => state.id === props.componentWorkflowTasks[0].currentStateId);
        const state = props.publicationWorkflowStates.find(state => state.id === stateId);
        return {
            isCurrentStep: currentState?.id === stateId,
            disabled: state?.order > currentState?.order,
            isLastStep: currentState?.category === Domain.Shared.WorkflowCategoryType.Done,
        };
    };

    const diplayChangeStateButton = !!props.userControlTask;

    const controlIsLocked = !props.editableElementIds || !props.editableElementIds[props.selectedElement.elementId] || props.editableElementIds[props.selectedElement.elementId].isLockedByOther;
    const controlHasDiscussion = !props.editableElementIds || !props.editableElementIds[props.selectedElement.elementId] || props.editableElementIds[props.selectedElement.elementId].componentHasDiscussion;

    return (
        <>
            <EdgeToolbar edge="left" open={props.isVisible} inModal onToggle={() => props.onToggleVisibility()}>
                <Styled.LeftSideBarPanel hasPadding={visibleTabsCount === 1} className="slide-panel-section">
                    {visibleTabsCount > 1 ? (
                        <EdgeTabs tabCount={tabCount}>
                            <EdgeTab title={getCaptionText()} icon={<EditIcon />} active={selectedTab === Tabs.Main} onClick={() => (setSelectedTab(Tabs.Main))} tabCount={tabCount}>
                                <Styled.EdgeToolbarContent>
                                    {!_.isEmpty(props.editableElementIds) &&
                                        props.userControlTask &&
                                        props.onToggleTaskStatus &&
                                        (
                                            <>
                                                <Label text="Taak" />
                                                <Styled.RowWrapper>
                                                    <Styled.EdgeToolbarButton btnbase="ghostbuttons"
                                                        btntype="medium_noicon"
                                                        disabled={props.userControlTask.taskIsCompleted || props.isProcessing || controlIsLocked}
                                                        onClick={() => toggleControlTaskStatus(true)}>
                                                        {props.userControlTask.taskIsCompleted ? 'Taak afgerond' : 'Taak afronden'}
                                                    </Styled.EdgeToolbarButton>
                                                    {props.userControlTask.taskIsCompleted &&
                                                        <Styled.EdgeToolbarButton
                                                            btnbase="ghostbuttons"
                                                            btntype="medium_noicon"
                                                            onClick={() => toggleControlTaskStatus(false)}
                                                            disabled={props.isProcessing || controlIsLocked}>
                                                            Taak heropenen
                                                        </Styled.EdgeToolbarButton>
                                                    }
                                                </Styled.RowWrapper>
                                            </>)
                                    }
                                    {props.publicationWorkflowStates && (
                                        <>
                                            <Label text="Status" />
                                            <Styled.RowWrapper>
                                                <Styled.StaticFieldValue>
                                                    {typeInputCustomSingleValue({ data: { label: currentStateName } })}
                                                </Styled.StaticFieldValue>
                                            </Styled.RowWrapper>
                                            {diplayChangeStateButton &&
                                                <Styled.RowWrapper>
                                                    <Styled.EdgeToolbarButton
                                                        btnbase="ghostbuttons"
                                                        btntype="small_noicon"
                                                        disabled={props.isProcessing || !previousWorkflowStateWithTasks.state || controlIsLocked}
                                                        onClick={() => setChangeWorkflowState(true)}>
                                                        STATUS WIJZIGEN
                                                    </Styled.EdgeToolbarButton>
                                                </Styled.RowWrapper>}
                                        </>
                                    )}
                                    <AutoFocus>
                                        {props.isDesigner &&
                                            <Styled.ToolbarTitle>
                                                {props.selectedElement.elementDefinitionSystemId !== SystemElementDefinitions.Pub.PageTemplate &&
                                                    <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={props.resetSelectedElement} />
                                                }
                                                {getCaptionText()}
                                            </Styled.ToolbarTitle>}
                                        {fieldEditorElementsMainTab}
                                    </AutoFocus>
                                    {hasPublisherSetPublishProfileItemsAccess && !props.isDesigner &&
                                        <MultiSelectElement
                                            id="publish-profiles"
                                            label="Publicatie profiel"
                                            editorSettings={{
                                                disabled: !props.availablePublishProfiles,
                                                validationErrors: undefined,
                                                restrictions: undefined,
                                                onChange: (selectedItems) => props.onUpdateControlPublishProfiles(selectedItems),
                                            }}
                                            searchable={false}
                                            clearable={true}
                                            optionItems={props.availablePublishProfiles ? props.availablePublishProfiles : []}
                                            value={props.selectedPublishProfiles ? props.selectedPublishProfiles : []}
                                        />
                                    }
                                </Styled.EdgeToolbarContent>
                            </EdgeTab>
                            {fieldEditorElementsAdvancedSettingsTab &&
                                <EdgeTab title="Geavanceerde instellingen"
                                    icon={<TuneIcon />}
                                    active={selectedTab === Tabs.AdvancedSettings}
                                    onClick={() => setSelectedTab(Tabs.AdvancedSettings)}
                                    disabled={!hasDataSourceValue}
                                    tabCount={tabCount}>
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Geavanceerde instellingen</Styled.ToolbarTitle>
                                            {fieldEditorElementsAdvancedSettingsTab}
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                            {props.componentWorkflowTasks &&
                                <EdgeTab title="Voortgang" icon={<PersonIcon />} active={selectedTab === Tabs.Users} onClick={() => (setSelectedTab(Tabs.Users))} tabCount={tabCount}>
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Voortgang</Styled.ToolbarTitle>
                                            {elementSettings.allowPatchContent && (<>
                                                {props.publicationWorkflowStates?.map((state) => {
                                                    const details = getStateDetails(state.id);
                                                    return <UserRightsControl
                                                        key={state.id}
                                                        users={tasksContributorsForState(state.id)}
                                                        disabled={details.disabled}
                                                        actionsEnabled={false}
                                                        showListHeading={false}
                                                        workflowTemplateStateName={state.name}
                                                        isCurrentStep={details.isCurrentStep}
                                                        isLastStep={details.isLastStep}
                                                    />;
                                                })}
                                            </>)
                                            }
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                            {!props.isDesigner &&
                                <EdgeTab title="Discussie" icon={controlHasDiscussion ? <MarkUnreadChatAltIcon /> : <ChatBubbleIcon />} active={selectedTab === Tabs.Comments} onClick={() => (setSelectedTab(Tabs.Comments))} tabCount={tabCount}>
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Discussie</Styled.ToolbarTitle>
                                            <CommentList
                                                publicationId={props.publicationId}
                                                pageId={props.selectedPageId}
                                                elementId={props.selectedElement.elementId}
                                                currentUserId={props.currentUserId}
                                                componentWorkflowTasks={props.componentWorkflowTasks}
                                            />
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                            {!props.isDesigner &&
                                <EdgeTab
                                    title="Historie"
                                    icon={<HistoryIcon />}
                                    active={selectedTab === Tabs.History}
                                    onClick={() => {
                                        setSelectedTab(Tabs.History);
                                        props.onAuditRefresh();
                                    }}
                                    tabCount={tabCount}
                                >
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Historie</Styled.ToolbarTitle>
                                            <AuditTrail
                                                elementDefinition={elementDefinition}
                                                auditTrail={getElementAuditTrail(props.auditEvents)}
                                            />
                                            <StatusTrail items={props.statusAuditEvents}></StatusTrail>
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                        </EdgeTabs>)
                        : (
                            <Styled.TabsPlaceholder>
                                <AutoFocus>
                                    {props.isDesigner && <Styled.ToolbarTitle>
                                        {props.selectedElement.elementDefinitionSystemId !== SystemElementDefinitions.Pub.PageTemplate &&
                                            <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={props.resetSelectedElement} />
                                        }
                                        {formatElementName()}</Styled.ToolbarTitle>}
                                    {fieldEditorElementsMainTab}
                                </AutoFocus>
                            </Styled.TabsPlaceholder>)
                    }
                </Styled.LeftSideBarPanel>
            </EdgeToolbar>
            {changeWorkflowState && <SetWorkflowStateDialog
                newWorkflowStateName={previousWorkflowStateWithTasks.state?.name}
                saveButtonDisabled={props.isProcessing}
                onCloseDialog={() => setChangeWorkflowState(false)}
                previousWorkflowStateUsers={previousWorkflowStateWithTasks.stateUsers}
                onChangeWorkflowState={(userIds: string[], remark: string) => {
                    props.onChangeElementState(props.selectedElement.elementId, previousWorkflowStateWithTasks.state?.id, userIds, remark).then(() => {
                        setChangeWorkflowState(false);
                    });
                }}></SetWorkflowStateDialog>}
        </>
    );
}

export type EditorRendererProps = {
    publicationId: string,
    selectedElement: Domain.Publisher.Element,
    disabled: boolean,
    isDesigner: boolean,
    fieldId: string,
    validationErrors: ValidationErrorData[],
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onSingleFieldChanged: (changes: Domain.Publisher.FieldPatch[]) => void,
    onComplexFieldChanged: (change: Domain.Publisher.ComplexFieldPatch) => void,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (attachmentId: string) => void,
    measureMoments?: Domain.Shared.MeasureMoment[],
    elementTypes?: IDataItemProps<string>[],
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    getDefinition: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
    hierarchies: SourcedHierarchyMap,
    hierarchyDefinitions: Dictionary<Domain.Shared.HierarchyDefinition[]>,
    getAvailableHierarchyTypes?: (items: Domain.Shared.HierarchyLinkDefinition[], source: ActionSource) => void,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
    hierarchySource: HierarchySource,
    sitemap: Domain.Publisher.Sitemap,
    selectedPageId?: string,
    variables?: Domain.Shared.ComplexFieldItem[],
    complexFieldsValidationErrors?: Record<string, Dictionary<ValidationErrorData[]>>,
    templateName?: string,
    templateOperations?: Domain.Publisher.Operation[],
    icons?: Record<string, Domain.Shared.SvgIcon>,
    onVariablesChanged?: (change: Domain.Shared.ComplexField) => void,
}

/**
 * Represents a UI component that renders an editor.
 */
const EditorRenderer: React.FC<EditorRendererProps> = (props) => {
    const elementDefinition = props.getElementDefinition(props.selectedElement.elementDefinitionSystemId, props.selectedElement.elementDefinitionId);
    const fieldDefinition = getFieldDefinition(elementDefinition, props.fieldId);

    const templateElement = props.templateOperations?.find(to => to.element.elementId === props.selectedElement.elementId);
    const templateFd = elementDefinition.fields.find(fd => fd.systemId === fieldDefinition.systemId);
    const templateDsFieldValue = templateElement?.element.fields[templateFd?.id];
    const fieldValue = props.selectedElement.fields[props.fieldId];

    const complexFieldValues = props.selectedElement.complexFields;

    const [dataSourceFields, setDataSourceFields] = useState<LsBaseField[]>([]);
    const [selectedHierarchyDefinition, setSelectedHierarchyDefinition] = useState<Domain.Shared.HierarchyDefinition>();
    const pubContext = useContext(PublicationContext);
    const [dataSourceElement, setDataSourceElement] = useState<Domain.Publisher.DataSourceElement>();

    useEffect(() => {
        const asyncWrapper = async () => {
            const dataSourceFieldDefinition = elementDefinition.fields.find(
                (item) => item.systemId === SystemFieldDefinitions.Pub.DataSource
            );

            if (!dataSourceFieldDefinition) {
                setDataSourceElement(null);
                return;
            }

            const writerDataSourceId = props.selectedElement.fields[dataSourceFieldDefinition.id];
            const templateDataSourceId = templateElement?.element.fields[dataSourceFieldDefinition.id];
            const usedDateSourceId = writerDataSourceId || templateDataSourceId;

            if (usedDateSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(usedDateSourceId);
                setDataSourceElement(dsElement);
            } else {
                setDataSourceElement(null);
            }
        };

        asyncWrapper();
    }, [props.selectedElement, elementDefinition, templateElement]);


    useEffect(() => {
        if (!dataSourceElement || fieldDefinition.systemId !== SystemFieldDefinitions.Pub.Filter) {
            return;
        }

        if (dataSourceElement?.schemaFileId) {
            props.onLoadAttachment(dataSourceElement.schemaFileId)
                .then((response) => response.text())
                .then((jsonText) => {
                    const fields = DataSourceControlsUtils.mapsDataSourceBaseFields(JSON.parse(jsonText));
                    setDataSourceFields(fields);
                });
        }

    }, [props.onLoadAttachment, dataSourceElement, fieldDefinition.systemId]);

    useEffect(() => {
        if (!props.hierarchyDefinitions) {
            return;
        }

        if (props.hierarchySource === HierarchySource.Performance) {
            setSelectedHierarchyDefinition(props.hierarchyDefinitions[ActionSource.Performance][0]);
        } else {
            const hierarchyDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.HierarchyDefinitionId);
            const hierarchyDefinitionFieldValue = hierarchyDefinition ? props.selectedElement.fields[hierarchyDefinition.id] : null;
            if (hierarchyDefinition && !!hierarchyDefinitionFieldValue) setSelectedHierarchyDefinition(props.hierarchyDefinitions[ActionSource.Studio].find(hd => hd.hierarchyDefinitionId === hierarchyDefinitionFieldValue));
        }
    }, [props.hierarchyDefinitions]);

    useEffect(() => {
        if (!controlsRequiredPerformanceHierarchy.includes(props.selectedElement.elementDefinitionSystemId) || !props.measureMoments) {
            return;
        }

        const measureMomentFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
        if (props.hierarchySource === HierarchySource.Performance) {
            if (!!measureMomentFieldValue && props.loadPerformanceHierarchy) {
                props.loadPerformanceHierarchy(measureMomentFieldValue);
            }
        } else {
            const hierarchyDefinitionFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId);
            const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === measureMomentFieldValue && h.definitionId === hierarchyDefinitionFieldValue);
            if (!!hierarchy && props.loadStudioHierarchy) {
                props.loadStudioHierarchy(hierarchy.id, hierarchyDefinitionFieldValue, measureMomentFieldValue);
            } else {
                console.error("Cannot load studio hierarchy due to missing data");
            }
        }

    }, [props.measureMoments]);

    useEffect(() => {
        if (!selectedHierarchyDefinition) {
            return;
        }
        props.getAvailableHierarchyTypes(selectedHierarchyDefinition.items, props.hierarchySource === HierarchySource.Studio ? ActionSource.Studio : ActionSource.Performance);
    }, [selectedHierarchyDefinition]);

    // Checks if an imageSource field has a value.
    const hasImageSourceFieldValue = (): boolean => {
        const imageFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.ImageSource);
        return imageFieldDefinition && !!props.selectedElement.fields[imageFieldDefinition.id];
    };

    // Checks if element patching is allowed on page content level.
    const allowPatchContent = (): boolean => {
        const allowPatchContentFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.AllowPatchContent);
        return allowPatchContentFieldDefinition && props.selectedElement.fields[allowPatchContentFieldDefinition.id].toLowerCase() === 'true';
    };

    const disabled = !props.isDesigner && (!allowPatchContent() || props.disabled);

    //TODO if accordion control, set correct default actions
    const isTreeviewControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.TreeViewControl;
    const isAccordionControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.AccordionDsControl;
    const isDataTableControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.DataTableControl;
    const isTabPanelControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.TabDsControl;

    const allowedActions = {
        ...defaultAllowedAction,
        canGroup: !isTreeviewControl && !isAccordionControl && !isTabPanelControl,
        canSummarize: !isTreeviewControl && !isAccordionControl && !isTabPanelControl,
        canExpandGroup: !isTreeviewControl && !isAccordionControl && !isTabPanelControl,
        canHighLight: !isAccordionControl && !isTabPanelControl,
        canHideCaption: isAccordionControl || isTabPanelControl,
        canAlign: !isAccordionControl && !isTabPanelControl,
        canSetColumnWidth: isDataTableControl || isTreeviewControl,
    };

    const onSingleFieldChanged = (value: string, fieldDefinitionId?: string) => {
        // This prevents infinite rendering when modifying template name; the purpose of templateName prop is solely for this workaround.
        if (elementDefinition.systemId === SystemElementDefinitions.Pub.PageTemplate && props.templateName === value) {
            return;
        }

        props.onSingleFieldChanged([{
            elementId: props.selectedElement.elementId,
            fieldId: fieldDefinitionId || fieldDefinition.id,
            value
        }]);
    };

    const onFocusOut = () => {
        const value = !props.isDesigner && (_.isEmpty(fieldValue) || fieldValue === "[]")
            ? templateDsFieldValue : fieldValue;

        onSingleFieldChanged(value);
    };

    const OnSingleNumberFieldChanged = (value: number) => {
        const newVal = value ? value.toString() : '0';
        onSingleFieldChanged(newVal);
    };

    //mandatory systemId to be able to reduce the field list on performance info control
    const wantedOptionItems = [
        SystemFieldDefinitions.Performance.Description,
        SystemFieldDefinitions.Performance.Explanation,
        SystemFieldDefinitions.Performance.Result,
    ] as string[];

    const isStudioComponent = props.hierarchySource === HierarchySource.Studio;

    const isTitleCompatible = (fieldDefinition: Domain.Shared.FieldDefinition) => {
        switch (fieldDefinition.dataType) {
            case Domain.Shared.FieldDataType.Attachment:
            case Domain.Shared.FieldDataType.Boolean:
            case Domain.Shared.FieldDataType.Option:
            case Domain.Shared.FieldDataType.Relation:
                return false;
            case Domain.Shared.FieldDataType.Integer:
            case Domain.Shared.FieldDataType.Decimal:
            case Domain.Shared.FieldDataType.DateTimeOffset:
                return true;
            case Domain.Shared.FieldDataType.String:
                const editorSettings: Domain.Studio.FieldEditorControlSettings = JsonUtils.toJson<Domain.Studio.FieldEditorControlSettings>(fieldDefinition?.editorSettings, undefined);
                return editorSettings && editorSettings.stringDisplayFormat ? editorSettings.stringDisplayFormat === (Domain.Studio.StringDisplayType.SingleLine as Domain.Studio.StringDisplayType) : true;
            default:
                return false;
        }
    }

    switch (fieldDefinition.systemId) {
        case SystemFieldDefinitions.Pub.TextualContent:
            // Don't render this field if it is part of the TextControl.
            if (props.selectedElement.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TextControl) {
                return null;
            }
            return (
                <TextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                />
            );
        case SystemFieldDefinitions.Pub.Name:
            return (
                <TextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: !props.isDesigner,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                />
            );
        case SystemFieldDefinitions.Pub.DataTableDescription:
        case SystemFieldDefinitions.Pub.Title:
        case SystemFieldDefinitions.Pub.MapDescription:
        case SystemFieldDefinitions.Pub.PivotTableDescription:
        case SystemFieldDefinitions.Pub.BarChartDescription:
        case SystemFieldDefinitions.Pub.LineChartDescription:
        case SystemFieldDefinitions.Pub.PieChartDescription:
            return (
                <TextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.MapSize:
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-map-size-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        case SystemFieldDefinitions.Pub.ImageCaption: {
            const showField = hasImageSourceFieldValue();
            return showField ? (
                <TextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onFocusOut={onFocusOut}
                />
            ) : null;
        }
        case SystemFieldDefinitions.Pub.AltText: {
            const showField = hasImageSourceFieldValue();
            return showField ? (
                <TextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    helpText={{ title: fieldDefinition.helpTextTitle, text: fieldDefinition.helpText }}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onFocusOut={onFocusOut}
                />
            ) : null;
        }
        case SystemFieldDefinitions.Pub.TablePanelIsVisible:
        case SystemFieldDefinitions.Pub.ShowCompleteImage:
        case SystemFieldDefinitions.Pub.ColumnsAutoHide:
        case SystemFieldDefinitions.Pub.PivotTableExpandByDefault:
            {
                return (
                    <ToggleElement
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                        booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                        booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                        editorSettings={{
                            disabled: disabled,
                            restrictions: { required: fieldDefinition.required },
                            validationErrors: props.validationErrors,
                            onChange: (val: boolean) => onSingleFieldChanged((String(val).charAt(0).toUpperCase() + String(val).slice(1)))
                        }}
                        value={fieldValue ? fieldValue.toLowerCase() === 'true' : false}
                    />
                )
            }
        case SystemFieldDefinitions.Pub.ShowColumnGrandTotals:
        case SystemFieldDefinitions.Pub.ShowColumnTotals:
        case SystemFieldDefinitions.Pub.ShowRowGrandTotals:
        case SystemFieldDefinitions.Pub.ShowRowTotals:
            {
                if (!props.isDesigner) return null;
                const value = fieldValue === undefined ? true : fieldValue.toLowerCase() === 'true';
                return (
                    <ToggleElement
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                        booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                        booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                        editorSettings={{
                            disabled: disabled,
                            restrictions: { required: fieldDefinition.required },
                            validationErrors: props.validationErrors,
                            onChange: (val: boolean) => onSingleFieldChanged((String(val).charAt(0).toUpperCase() + String(val).slice(1)))
                        }}
                        value={value}
                    />
                )
            }
        case SystemFieldDefinitions.Pub.TitleStyling:
        case SystemFieldDefinitions.Pub.ImageSize:
        case SystemFieldDefinitions.Pub.MenuType:
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-menu-type-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartType:
        case SystemFieldDefinitions.Pub.ChartShowLabels: {
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-chart-show-labels-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        }
        case SystemFieldDefinitions.Pub.PieChartValue:
        case SystemFieldDefinitions.Pub.BarChartValue:
        case SystemFieldDefinitions.Pub.LineChartValue:
            return (
                <DataSourceEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    value={fieldValue}
                    schemaFileId={dataSourceElement?.schemaFileId}
                    schemaFilter={(item) => item.dataType === 'number'}
                    onChange={onSingleFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartArgument:
        case SystemFieldDefinitions.Pub.BarChartArgument:
        case SystemFieldDefinitions.Pub.LineChartArgument:
            return (
                <DataSourceEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    value={fieldValue}
                    schemaFileId={dataSourceElement?.schemaFileId}
                    onChange={onSingleFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.DataSourceTitleColumn:
            return (
                <DataSourceEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    value={fieldValue}
                    schemaFileId={dataSourceElement?.schemaFileId}
                    schemaFilter={(item) => item.format ? item.format === 'singleline' : item.dataType === "string"}
                    onChange={onSingleFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.DataSourceTextColumn:
            return (
                <DataSourceEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    value={fieldValue}
                    schemaFileId={dataSourceElement?.schemaFileId}
                    onChange={onSingleFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartCustomLabel:
            return (
                <MultiLineTextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartValueFormat:
        case SystemFieldDefinitions.Pub.BarChartValueFormat:
        case SystemFieldDefinitions.Pub.LineChartValueFormat:
            {
                const pieChartValueFormat: FormatType = JsonUtils.toJson(fieldValue);
                return (<PieChartValueFormatEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    myKey={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    disabled={disabled}
                    required={fieldDefinition.required}
                    validationErrors={props.validationErrors}
                    value={pieChartValueFormat}
                    onValueChange={(format) => {
                        onSingleFieldChanged(JSON.stringify(format));
                    }}
                />)
            }
        case SystemFieldDefinitions.Pub.PieChartMaximumSlices: {
            return (
                <IntegerElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    controlButtons={true}
                    startValue={10}
                    min={0}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength, },
                        validationErrors: props.validationErrors,
                        onChange: OnSingleNumberFieldChanged
                    }}
                    value={fieldValue ? Number.parseInt(fieldValue) : 0}
                />
            );
        }
        case SystemFieldDefinitions.Pub.PieChartCombinedSliceLabel:
            return (
                <TextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                />
            );

        case SystemFieldDefinitions.Pub.PivotGridFields: {
            const originalValues = JsonUtils.toJson(fieldValue, []);
            const groupedFields = _.groupBy(originalValues, 'area');
            return (
                <PivotTableFieldsEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    schemaFileId={dataSourceElement?.schemaFileId}
                    onLoadAttachment={props.onLoadAttachment}
                    value={groupedFields}
                    onValueChange={onSingleFieldChanged}
                />);
        }
        case SystemFieldDefinitions.Pub.VirtualPivotGridFields: {
            const relatedFieldId = elementDefinition.fields?.find(e => e.systemId === SystemFieldDefinitions.Pub.PivotGridFields)?.id;
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.PivotGridFields);
            return (
                <PivotTableFieldsAdvancedEditor
                    key={`advanced-${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    value={relatedFieldValue}
                    sourceElementId={props.selectedElement.elementId}
                    sourceFieldId={relatedFieldId}
                    onChange={(change: Domain.Publisher.FieldPatch) => props.onSingleFieldChanged([change])}
                />
            );
        }
        case SystemFieldDefinitions.Pub.HelpText:
            return (
                <MultiLineTextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: !props.isDesigner,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged,
                        readMore: {
                            active: true,
                            length: 450,
                        },
                    }}
                    value={fieldValue}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.HtmlContent:
            if (props.isDesigner) return null;
            return (
                <MultiLineTextElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    rows={10}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.ImageSource:
            return (
                <AttachmentElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required, maxFileSize: fieldDefinition.attachmentMaxFileSize, allowedFileTypes: fieldDefinition.attachmentAllowedFileTypes },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                />
            );
        case SystemFieldDefinitions.Pub.FileSource:
            if (props.isDesigner) return null;

            const attachmentComplexDefinition = elementDefinition.complexFields.find((complexField) => complexField.systemId === SystemFieldDefinitions.Pub.AttachmentsComplex);
            const fileSourceDefinition = attachmentComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.FileSource);
            const fileNameDefinition = attachmentComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.FileName);
            const fileSizeDefinition = attachmentComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.FileSize);
            const values = Object.values(complexFieldValues)
                .filter((complexField) => Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === attachmentComplexDefinition.id)
                .map((complexField) => ({
                    rowIndex: complexField.rowIndex,
                    source: complexField.fields[fileSourceDefinition.id],
                    name: complexField.fields[fileNameDefinition.id],
                    size: complexField.fields[fileSizeDefinition.id],
                }));

            const onAttachmentFieldChanged = (rowIndex: number, fileSource: string, fileName: string, fileSize: string, deleted: boolean): void => {
                props.onComplexFieldChanged({
                    elementId: props.selectedElement.elementId,
                    complexFieldDefinitionId: attachmentComplexDefinition.id,
                    rowIndex: rowIndex,
                    complexField: {
                        rowIndex: rowIndex,
                        complexFieldDefinitionId: attachmentComplexDefinition.id,
                        fields: {
                            [fileSourceDefinition.id]: fileSource,
                            [fileNameDefinition.id]: fileName,
                            [fileSizeDefinition.id]: fileSize
                        }
                    },
                    deleted: deleted,
                });
            };

            return (
                <ReferenceAttachmentsEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    disabled={disabled}
                    uploaderId={fileSourceDefinition.id}
                    uploaderLabel={fileSourceDefinition.label ? fileSourceDefinition.label : fileSourceDefinition.name}
                    maxFileSize={fileSourceDefinition.attachmentMaxFileSize}
                    allowedFileTypes={fileSourceDefinition.attachmentAllowedFileTypes}
                    listId={attachmentComplexDefinition.id}
                    listLabel={attachmentComplexDefinition.label ? attachmentComplexDefinition.label : attachmentComplexDefinition.name}
                    validationErrors={props.validationErrors}
                    values={values}
                    onAttachmentFieldChanged={onAttachmentFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    onRemoveAttachment={props.onRemoveAttachment}
                />
            );
        case SystemFieldDefinitions.Pub.ImageFocusPoint: {
            const imageFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.ImageSource);
            const fillFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.ShowCompleteImage);
            const showFocusPoint = props.selectedElement.fields[fillFieldDefinition.id] ? props.selectedElement.fields[fillFieldDefinition.id].toLowerCase() === 'true' : false;
            return showFocusPoint ? (
                <ImageFocusPointElement
                    key={`${props.selectedElement.elementId}-fieldDefinition.name`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    attachmentId={props.selectedElement.fields[imageFieldDefinition.id]}
                    helpText={{ title: fieldDefinition.helpTextTitle, text: fieldDefinition.helpText }}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    onLoadAttachment={props.onLoadAttachment}
                />) : null;
        }
        case SystemFieldDefinitions.Pub.DataSource:

            return (
                <DataSourceSelection
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    publicationId={props.publicationId}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    fieldValue={fieldValue}
                    selectedElement={props.selectedElement}
                    validationErrors={props.validationErrors}
                    getElementDefinition={props.getElementDefinition}
                    onFieldsChanged={props.onSingleFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.TableColumnSettings:
            return (
                dataSourceElement?.schemaFileId ?
                    <ColumnBuilderElement
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                        editorSettings={{
                            disabled: !(dataSourceElement?.schemaFileId) || disabled,
                            restrictions: { required: fieldDefinition.required },
                            validationErrors: props.validationErrors,
                            onChange: onSingleFieldChanged
                        }}
                        value={fieldValue}
                        allowedActions={allowedActions}
                    /> : null
            );
        case SystemFieldDefinitions.Pub.Filter:
            const variables = props.variables?.map(variable => ({ label: `$${variable.name}`, value: `$${variable.name}` }));

            return (
                <FilterBuilderElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    fields={dataSourceFields}
                    editorSettings={{
                        disabled: !(dataSourceElement?.schemaFileId) || disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: onSingleFieldChanged
                    }}
                    value={fieldValue}
                    variables={variables}
                    onReset={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.GrandSummaryText:
        case SystemFieldDefinitions.Pub.PivotTableGrandTotalCaption:
            {
                return (
                    <TextElement
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                        editorSettings={{
                            disabled: disabled,
                            restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
                            validationErrors: props.validationErrors,
                            onChange: onSingleFieldChanged
                        }}
                        value={fieldValue}
                        onFocusOut={onFocusOut}
                    />
                );
            }
        // Template designer only fields
        case SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField:
            if (!props.isDesigner) return null;

            return (
                <WhitespaceEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    element={props.selectedElement}
                    fieldDefinitions={elementDefinition.fields}
                    fieldValues={props.selectedElement.fields}
                    disabled={disabled}
                    validationErrors={props.validationErrors}
                    onChange={(change: Domain.Publisher.FieldPatch) => props.onSingleFieldChanged([change])}
                />
            );
        case SystemFieldDefinitions.Pub.ShowBackground:
        case SystemFieldDefinitions.Pub.ShowShadow:
        case SystemFieldDefinitions.Pub.AllowPatchContent:
        case SystemFieldDefinitions.Pub.PrintAsLandscape:
            if (!props.isDesigner) return null;
            return (
                <ToggleElement
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                    booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (val) => onSingleFieldChanged((String(val).charAt(0).toUpperCase() + String(val).slice(1)))
                    }}
                    value={fieldValue ? fieldValue.toLowerCase() === 'true' : false}
                />
            );
        case SystemFieldDefinitions.Pub.StackContainerDirection:
            if (!props.isDesigner) return null;
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-container-dir-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required }, //TODO: old app doesn't show this field as required, but it's sent as such from the backend.
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        case SystemFieldDefinitions.Pub.BarChartOrientation:
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-chart-orientation-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        case SystemFieldDefinitions.Pub.ChartLegend:
            if (props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.BarChartControl) return null;
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-chart-legend-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );

        // Render no editor for fields that should not be visible anywhere.
        case SystemFieldDefinitions.Pub.TextHtmlContent:
        case SystemFieldDefinitions.Pub.ShowWhitespaceTop:
        case SystemFieldDefinitions.Pub.ShowWhitespaceBottom:
        case SystemFieldDefinitions.Pub.ShowWhitespaceLeft:
        case SystemFieldDefinitions.Pub.ShowWhitespaceRight:
            // The Container item itself is selectable when placed inside a tabbed control. In this case, ignore all of its fields.
            return null;
        case SystemFieldDefinitions.Pub.IsStudioControl: {
            const source = fieldValue?.toLowerCase() === 'true' ? ActionSource.Studio : (fieldValue?.toLowerCase() === 'false' ? ActionSource.Performance : null);

            return (
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-is-studio-ctrl-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label='Module'
                    placeholder='Selecteer...'
                    searchable={false}
                    clearable={true}
                    optionItems={helpers.AccordionTypes}
                    value={helpers.AccordionTypes.find(at => at.value === fieldValue?.toLowerCase())}
                    onFocusOut={onFocusOut}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            const changes = getDependentChanges(props.selectedElement, elementDefinition, [
                                SystemFieldDefinitions.Pub.HierarchyDefinitionId,
                                SystemFieldDefinitions.Pub.EntityTypeId,
                                SystemFieldDefinitions.Pub.EntityId,
                                SystemFieldDefinitions.Pub.FieldId,
                                SystemFieldDefinitions.Pub.ParentEntityTypeId,
                                SystemFieldDefinitions.Pub.ParentEntityId,
                                SystemFieldDefinitions.Pub.HeaderFieldId,
                                SystemFieldDefinitions.Pub.FieldsListJson,
                            ], props.getDefinition(item?.value, source)
                            );

                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: (String(item?.value).charAt(0).toUpperCase() + String(item?.value).slice(1)),
                            });

                            if (item?.value?.toLowerCase() === 'false') {
                                const controlSelectedHierarchyDefinition = props.hierarchyDefinitions[ActionSource.Performance][0];
                                setSelectedHierarchyDefinition(controlSelectedHierarchyDefinition);
                                props.getAvailableHierarchyTypes(controlSelectedHierarchyDefinition.items, ActionSource.Performance);
                                const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
                                if (!!relatedFieldValue && props.loadPerformanceHierarchy) {
                                    props.loadPerformanceHierarchy(relatedFieldValue);
                                }
                            }

                            props.onSingleFieldChanged(changes);
                        },
                    }}
                />);
        }
        case SystemFieldDefinitions.Pub.MeasureMomentId:
            return (
                <SelectElement<Domain.Shared.MeasureMoment>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-moment-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={true}
                    optionItems={props.measureMoments}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            const changes = getDependentChanges(props.selectedElement, elementDefinition, [SystemFieldDefinitions.Pub.HierarchyDefinitionId, SystemFieldDefinitions.Pub.EntityId, SystemFieldDefinitions.Pub.ParentEntityId]);
                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.id
                            });
                            props.onSingleFieldChanged(changes);
                            //If performance
                            if (props.hierarchySource === HierarchySource.Performance && !!item && props.loadPerformanceHierarchy) {
                                props.loadPerformanceHierarchy(item?.id);
                            }

                            if (props.hierarchySource === HierarchySource.Studio && !!item) {
                                const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId);
                                const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === item?.id && h.definitionId === relatedFieldValue);
                                if (!!relatedFieldValue && !!hierarchy) {
                                    props.loadStudioHierarchy(hierarchy.id, relatedFieldValue, item.id);
                                }
                            }
                        }
                    }}
                    value={props.measureMoments?.find(m => m.id === fieldValue)}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.HierarchyDefinitionId: {
            const isStudioControlFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            if (!isStudioControlFieldValue || isStudioControlFieldValue.toLowerCase() === 'false') return null;
            const measureMomentDefinitionIdFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
            const canSetValue = isStudioControlFieldValue?.toLowerCase() === 'false' || (isStudioControlFieldValue?.toLowerCase() === 'true' && !!measureMomentDefinitionIdFieldValue);
            const value = pubContext.studioHierarchies?.find(hd => hd.momentId === measureMomentDefinitionIdFieldValue && hd.definitionId === fieldValue);

            return (
                <SelectElement<Domain.Studio.HierarchyListItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-hierarchy-def-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={pubContext.studioHierarchies?.filter(hd => hd.momentId === measureMomentDefinitionIdFieldValue)}
                    editorSettings={{
                        disabled: disabled || !canSetValue,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            const changes = getDependentChanges(props.selectedElement, elementDefinition, [
                                SystemFieldDefinitions.Pub.EntityTypeId,
                                SystemFieldDefinitions.Pub.EntityId,
                                SystemFieldDefinitions.Pub.FieldId,
                                SystemFieldDefinitions.Pub.ParentEntityTypeId,
                                SystemFieldDefinitions.Pub.ParentEntityId,
                                SystemFieldDefinitions.Pub.HeaderFieldId,
                                SystemFieldDefinitions.Pub.FieldsListJson,
                            ], props.getDefinition(item?.definitionId, ActionSource.Studio)
                            );

                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.definitionId
                            });

                            const controlSelectedHierarchyDefinition = props.hierarchyDefinitions[ActionSource.Studio].find(hd => hd.hierarchyDefinitionId === item?.definitionId);

                            if (!!controlSelectedHierarchyDefinition) {
                                setSelectedHierarchyDefinition(controlSelectedHierarchyDefinition);
                                props.getAvailableHierarchyTypes(controlSelectedHierarchyDefinition.items, ActionSource.Studio);
                                const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
                                const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === relatedFieldValue && h.definitionId === item?.definitionId);
                                if (!!relatedFieldValue && !!hierarchy && !!item?.definitionId && props.loadStudioHierarchy) {
                                    props.loadStudioHierarchy(hierarchy.id, item.definitionId, relatedFieldValue);
                                }
                            }
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                    value={value}
                />
            );
        }
        case SystemFieldDefinitions.Pub.EntityTypeId: {
            const isStudioControlFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            const source = isStudioControlFieldValue?.toLowerCase() === 'true' ? ActionSource.Studio : ActionSource.Performance;
            const hierarchyDefinitionIdFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId);
            const canSetValue = (props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.PerformanceInformationControl)
                ? true
                : isStudioControlFieldValue?.toLowerCase() === 'false' || (isStudioControlFieldValue?.toLowerCase() === 'true' && !!hierarchyDefinitionIdFieldValue);

            return (
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-entity-type-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={props.elementTypes || []}
                    editorSettings={{
                        disabled: disabled || !canSetValue,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            const changes = getDependentChanges(props.selectedElement, elementDefinition, [
                                SystemFieldDefinitions.Pub.EntityId,
                                SystemFieldDefinitions.Pub.FieldId,
                                SystemFieldDefinitions.Pub.ParentEntityTypeId,
                                SystemFieldDefinitions.Pub.ParentEntityId,
                                SystemFieldDefinitions.Pub.HeaderFieldId,
                                SystemFieldDefinitions.Pub.FieldsListJson,
                            ], props.getDefinition(item.value, source)
                            );

                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item.value
                            });
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                    value={props.elementTypes?.find(el => el.value === fieldValue)}
                />
            );
        }
        case SystemFieldDefinitions.Pub.EntityId: {
            const measureMomentFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const hierarchyDefinitionFieldValue = isStudioComponent ? getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId) : 'performance';
            const entityDefinition = relatedFieldValue ? props.getDefinition(relatedFieldValue, ActionSource.Performance) : undefined;
            const canSetValue = !!measureMomentFieldValue && !!relatedFieldValue && !!hierarchyDefinitionFieldValue;

            const optionItems = entityDefinition && canSetValue && props.hierarchies[measureMomentFieldValue]
                ? props.hierarchies[measureMomentFieldValue][hierarchyDefinitionFieldValue]
                    ?.filter((item: Domain.Performance.HierarchyItem) => item.element.elementDefinitionId === relatedFieldValue)
                    ?.map((item: Domain.Performance.HierarchyItem) => {
                        const elementItem = new Domain.Studio.HierarchyItemElement();
                        FieldsHelper.mapObject<Domain.Studio.HierarchyItemElement>(elementItem, entityDefinition.fields, item.element.fields);
                        elementItem.id = item.element.elementId;
                        return { value: elementItem.id, label: elementItem.name };
                    })
                : [];

            return (
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-entity-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={optionItems}
                    editorSettings={{
                        disabled: disabled || !canSetValue,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.value)
                    }}
                    value={optionItems?.find(item => item.value === fieldValue)}
                />
            );
        }
        case SystemFieldDefinitions.Pub.FieldId: {
            const isStudioControlFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            const source = isStudioControlFieldValue?.toLowerCase() === 'true' ? ActionSource.Studio : ActionSource.Performance;
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const optionItems = relatedFieldValue && selectedHierarchyDefinition ?
                props.getDefinition(relatedFieldValue, source)
                    ?.fields
                    ?.filter((e) => (selectedHierarchyDefinition.systemId === SystemHierarchyDefinitions.Performance && wantedOptionItems.includes(e.systemId))
                        || selectedHierarchyDefinition.systemId === SystemHierarchyDefinitions.Dynamic) : [];
            return (
                <SelectElement<Domain.Shared.FieldDefinition>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-field-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={optionItems}
                    editorSettings={{
                        disabled: disabled || !!!relatedFieldValue,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id),
                    }}
                    value={!!relatedFieldValue ? optionItems?.find(opt => opt.id === fieldValue) : null}
                />
            );
        }
        case SystemFieldDefinitions.Pub.ParentEntityTypeId: {
            const isStudioControlFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            if (!isStudioControlFieldValue || !selectedHierarchyDefinition) return null;
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const optionItems = relatedFieldValue
                ? getAvailableAncestorsElementDefinitions(selectedHierarchyDefinition, relatedFieldValue)
                    ?.map((linkDefinition) => props.elementTypes?.find(e => e.value === linkDefinition.fromElementDefinitionId))
                : [];
            const uniqueOptionItems = _.uniqWith(optionItems, (a, b) => a.value === b.value);

            return (
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-parent-entity-type-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={uniqueOptionItems}
                    editorSettings={{
                        disabled: disabled || !!!relatedFieldValue,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            const changes = getDependentChanges(props.selectedElement, elementDefinition, [SystemFieldDefinitions.Pub.ParentEntityId]);
                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.value
                            });
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                    value={!!relatedFieldValue ? uniqueOptionItems.find(item => item.value === fieldValue) : null}
                />
            );
        }
        case SystemFieldDefinitions.Pub.ParentEntityId: {
            if (props.isDesigner) return null;
            const measureMomentFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
            const hierarchyDefinitionFieldValue = isStudioComponent ? getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId) : 'performance';
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.ParentEntityTypeId);
            const source = isStudioComponent ? ActionSource.Studio : ActionSource.Performance;
            const entityDefinition = relatedFieldValue ? props.getDefinition(relatedFieldValue, source) : undefined;
            const canSetValue = !!measureMomentFieldValue && !!relatedFieldValue && !!hierarchyDefinitionFieldValue;
            const optionItems = entityDefinition && canSetValue && props.hierarchies[measureMomentFieldValue] ? props.hierarchies[measureMomentFieldValue][hierarchyDefinitionFieldValue]
                ?.filter((item: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem) => item.element.elementDefinitionId === relatedFieldValue)
                ?.map((item: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem) => {
                    const elementItem = new Domain.Studio.HierarchyItemElement();
                    FieldsHelper.mapObject<Domain.Studio.HierarchyItemElement>(elementItem, entityDefinition.fields, item.element.fields);
                    elementItem.id = item.element.elementId;
                    return { value: elementItem.id, label: elementItem.name };
                }) : [];
            const uniqueOptionItems = _.uniqWith(optionItems, (a, b) => a.value === b.value);

            return (
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-parent-entity-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    value={canSetValue ? uniqueOptionItems?.find(item => item.value === fieldValue) : null}
                    searchable={false}
                    clearable={true}
                    optionItems={uniqueOptionItems}
                    editorSettings={{
                        disabled: disabled || !canSetValue,
                        restrictions: { required: !props.isDesigner },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.value)
                    }}
                    onFocusOut={onFocusOut}
                />
            );
        }
        case SystemFieldDefinitions.Pub.HeaderFieldId: {
            const source = isStudioComponent ? ActionSource.Studio : ActionSource.Performance;
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const optionItems = relatedFieldValue ? props.getDefinition(relatedFieldValue, source)
                ?.fields?.filter((field: Domain.Shared.FieldDefinition) => isTitleCompatible(field))
                ?.filter(item => !!item)
                : [];
            return (
                <SelectElement<Domain.Shared.FieldDefinition>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-header-field-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={optionItems}
                    value={!!relatedFieldValue ? optionItems?.find(item => item.id === fieldValue) : null}
                    editorSettings={{
                        disabled: disabled || !!!relatedFieldValue,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            const changes = getDependentChanges(
                                props.selectedElement,
                                elementDefinition,
                                [SystemFieldDefinitions.Pub.FieldsListJson],
                                props.getDefinition(relatedFieldValue, source),
                                item?.id);
                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.id
                            });
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                />
            );
        }
        case SystemFieldDefinitions.Pub.FieldsListJson: {
            const source = props.hierarchySource === HierarchySource.Studio ? ActionSource.Studio : ActionSource.Performance;
            const relatedFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            // filter out headerId from shown fields
            const headerFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HeaderFieldId);

            const hierarchyEntityFields = relatedFieldValue ?
                props.getDefinition(relatedFieldValue, source)?.fields
                : [];
            const originalValues = !!fieldValue ? JSON.parse(fieldValue) : [];

            const optionItems = hierarchyEntityFields?.reduce((acc, e: Domain.Shared.FieldDefinition) => {
                if (e.id === headerFieldValue) {
                    return acc;
                }

                const originalValue = originalValues?.find((original) => original.fieldId === e.id);

                const item = originalValue ?? { fieldId: e.id, label: e.name, visible: true, index: 0, showFieldName: true };

                if (_.isUndefined(item.showFieldName)) {
                    originalValue.showFieldName = true;
                }

                return [...acc, item];
            }, [])?.sort((a, b) => a.index - b.index) || [];


            return (
                <FieldListEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    value={optionItems}
                    onValuesChanged={onSingleFieldChanged}
                    disabled={disabled || !!!relatedFieldValue}
                />
            );
        }
        case Domain.SystemFieldDefinitions.Pub.TileImageKind: {
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-tile-image-kind-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldValue ? fieldDefinition.optionItems.find(fd => fd.id === fieldValue) : fieldDefinition.optionItems.find(fd => fd.value === 1)}
                />
            );
        }
        case Domain.SystemFieldDefinitions.Pub.TilePage: {
            return null;
        }
        case SystemFieldDefinitions.Pub.TitleColumn:
        case SystemFieldDefinitions.Pub.HeaderField: {
            return (
                dataSourceElement?.schemaFileId
                    ? <DataSourceEditor
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                        disabled={disabled}
                        value={fieldValue}
                        schemaFileId={dataSourceElement.schemaFileId}
                        schemaFilter={(item) => item.propertyGroupKind !== 'SystemField' && item.format !== 'html'}
                        onChange={onSingleFieldChanged}
                        onLoadAttachment={props.onLoadAttachment}
                        onFocusOut={onFocusOut}
                    /> : null
            );
        }
        case SystemFieldDefinitions.Pub.Latitude:
        case SystemFieldDefinitions.Pub.Longitude: {
            return (
                dataSourceElement?.schemaFileId
                    ? <DataSourceEditor
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        value={fieldValue}
                        label={fieldDefinition.label}
                        schemaFileId={dataSourceElement?.schemaFileId}
                        schemaFilter={(item) => item.dataType === 'number'}
                        onChange={onSingleFieldChanged}
                        onLoadAttachment={props.onLoadAttachment}
                        onFocusOut={onFocusOut}
                    /> : null
            );
        }
        case SystemFieldDefinitions.Pub.LabelColumnName: {
            return (
                dataSourceElement?.schemaFileId
                    ? <DataSourceEditor
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        value={fieldValue}
                        label={fieldDefinition.label}
                        schemaFileId={dataSourceElement?.schemaFileId}
                        schemaFilter={(item) => item.dataType === 'string' && !renderModeAvailable.includes(item.propertyGroupKind)}
                        onChange={onSingleFieldChanged}
                        onLoadAttachment={props.onLoadAttachment}
                        onFocusOut={onFocusOut}
                    /> : null
            );
        }
        case SystemFieldDefinitions.Pub.TabPosition:
        case SystemFieldDefinitions.Pub.TabIconPosition:
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    displayExpr='name'
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={`sel-tab-pos-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        disabled: disabled,
                        restrictions: { required: fieldDefinition.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        case SystemFieldDefinitions.Pub.TabIcon:
            return <IconSelectionEditor
                key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                id={fieldDefinition.id}
                label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                disabled={disabled}
                value={fieldValue}
                icons={props.icons}
                onChange={onSingleFieldChanged} />;
        case SystemFieldDefinitions.Pub.VirtualVariablesField: {
            const variablesComplexDefinition = elementDefinition.complexFields.find((complexField) => complexField.systemId === SystemFieldDefinitions.Pub.VariablesComplex);
            const variableNameDefinition = variablesComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.VariableNameField);
            const variableValueDefinition = variablesComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.VariableValueField);

            const values = Object.values(complexFieldValues)
                .filter((complexField) =>
                    complexField.fields && Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === variablesComplexDefinition.id)
                .map((complexField) => ({
                    rowIndex: complexField.rowIndex,
                    name: complexField.fields[variableNameDefinition.id],
                    value: complexField.fields[variableValueDefinition.id],
                }));

            return <VariableListEditor
                label={variablesComplexDefinition.label}
                values={values}
                onAddRemoveVariable={(item) => {
                    const maxRowIndex = Math.max(...values.map(v => v.rowIndex || 0), -1) + 1;
                    const data: Domain.Shared.ComplexField & { type: string } = {
                        rowIndex: maxRowIndex,
                        complexFieldDefinitionId: variablesComplexDefinition.id,
                        fields: {
                            [variableNameDefinition.id]: item.name,
                            [variableValueDefinition.id]: item.value,
                        },
                        type: item.type,
                    };

                    props.onVariablesChanged(data);
                }}
                allowCreate={props.isDesigner}
                allowDelete={props.isDesigner}
            />;
        }
        default:
            console.log(fieldDefinition);
            return (<>{fieldDefinition.systemId} - {fieldDefinition.name}<br /></>);
    }
}

const getAvailableAncestorsElementDefinitions = (hierachyDefinition: Domain.Shared.HierarchyDefinition, entityTypeId: string, result: Domain.Shared.HierarchyLinkDefinition[] = []): Domain.Shared.HierarchyLinkDefinition[] => {
    const parents = hierachyDefinition.items?.filter((hierarchyDefinitionLink) => !!hierarchyDefinitionLink.fromElementDefinitionId && hierarchyDefinitionLink.toElementDefinitionId === entityTypeId);
    if (parents.length > 0) {
        result = result.concat(parents);
        return getAvailableAncestorsElementDefinitions(hierachyDefinition, parents[0].fromElementDefinitionId, result);
    } else return result;
};

const getFieldDefinition = (elementDefinition: Domain.Shared.ElementDefinition, fieldId: string): Domain.Shared.FieldDefinition => {
    if (fieldId === SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField) {
        return Helper.getVirtualWhitespaceFieldDefinition();
    }

    if (fieldId === SystemFieldDefinitions.Pub.VirtualPivotGridFields) {
        const auxField = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.PivotGridFields);
        return { ...auxField, systemId: Domain.SystemFieldDefinitions.Pub.VirtualPivotGridFields, id: Domain.SystemFieldDefinitions.Pub.VirtualPivotGridFields };
    }

    if (fieldId === SystemFieldDefinitions.Pub.VirtualVariablesField) {
        return Helper.getVirtualVariablesFieldDefinition();
    }

    if (elementDefinition.systemId === SystemElementDefinitions.Pub.ReferenceAttachments || elementDefinition.systemId === SystemElementDefinitions.Pub.TileMenuControl) {
        const complexFieldDefinition = elementDefinition.complexFields[0].fields.find((item) => item.id === fieldId);
        if (complexFieldDefinition) {
            return complexFieldDefinition;
        }
    }

    return elementDefinition.fields.find((item) => item.id === fieldId);
};

/**
 * Get field value from Publisher item.
*/
const getFieldValue = (selectedElement: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition, fieldSystemId: string): string | undefined => {
    const fieldDefinitionId = elementDefinition.fields?.find((item: Domain.Shared.FieldDefinition) => item.systemId === fieldSystemId)?.id;
    const fieldValue = selectedElement.fields[fieldDefinitionId];
    return fieldValue;
};

/**
 * Clear dependent fields on cascade from the selected element.
 * 
 * @param selectedElement The selected Pub control.
 * @param elementDefinition The element definition of the selected Pub element.
 * @param fieldSystemIds The list of system Id's from Pub element to be cleaned.
 * @param performanceElementDefinition The performance element definition ??
 * @param headerId The field Id used for header on accordion control.
 * @returns An array with the resetted values.
 */
const getDependentChanges = (selectedElement: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition, fieldSystemIds: string[], hierarchyElementDefinition?: Domain.Shared.ElementDefinition, headerId?: string): Domain.Publisher.FieldPatch[] => {
    const changes: Domain.Publisher.FieldPatch[] = [];
    fieldSystemIds.forEach(fieldSystemId => {
        const dependentField = elementDefinition.fields?.find(e => e.systemId === fieldSystemId);
        let allFields = [];
        if (dependentField && dependentField.id) {
            if (hierarchyElementDefinition && dependentField.systemId === SystemFieldDefinitions.Pub.FieldsListJson) {
                allFields = hierarchyElementDefinition.fields?.map((e: Domain.Shared.FieldDefinition) => ({
                    fieldId: e.id,
                    label: e.name,
                    visible: !(e.id === headerId),
                    index: 0,
                    showFieldName: true
                })) || [];
            }
            changes.push({
                elementId: selectedElement.elementId,
                fieldId: dependentField.id,
                value: allFields.length > 0 ? JSON.stringify(allFields) : "",
            });
        }
    });
    return changes;
};

/**
 * Defines a list of systemIds, which need a performance hierarchy loaded.
 */
const controlsRequiredPerformanceHierarchy = [
    SystemElementDefinitions.Pub.AccordionDataControl,
    SystemElementDefinitions.Pub.PerformanceInformationControl,
] as string[];

/**
 * Gets the base settings of an element.
 * 
 * @param element Defines the element.
 * @param elementDefinition Defines the element definition.
 */
const getBaseElementSettings = (element: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition): Domain.Publisher.BaseControlElement => {
    const elementSettings = new Domain.Publisher.BaseControlElement();
    FieldsHelper.mapObject<Domain.Publisher.BaseControlElement>(elementSettings, elementDefinition.fields, element.fields);

    return elementSettings;
};

import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import Styled from './index.styled';
import { Props } from './index.props';

/**
 * Represents a UI component that renders a HTML viewer.
 */
export const HtmlViewer: React.FC<Props> = (props) => {
    const isStringOrStringifiable = value =>
        typeof value === 'string' ||
        (typeof value === 'number' && !isNaN(value)) ||
        (value != null && typeof value === 'object' && typeof value.toString === 'function');
    const htmlParsed = parse(isStringOrStringifiable(props?.value) ? props.value.toString() : '');
    /**
     * useEffect hook to handle navigation within the HtmlViewer component if custom navigation is provided.
     */
    useEffect(() => {
        if (!props.onNavigate || !props.id) {
            return;
        }
        const elementId = `htmlviewer-${props.id}`;
        const wrapperElement = document.getElementById(elementId);

        if (!wrapperElement) {
            return;
        }

        const handleNavigation = (event) => {
            const target = event.target.closest('a');
            if (target && target.href) {
                event.preventDefault();
                props.onNavigate?.(target);
            }
        };
        wrapperElement.addEventListener('click', handleNavigation);
        return () => wrapperElement.removeEventListener('click', handleNavigation);
    }, [props.onNavigate, props.id]);

    return (
        <Styled.HtmlWrapper id={`htmlviewer-${props.id}`} className={props.className} style={props.style}>
            {htmlParsed}
        </Styled.HtmlWrapper>
    );
};

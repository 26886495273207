import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { DxPivotArea } from '@liasincontrol/ui-devextreme';
import { ColumnBuilderElement, defaultAllowedAction } from '@liasincontrol/ui-elements';
import { JsonUtils } from '@liasincontrol/core-service';
import { AreaType, availableAreas } from './PivotTableFieldsEditor';

type Props = {
    id?: string,
    label?: string,
    disabled?: boolean,
    key?: string,
    useDragHandle?: true,
    value: string,
    sourceElementId?: string,
    sourceFieldId?: string,
    onChange: (change: Domain.Publisher.FieldPatch) => void,
}

/**
 * Represents a UI component that renders an editor.
 */
const PivotTableFieldsAdvancedEditor: React.FC<Props> = (props) => {
    const originalValues = JsonUtils.toJson(props.value, []);

    const onChanged = (data: string, area: DxPivotArea): void => {
        const otherAreaData = originalValues.filter(value => value.area !== area);
        const selectedAreaData = JsonUtils.toJson(data, []);

        const change = {
            elementId: props.sourceElementId,
            fieldId: props.sourceFieldId,
            value: JSON.stringify(otherAreaData.concat(selectedAreaData))
        };
        props.onChange(change);
    }

    return (<>
        {availableAreas.map((area: AreaType) => {
            const areaValue = JSON.stringify(originalValues.filter(areaValue => areaValue.area === area.name));
            const areaName = area.name;
            const areaLabel = area.displayName;
            return <ColumnBuilderElement
                id={`${props.id}-${areaName}`}
                key={`${props.id}-${areaName}`}
                label={areaLabel}
                editorSettings={{
                    disabled: false,
                    validationErrors: undefined,
                    restrictions: undefined,
                    onChange: (data: string) => onChanged(data, areaName),
                }}
                value={areaValue}
                allowedActions={{
                    ...allowedActions,
                    canSort: !!area.allowSorting,
                    canDivide: !!area.allowDividing,
                }}
            />;
        })}
    </>
    );
}

export { PivotTableFieldsAdvancedEditor };

const allowedActions = {
    ...defaultAllowedAction,
    canHide: false,
    canAlign: false,
    canCaption: false,
    canDivide: false,
    canFormat: true,
    canSummarize: false,
    canSort: true,
    canHighLight: false,
    canGroup: false,
    canExpandGroup: false,
    canDrag: true,
    canHideCaption: false,
    canSetColumnWidth: false,
};

import React, { useCallback, useEffect, useRef } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import ReactDOM from 'react-dom/client';
import { LsPivotTable, PivotCell, PivotGridDataSource, ToolbarItem } from "@liasincontrol/ui-devextreme";
import { CheckboxElement } from '@liasincontrol/ui-elements';
import { Section } from '@liasincontrol/ui-basics';

export type BudgetDevGridProps = {
    id?: string;
    dataSource: PivotGridDataSource;
    onCellClick?: (e) => void;
    divideBy: number;
    setDivideBy: (val: number) => void;
    cellSelectionEnabled?: boolean;
    selectedCellElementBackgroundColor?: string;
    selectedCellElementColor?: string;
}

export const BudgetDevelopmentGrid: React.FC<BudgetDevGridProps> = (props: BudgetDevGridProps) => {
    const selectedCellRef = useRef<PivotCell | null>(null);
    const selectedCellKeyRef = useRef<string | null>(null); // Store the key of the selected cell

    const onCellClick = useCallback((e) => {
        if (isDataCell(e.cell)) {
            selectedCellRef.current = e.cell as PivotCell;
            selectedCellKeyRef.current = getCellKey(e.cell);
        }

        if (props.onCellClick) {
            props.onCellClick(e);
        }
    }, [props.onCellClick, isDataCell, getCellKey]);

    const onCellPrepared = useCallback((cell: PivotCell, cellElement: HTMLElement) => {
        if (!cell.value || !cellElement) return;

        if ((isColumnDataCell(cell) || isColumnTotalCell(cell)) && !isColumnFullyExpanded(cell)) {
            const icon = renderAmmountCell(cell);
            cellElement.appendChild(icon);
            cellElement.style.color = cell.value > 0 ? '#960000' : '#006E00';
        }

        const cellKey = getCellKey(cell); // Generate the key for the current cell
        const isSelected = props.cellSelectionEnabled && selectedCellKeyRef.current === cellKey; // Compare with the stored selected cell's key
        if (isSelected) {
            cellElement.style.backgroundColor = props.selectedCellElementBackgroundColor ?? bgActiveCell;
            cellElement.style.color = props.selectedCellElementColor ?? fgActiveCell;
        }
    }, [props.cellSelectionEnabled, selectedCellKeyRef.current]);

    // Clear selection when dataSource is updated
    useEffect(() => {
        selectedCellRef.current = null;
        selectedCellKeyRef.current = null;
    }, [props.dataSource]);

    return (<Section look='white'>
        <LsPivotTable
            id={props.id}
            dataSource={props.dataSource}
            showColumnGrandTotals={false}
            showColumnTotals={true}
            scrolling={true}
            scrollIntoView={false}
            onCellPrepared={onCellPrepared}
            onCellClick={onCellClick}
            showToolbar={true}
            customToolbarItems={[
                <ToolbarItem location="before" locateInMenu="auto" cssClass='pr-100'>
                    <CheckboxElement
                        id='input-divideBy'
                        label='Bedragen delen'
                        altLabel='Delen door 1000'
                        value={props.divideBy === 1000 ? true : false}
                        editorSettings={{
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (val) => {
                                props.setDivideBy(val ? 1000 : 1);
                            },
                        }}
                    />
                </ToolbarItem>
            ]}
        />

    </Section>);
};

const bgActiveCell = "#708de9";
const fgActiveCell = "#000000";

const renderAmmountCell = (item) => {
    const icon = document.createElement('span');
    ReactDOM.createRoot(icon).render(
        <CircleIcon
            sx={{
                fontSize: '0.8rem',
                color: item.value > 0 ? '#DB0000' : '#3DC700',
                stroke: item.value > 0 ? '#960000' : '#006E00',
                strokeWidth: 1,
                marginLeft: '0.5rem',
                marginBottom: '-0.1rem',
            }}
        />
    );
    return icon;
};

const isColumnFullyExpanded = (cell: PivotCell) => {
    if (!cell.columnPath) return false;
    return (cell.columnPath[cell.columnPath.length - 1] === 'Baten' || cell.columnPath[cell.columnPath.length - 1] === 'Lasten');
};

const isColumnDataCell = (cell: PivotCell) => {
    return cell.columnType === "D" && cell.area === "data";
};

const isColumnTotalCell = (cell: PivotCell) => {
    return cell.columnType === "T" && cell.area === "data";
};

const isDataCell = (cell: PivotCell) => {
    return cell.area === 'data' || cell.columnType === 'D';
};

// Generate a unique key for the cell
const getCellKey = (cell: PivotCell): string => `${JSON.stringify(cell.rowPath)}-${JSON.stringify(cell.columnPath)}`;

import { Filter } from 'odata-query';


export type DevExtremeFilter = [string, string, any] | Array<any>;

export class DevExtremeToODataQueryFilter {
    /**
     * Converts a DevExtreme filter array to an OData filter object compatible with odata-query.
     * @param filter - The DevExtreme filter array.
     * @returns The corresponding OData filter object.
     */
    static convert(filter: DevExtremeFilter): Filter {
        if (Array.isArray(filter[0])) {
            // Handle complex filters with 'and'/'or'
            return this.convertComplexFilter(filter as any);
        } else {
            // Handle simple filters
            return this.convertSimpleFilter(filter as [string, string, any]);
        }
    }

    /**
     * Converts a simple filter array to an OData filter object.
     * @param filter - The DevExtreme simple filter array.
     * @returns The corresponding OData filter object.
     */
    private static convertSimpleFilter(filter: [string, string, any]): Filter {
        const [field, operator, value] = filter;

        // Special case: "notcontains" becomes a negated "contains"
        if (operator === 'notcontains') {
            return { not: { [field]: { contains: value } } };
        }

        const odataOperator = this.mapOperator(operator);
        return { [field]: { [odataOperator]: value } };
    }

    /**
     * Converts a complex filter array (with nested 'and'/'or') to an OData filter object.
     * @param filter - The DevExtreme complex filter array.
     * @returns The corresponding OData filter object.
     */
    private static convertComplexFilter(filter: any[]): Filter {
        const logicalOperator = filter[1]?.toLowerCase() === 'or' ? 'or' : 'and';
        const filters = filter
            .filter((subFilter) => Array.isArray(subFilter))
            .map((subFilter) => this.convert(subFilter));

        return { [logicalOperator]: filters };
    }

    /**
     * Maps a DevExtreme operator to an OData query operator.
     * @param operator - The DevExtreme operator.
     * @returns The corresponding OData query operator.
     */
    private static mapOperator(operator: string): string {
        switch (operator) {
            case '=':
                return 'eq';
            case '<>':
                return 'ne';
            case '>':
                return 'gt';
            case '>=':
                return 'ge';
            case '<':
                return 'lt';
            case '<=':
                return 'le';
            case 'contains':
                return 'contains';
            case 'startswith':
                return 'startswith';
            case 'endswith':
                return 'endswith';
            default:
                throw new Error(`Unsupported operator: ${operator}`);
        }
    }
}

import React, { useCallback, useRef } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { DropDownBox, RadioGroup, TreeView } from 'devextreme-react';
import TextBox from 'devextreme-react/text-box';
import { UrlState } from './index';
import { TreeViewRef } from 'devextreme-react/cjs/tree-view';

type LinkPopupProps = {
    url: UrlState;
    setUrl: React.Dispatch<React.SetStateAction<UrlState>>;
    sitemapsource?: any[];
    isTreeBoxOpened: boolean;
    setIsTreeBoxOpened: React.Dispatch<React.SetStateAction<boolean>>;
    insertLink: () => void;
};

/**
 * LinkPopup component provides a modal for inserting hyperlinks.
 */
const LinkPopup: React.FC<LinkPopupProps> = ({ url, setUrl, sitemapsource, isTreeBoxOpened, setIsTreeBoxOpened, insertLink }) => {
    const treeViewRef = useRef<TreeViewRef<any>>(null);

    const treeViewItemSelectionChanged = useCallback((e) => {
        setUrl((prev) => ({ ...prev, type: 'Internal', url: e.itemData.id, text: prev.length <= 0 ? e.itemData.name : prev.text }));
    }, [setUrl]);

    const treeViewOnContentReady = useCallback((e) => {
        e.component.selectItem(url.url);
    }, [url.url]);

    const onTreeItemClick = useCallback(() => {
        setIsTreeBoxOpened(false);
    }, [setIsTreeBoxOpened]);

    const treeViewRender = useCallback(() => (
        <TreeView
            items={sitemapsource}
            ref={treeViewRef}
            dataStructure="plain"
            keyExpr="id"
            parentIdExpr="parentId"
            selectionMode="single"
            displayExpr="name"
            selectByClick={true}
            onContentReady={treeViewOnContentReady}
            onItemClick={onTreeItemClick}
            onItemSelectionChanged={treeViewItemSelectionChanged}
        />
    ), [sitemapsource, treeViewOnContentReady, onTreeItemClick, treeViewItemSelectionChanged]);

    const onTreeBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }, [setIsTreeBoxOpened]);

    const syncTreeViewSelection = useCallback((e) => {
        setUrl((prev) => ({ ...prev, url: e.value }));
        if (!treeViewRef.current) return;

        if (!e.value) {
            treeViewRef.current.instance().unselectAll();
        } else {
            treeViewRef.current.instance().selectItem(e.value);
        }
    }, [setUrl]);

    const onClose = useCallback(() => {
        setUrl({ popupVisible: false, index: 0, length: 0, type: 'External', text: '', url: '' });
    }, [setUrl]);

    const radioItems: { key: string, label: string }[] = [
        { key: 'External', label: 'Externe website' },
        { key: 'Internal', label: 'Pagina in publicatie' },
    ];

    return (
        <Popup
            showTitle={true}
            title="Hyperlink invoegen"
            visible={url.popupVisible}
            showCloseButton={true}
            width={400}
            height={'auto'}
            dragEnabled={false}
            hideOnParentScroll={true}
            onHiding={onClose}
        >
            <div className="dx-fieldset dx-lias-fieldset">
                <div className="dx-field dx-lias-field">
                    <div className="dx-field-label">Link naar</div>
                    <RadioGroup
                        items={radioItems}
                        valueExpr='key'
                        displayExpr='label'
                        defaultValue={url.type}
                        layout="horizontal"
                        onValueChanged={(e) => {
                            setUrl((prev) => ({
                                ...prev,
                                url: '',
                                type: e.value
                            }));
                        }}
                    />
                </div>
                <div className="dx-field dx-lias-field">
                    {url.type === 'Internal' ?
                        <>
                            <div className="dx-field-label">Pagina</div>
                            <DropDownBox
                                value={url.url}
                                opened={isTreeBoxOpened}
                                valueExpr="id"
                                displayExpr="name"
                                placeholder="Kies..."
                                showClearButton={true}
                                items={sitemapsource}
                                onValueChanged={syncTreeViewSelection}
                                onOptionChanged={onTreeBoxOpened}
                                contentRender={treeViewRender}
                            />
                        </> :
                        <>
                            <div className="dx-field-label">URL</div>
                            <TextBox
                                value={url.url}
                                onValueChanged={(e) => {
                                    setUrl((prev) => ({ ...prev, url: e.value }));
                                }}
                            />
                        </>}
                </div>
                <div className="dx-field dx-lias-field">
                    <div className="dx-field-label">Tekst</div>
                    <TextBox
                        value={url.text}
                        onValueChanged={(e) => {
                            setUrl((prev) => ({ ...prev, text: e.value }));
                        }}
                    />
                </div>
            </div>

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'OK',
                    stylingMode: 'text',
                    type: 'default',
                    onClick: () => {
                        insertLink();
                        onClose();
                    },
                }}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Annulleren',
                    stylingMode: 'text',
                    type: 'default',
                    onClick: onClose,
                }}
            />
        </Popup>
    );
};

export default LinkPopup;
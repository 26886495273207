import React from 'react';
import { Button } from '../button';
import { withField } from '../field';
import { Tooltip } from 'devextreme-react/tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';

export const ButtonField = withField<{ name: string, onReset?: () => void }>(({ id, label, disabled, value: { name, onReset }, onChange }) => (
    <div>
        <Button
            id={`button-${id}`}
            btnbase="textbuttons"
            btntype="medium_icon"
            aria-label={name}
            onClick={onChange}
            disabled={disabled}>
            {name}
        </Button>
        {onReset &&
            <Button id="btn-reset-filter-to-template" btnbase='textbuttons' btntype='small_icon' icon={<RefreshIcon />} onClick={() => onReset()}>
                <Tooltip
                    target="#btn-reset-filter-to-template"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={true}
                >
                    Laad {label} uit template
                </Tooltip>
            </Button>}
    </div>
));

import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { MultiSelectItem, Label, Text, MandatoryIcon } from '@liasincontrol/ui-basics';
import { SelectElement, CheckboxElement, TextElement } from '@liasincontrol/ui-elements';
import { ValidatorsDictionary, FormData, BasicValidator, FormMode, JsonUtils, FormHelper, ValueType } from '@liasincontrol/core-service';
import { isDataStoreType, performanceElementDefinitionSystemIds, requiredFieldsChecked } from '../../DataSourcesList/index.helper';

type Props = {
    form: FormData<ValueType>,
    mode: FormMode,
    measureMoments: Domain.Shared.MeasureMoment[],
    selectedDataStore: { id: string, kind: string, element: Domain.Publisher.DataStoreElement },
    elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    hierarchyElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    hierarchyDefinitions?: Domain.Shared.HierarchyDefinition[],
    onChange: (subForm: FormData<ValueType>) => void,
    inValidateForm?: () => void,
};

type MultiSelectItemExtended = MultiSelectItem & { elements: string[] };
type ExtraParameters = { hierarchyDefinitionId: string, hierarchyDefinitionName: string, availableTypes: MultiSelectItem[], fieldList: MultiSelectItemExtended[] };

/**
 * Represents a UI component that renders a sub form to view/edit a data source with hierarchy.
 */

const HierarchyForm: React.FC<Props> = (props) => {
    const complexFieldDefinitions: Record<string, Domain.Shared.FieldDefinition> = useMemo(() =>
        props.elementdefinitions[Domain.SystemElementDefinitions.Pub.DataSource].complexFields[0].fields
            ?.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {})
        , [props.elementdefinitions]);
    const measureMoment = useMemo(() => complexFieldDefinitions[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId], [complexFieldDefinitions]);

    const elementDefinitionDefinition = useMemo(() => complexFieldDefinitions[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId], [complexFieldDefinitions]);
    const dataSourceColumnsDefinition = useMemo(() => complexFieldDefinitions[SystemFieldDefinitions.Pub.DataSourceColumns], [complexFieldDefinitions]);

    const requiredFieldNames: string[] = useMemo(() => Object.values(props.hierarchyElementDefinitions)?.flatMap((elementDefinition) => {
        const fieldListNames = elementDefinition.fields?.filter((field) => requiredFieldsChecked.includes(field.systemId))?.map((f) => f.label || f.name);
        return _.uniq(fieldListNames);
    }), [props.hierarchyElementDefinitions]);

    const [extraParameters, setExtraParameters] = useState<ExtraParameters>({
        hierarchyDefinitionId: undefined,
        hierarchyDefinitionName: undefined,
        availableTypes: [],
        fieldList: [],
    });

    useEffect(() => {
        props.inValidateForm?.();
    }, [props.selectedDataStore]);

    useEffect(() => {
        if (!props.selectedDataStore || !props.selectedDataStore.kind || !props.form || !props.hierarchyElementDefinitions) return;
        initExtraParameters(props.selectedDataStore.id, props.selectedDataStore.kind, props.form.values[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId], props.form.values[SystemFieldDefinitions.Pub.DataSourceColumns]);
    }, [props.selectedDataStore, props.hierarchyDefinitions, props.hierarchyElementDefinitions, props.form.values[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId], props.form.values[SystemFieldDefinitions.Pub.DataSourceColumns]]);

    // #region Hierarchy utils
    const isContained = (arr: string[], str: string): boolean => {
        return arr.some(element => element.toLowerCase() === str.toLowerCase());
    };

    const getFieldListByElement = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, elementDefinitionId: string): MultiSelectItemExtended[] => {
        if (!elementDefinitionId) return [];
        const selectedDefinition = elementDefinitions[elementDefinitionId];
        if (!selectedDefinition) return [];

        const selectedFieldList = selectedDefinition.fields?.map((field) => ({
            id: field.id,
            label: field.name,
            value: false,
            disabled: false,
            elements: [selectedDefinition.name]
        }));
        return selectedFieldList;
    };

    const getUniqFieldList = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, selectedTypes: string[]): MultiSelectItemExtended[] => {
        const fieldList = selectedTypes.flatMap((type) => getFieldListByElement(elementDefinitions, type));
        const groupedItems = _(fieldList)
            .groupBy(item => item.label.toLowerCase())
            .sortBy(group => fieldList.indexOf(group[0]))
            .map((group) => ({ ...group[0], elements: group.flatMap(a => a.elements) }))
            .value();
        return groupedItems;
    };

    const getAvailableHierarchyTypes = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, items: Domain.Shared.HierarchyLinkDefinition[]) => {
        if (!items) return;
        const usedElementDefinitionIds: string[] = items
            ?.filter((hierarchyDefinitionLink) => !hierarchyDefinitionLink.fromElementDefinitionId)
            ?.map((hierarchyDefinitionLink) => hierarchyDefinitionLink.toElementDefinitionId);

        const availableTypes = Object.values(elementDefinitions)
            .filter((elementDefinition) => usedElementDefinitionIds.indexOf(elementDefinition.id) >= 0)
            .map((elementDefinition) => {
                return {
                    id: elementDefinition.id,
                    label: elementDefinition.name,
                    value: false,
                };
            });
        return availableTypes;
    };
    // #endregion

    // get available types and fields based on datasource
    const initExtraParameters = (dataStoreId: string, dataStoreKind: string, initialTypes?: string, initialFields?: string): void => {
        const selectedTypeIds = JsonUtils.toJson<string[]>(initialTypes, []);
        const selectedFields = JsonUtils.toJson<string[]>(initialFields, []);

        if (isDataStoreType(dataStoreKind, Domain.Publisher.DataStoreTypes.Studio)) {
            if (props.selectedDataStore.element && props.hierarchyDefinitions) {
                const selectedDefinition = props.hierarchyDefinitions?.find(hd => hd.hierarchyDefinitionId === props.selectedDataStore.element.hierarchyDefinition);
                const availableTypes = getAvailableHierarchyTypes(props.hierarchyElementDefinitions, selectedDefinition.items);

                const markedavailableTypesList = availableTypes.map((type) => ({ ...type, value: !!selectedTypeIds?.includes(type.id) }));
                const availableFieldList = props.hierarchyElementDefinitions ? getUniqFieldList(props.hierarchyElementDefinitions, selectedTypeIds) : [];
                setExtraParameters({
                    hierarchyDefinitionId: selectedDefinition.hierarchyDefinitionId,
                    hierarchyDefinitionName: selectedDefinition.name,
                    availableTypes: markedavailableTypesList,
                    fieldList: availableFieldList.map((field) => (
                        {
                            ...field,
                            value: isContained(requiredFieldNames.concat(selectedFields), field.label),
                            disabled: isContained(requiredFieldNames, field.label)
                        }
                    )),
                });
            } else {
                setExtraParameters({
                    hierarchyDefinitionId: undefined,
                    hierarchyDefinitionName: undefined,
                    availableTypes: [],
                    fieldList: [],
                });
            }
        } else if (isDataStoreType(dataStoreKind, Domain.Publisher.DataStoreTypes.Performance)) {
            const availableTypes = Object.values(props.hierarchyElementDefinitions)
                .filter((elementDefinition) => performanceElementDefinitionSystemIds.includes(elementDefinition.systemId))
                .map((elementDefinition) => {
                    return {
                        id: elementDefinition.id,
                        label: elementDefinition.name,
                        value: !!selectedTypeIds?.includes(elementDefinition.id),
                    };
                });

            const availableFieldList = props.hierarchyElementDefinitions ? getUniqFieldList(props.hierarchyElementDefinitions, selectedTypeIds) : [];

            setExtraParameters({
                hierarchyDefinitionId: undefined,
                hierarchyDefinitionName: undefined,
                availableTypes: availableTypes,
                fieldList: availableFieldList.map((field) => (
                    {
                        ...field,
                        value: isContained(requiredFieldNames.concat(selectedFields), field.label),
                        disabled: isContained(requiredFieldNames, field.label)
                    }
                )),
            });
        } else {
            setExtraParameters({
                hierarchyDefinitionId: undefined,
                hierarchyDefinitionName: undefined,
                availableTypes: [],
                fieldList: [],
            });
        }
    };

    const storeFormValues = (values: Record<string, ValueType>): void => {
        const clonedForm = FormHelper.handleFormValuesChanged(values, props.form);
        const { errors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors, []);
        clonedForm.validationErrors = { ...props.form.validationErrors, ...errors };
        clonedForm.isValid = !Object.values(clonedForm.validationErrors).some(a => a.length > 0);
        props.onChange(clonedForm);
    };

    const changeElementDefinition = (typeId: string, val: boolean): void => {
        const clonedItemList = [...extraParameters.availableTypes];
        const type = clonedItemList.find((f) => f.id === typeId);
        clonedItemList.splice(clonedItemList.indexOf(type), 1, { ...type, value: val });
        const selectedTypes: MultiSelectItem[] = clonedItemList?.filter((t) => !!t.value);

        // reconfigure fields
        const fieldList = props.hierarchyElementDefinitions && selectedTypes && selectedTypes.length ? getUniqFieldList(props.hierarchyElementDefinitions, selectedTypes.map(i => i.id)) : [];

        // preserve selection!
        const clonedFieldList = [...extraParameters.fieldList];

        const previouslySelectedFieldList = requiredFieldNames.concat(clonedFieldList?.filter((t) => !!t.value)?.map((f) => f.label));
        const syncedFieldList = fieldList.map((field) => ({ ...field, value: isContained(previouslySelectedFieldList, field.label) }));

        const selectedFields: MultiSelectItemExtended[] = syncedFieldList?.filter((field) => !!field.value);

        const fields = {
            [SystemFieldDefinitions.Pub.DataSourceElementDefinitionId]: _.isEmpty(selectedTypes) ? '' : JSON.stringify(selectedTypes.map(i => i.id)),
            [SystemFieldDefinitions.Pub.DataSourceColumns]: _.isEmpty(selectedFields) ? '' : JSON.stringify(selectedFields.map(i => i.label)),
        };

        storeFormValues(fields);
    };

    const changeFieldListSelection = (fieldId: string, val: boolean) => {
        const clonedFieldList = [...extraParameters.fieldList];
        const field = clonedFieldList.find((f) => f.id === fieldId);
        clonedFieldList.splice(clonedFieldList.indexOf(field), 1, { ...field, value: val });
        const selectedFields: MultiSelectItem[] = clonedFieldList?.filter((field) => !!field.value);

        const fields = {
            [SystemFieldDefinitions.Pub.DataSourceColumns]: _.isEmpty(selectedFields) ? '' : JSON.stringify(selectedFields.map(i => i.label)),
        };

        storeFormValues(fields);
    };

    return (<>
        {isDataStoreType(props.selectedDataStore.kind, Domain.Publisher.DataStoreTypes.Studio) &&
            <MuiGrid item xs={1} sm={2} md={4} key='datasource-hierarchyid'>
                <TextElement
                    id='datasource-hierarchyid'
                    key='key-datasource-hierarchyid'
                    label='Hiërarchie'
                    value={extraParameters.hierarchyDefinitionName}
                />
            </MuiGrid>
        }
        <MuiGrid item xs={1} sm={2} md={4} key={measureMoment.id}>
            <SelectElement<Domain.Shared.MeasureMoment>
                id='datasource-measuremoment'
                key='key-datasource-measuremoment'
                displayExpr='name'
                label={measureMoment.label ? measureMoment.label : measureMoment.name}
                searchable={false}
                clearable={false}
                optionItems={props.measureMoments}
                editorSettings={{
                    disabled: props.mode === FormMode.View,
                    restrictions: validators[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId] ? validators[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId].getRestrictions() : undefined,
                    validationErrors: props.form.touched[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId] ? props.form.validationErrors[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId] : [],
                    onChange: (item) => {
                        const fields = {
                            [SystemFieldDefinitions.Pub.DataSourceMeasureMomentId]: item.id,
                        };
                        storeFormValues(fields);
                    }
                }}
                value={props.measureMoments.find(mm => mm.id === props.form.values[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId])}
            />
        </MuiGrid>

        <MuiGrid item xs={1} sm={2} md={4} key={elementDefinitionDefinition.id}>
            <Label text="Elementdefinities" />
            {validators[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId].getRestrictions()?.required && <MandatoryIcon />}
        </MuiGrid>
        {extraParameters?.availableTypes?.map((type) => (
            <MuiGrid item xs={1} key={`key-element-definition-wrapper-${type.id}`}>
                <CheckboxElement
                    id={type.id}
                    altLabel={type.label}
                    value={type.value}
                    helpText={type.label.length > 50 && {
                        text: type.label,
                        type: 'info'
                    }}
                    editorSettings={{
                        disabled: props.mode === FormMode.View,
                        withoutFeedback: true,
                        restrictions: validators[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId] ? validators[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId].getRestrictions() : undefined,
                        validationErrors: props.form.touched[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId] ? props.form.validationErrors[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId] : [],
                        onChange: (val: boolean) => {
                            changeElementDefinition(type.id, val);
                        }
                    }}
                />
            </MuiGrid>
        ))}
        <MuiGrid item xs={1} sm={2} md={4} key={dataSourceColumnsDefinition.id}>
            <Label text="Selecteer kolommen" />
            {validators[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId].getRestrictions()?.required && <MandatoryIcon />}
            {!!!extraParameters?.fieldList?.length && <Text value={'Wanneer de bovenstaande selectie compleet is zullen de kolommen zichtbaar worden.'} />}
        </MuiGrid>
        {extraParameters?.fieldList?.map((field) => (
            <MuiGrid item xs={1} key={`key-field-definition-wrapper-${field.id}`}>
                <CheckboxElement
                    id={field.id}
                    altLabel={`${field.label} (${field.elements.length})`}
                    value={field.value}
                    helpText={{ text: field.elements.join('\n'), title: 'Elementdefinitie(s)', type: 'info' }}
                    editorSettings={{
                        disabled: props.mode === FormMode.View || field.disabled,
                        withoutFeedback: true,
                        restrictions: validators[SystemFieldDefinitions.Pub.DataSourceColumns] ? validators[SystemFieldDefinitions.Pub.DataSourceColumns].getRestrictions() : undefined,
                        validationErrors: props.form.touched[SystemFieldDefinitions.Pub.DataSourceColumns] ? props.form.validationErrors[SystemFieldDefinitions.Pub.DataSourceColumns] : [],
                        onChange: (val: boolean) => {
                            changeFieldListSelection(field.id, val);
                        }
                    }}
                />
            </MuiGrid>
        ))}
    </>
    );
}

/**
 * Initialises the subform validators.
 */
const validators: ValidatorsDictionary = {
    [SystemFieldDefinitions.Pub.DataSourceMeasureMomentId]: new BasicValidator<string>({ required: true }),
    [SystemFieldDefinitions.Pub.DataSourceElementDefinitionId]: new BasicValidator<string>({ required: true }),
    [SystemFieldDefinitions.Pub.DataSourceColumns]: new BasicValidator<string>({ required: true }),
};

export { HierarchyForm };

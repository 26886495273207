import { css } from "styled-components";

export const InternalScrollDataGrid = css`
    //grow to occupy remaining height in parent container
    flex-grow: 1;
    min-height: 0;

    //darken selected color and hover color
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td {
        background-color: #0000000a;
    }

    tr.dx-row.dx-data-row.dx-row-lines:hover > td {
        cursor: pointer;
    }
`;


import React from 'react';
import { DateUtils } from '@liasincontrol/core-service';
import { ElementProps } from '../../shared/ElementProps';
import { DateField } from '@liasincontrol/ui-devextreme';
import { getValidationErrorsNode } from '../../shared/validationHelper';
import _ from 'lodash';

export type DateElementProps = ElementProps<Date> & {
    minDate?: Date,
    maxDate?: Date,
    showClearButton?: boolean,
}
/**
 * Represents a UI component that renders an Date element.
 */
export const DateElement: React.FC<DateElementProps> = (props) => {
    return (
        <DateField
            id={props.id}
            key={props.id}
            label={props.label}
            disabled={_.isEmpty(props.editorSettings) || props.editorSettings.disabled}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            mandatory={props.editorSettings?.restrictions?.required}
            value={props.value}
            showClearButton={props.showClearButton === undefined ? true : props.showClearButton}
            minDate={props.editorSettings?.restrictions ? DateUtils.tryConvertDate(props.minDate, true) : undefined}
            maxDate={props.editorSettings?.restrictions ? DateUtils.tryConvertDate(props.maxDate, true) : undefined}
            helpText={{ text: props.helpText?.text, title: props.helpText?.title }}
            onChange={(val) => props.editorSettings?.onChange?.(val)}
        />);
};
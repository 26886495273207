import { css } from 'styled-components';
import { cssSmallText, cssNormalText, cssLargeText } from '../../primitives/typography';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { base, whenDisabled } from './base';
import { ghostbuttons } from './styleguide';

// tslint:disable:no-magic-numbers

export const Ghost: ghostbuttons = {
    small_icon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(5)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.white, palette.primary2)}
                border-width: 1px;
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(3)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    small_icon_green: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(5)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.darkGreen2, palette.lightGreen2, palette.darkGreen2)}
                border-width: 1px;
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(3)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.darkGreen2, palette.darkGreen2)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.darkGreen2, palette.darkGreen2)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.darkGreen2, palette.darkGreen2)}
                border-width: 1px;
            }
            :disabled > span {
                ${base.colors(palette.darkGreen2, palette.lightGreen2, palette.lightGreen2)}
                border-width: 1px;
            }
            /* > span {
                ${whenDisabled(base.colors(palette.darkGreen2, palette.lightGreen2, palette.darkGreen2))}
            } */
        `,
    },
    small_icon_red: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(5)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.darkRed, palette.lightRed2, palette.darkRed)}
                border-width: 1px;
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(3)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.darkRed, palette.darkRed)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.darkRed, palette.darkRed)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.darkRed, palette.darkRed)}
                border-width: 1px;
            }
            :disabled > span {
                ${base.colors(palette.darkRed, palette.lightRed2, palette.lightRed2)}
                border-width: 1px;
            }
            /* > span {
                ${whenDisabled(base.colors(palette.darkRed, palette.lightRed2, palette.darkRed))}
            } */
        `,
    },
    small_noicon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(5)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.white, palette.primary2)}
                border-width: 1px;
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(3)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    medium_icon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.white, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    medium_icon_green: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.darkGreen2, palette.lightGreen2, palette.darkGreen2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.darkGreen2, palette.darkGreen2)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.darkGreen2, palette.darkGreen2)}
            }
            :active > span {
                ${base.colors(palette.white, palette.darkGreen2, palette.darkGreen2)}
            }
            :disabled > span {
                ${base.colors(palette.darkGreen2, palette.lightGreen2, palette.lightGreen2)}
            }

            > span {
                ${whenDisabled(base.colors(palette.darkGreen2, palette.lightGreen2, palette.darkGreen2))}
            }
        `,
    },
    medium_icon_red: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.darkRed, palette.lightRed2, palette.darkRed)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.darkRed, palette.darkRed)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.darkRed, palette.darkRed)}
            }
            :active > span {
                ${base.colors(palette.white, palette.darkRed, palette.darkRed)}
            }
            :disabled > span {
                ${base.colors(palette.darkRed, palette.lightRed2, palette.lightRed2)}
            }

            > span {
                ${whenDisabled(base.colors(palette.darkRed, palette.lightRed2, palette.lightRed2))}
            }
        `,
    },
    medium_noicon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.white, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
            }
            :focus > span {
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    large_icon: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span {
                grid-gap: 16px;
                padding: ${px(11)} ${px(24)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.white, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }

            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    large_noicon: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span {
                grid-gap: 16px;
                padding: ${px(11)} ${px(24)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.white, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
};

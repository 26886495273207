import { ILsChartProps } from ".";

export const defaultChartSettings: ILsChartProps = {
    data: [],
    redrawOnResize: true,
    chartData: {
        valueField: '',
        argumentField: '',
        label: {
            visible: false,
            connector: {
                visible: false,
            },
        },
    },
    legendData: {
        visible: false,
    },
    tooltipData: {
        enabled: false,
    },
    animationData: {
        enabled: false,
    },
    adaptiveLayout: {
        height: 80,
        width: 80
    },
    type: "bar",
};
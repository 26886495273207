import React from 'react';
import { AttachmentField } from '@liasincontrol/ui-basics';
import { AttachmentValidationRestriction } from '@liasincontrol/core-service';
import { AttachmentProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders an attachment editor.
 */
export const AttachmentEditor: React.FC<AttachmentProps> = (props) => {
    const restrictions: AttachmentValidationRestriction = props.editorSettings ? props.editorSettings.restrictions : null;

    return (
        <AttachmentField
            id={props.id}
            label={props.label}
            disabled={props.editorSettings.disabled}
            mandatory={props.editorSettings.restrictions?.required}
            helpText={props.helpText}
            attachmentId={props.value}
            allowedFileTypes={restrictions?.allowedFileTypes}
            allowMultiple={props.allowMultiple}
            maxFileSize={restrictions?.maxFileSize}
            maxImageSize={props.maxImageSize}
            error={getValidationErrorsNode(props.editorSettings.validationErrors)}
            onLoadAttachment={props.onLoadAttachment}
            onUploadAttachment={props.onUploadAttachment}
            onChange={props.editorSettings.onChange}
            onAddFileError={props.onAddFileError}
            onStartMultipleUpload={props.onStartMultipleUpload}
            onFinishMultipleUpload={props.onFinishMultipleUpload}
            onReset={props.onReset}
        />
    );
};

import posed from 'react-pose';
import styled, { css } from 'styled-components';
import { px } from '../style';
import { palette } from '../styleguide';
import { Heading1 } from '../primitives';
import { ModalDialogLook } from './index.props';

const Underlay = styled(
    posed.div({
        opened: { opacity: 1, transition: { ease: `easeIn`, duration: 1 } },
        closed: { opacity: 0, transition: { ease: `easeIn`, duration: 200 } },
    })
)`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
`;

const WhenVisible = styled(posed.div({
    opened: { beforeChildren: true, applyAtStart: { display: 'block' }, duration: 1 },
    closed: { afterChildren: true, applyAtEnd: { display: 'none' }, duration: 1 },
}))``;

const Container = styled(
    posed.div({
        opened: { y: `0%`, scale: 1, transition: { ease: `easeIn`, duration: 1 } },
        closed: { y: `100%`, scale: 0.95, transition: { ease: `easeIn`, duration: 200 } },
    })
) <{ cardLook: boolean }>`
    position: relative;
    overflow: hidden;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: ${(p) => p.cardLook ? `white` : `${palette.grey4}`};
    border-radius: ${(p) => p.cardLook ? `none` : `6px 6px 0 0`};
    box-shadow: 0 ${px(3)} ${px(30)} rgba(75, 85, 246, 0.1);
    padding: 0 0 ${px(64)} 0;
`;

const ScrollContainer = styled.div`
    position: relative;
    padding: ${(props: { customPadding: boolean }) => (props.customPadding ? null : '40px 40px 80px')};
    overflow-y: auto;
    height: 100%;
    ::-webkit-scrollbar {
        width: 15px;
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const MarginLayout = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    padding: ${px(40)} ${px(40)} ${px(0)} ${px(40)};
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`;

const CenterLayout = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    overflow: auto;
`;

const Popup = styled.div<{ maxAllowed: boolean, customWidth: number; look: ModalDialogLook, cardLook: boolean, customPadding: boolean }>`
    margin: ${px(48)};
    background-color: #fff;
    padding: ${(p) => (p.look === 'interactive' ? '32px 40px' : px(48))};
    box-shadow: 0 ${px(4)} ${px(44)} ${px(-4)} rgba(0, 0, 0, 0.2);
    border-radius: ${px(4)};
    z-index: 1;
    width: 100%;
    ${({ maxAllowed, customWidth }) => (maxAllowed
        ? css`
            max-width: calc(100% - ${px(160)});
            max-height: calc(100% - ${px(160)});
            height: 100%;
        `
        : css`
            max-width: ${px(customWidth)};
        `
    )};
    ${({ customPadding }) => (customPadding && css`padding: 0;`)};
`;

const H1 = styled(Heading1)`
    margin: 0 0 ${px(40)} 0;
`;

const Footer = styled.div`
    margin: ${px(40)} 0 0 0;
`;
const Header = styled.div`
    margin: ${px(40)} 0 0 0;
`;


const ModalDialogCustomStyle = styled.div`
    padding: ${px(40)} ${px(40)} ${px(16)};
    margin: 0 auto;
`;

export { Underlay, WhenVisible, Popup, Container, ScrollContainer, H1, Header, Footer, MarginLayout, CenterLayout, ModalDialogCustomStyle };
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ThumbnailImg, useComponentBounds } from '@liasincontrol/ui-basics';
import { CUSTOM_PALETTE, DEFAULT_PALETTE, getRealHeight, ILsColumnProps, LsChart } from '@liasincontrol/ui-devextreme';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    variables?: Domain.Shared.ComplexFieldItem[],
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string) => Promise<Blob>,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
}

type ControlSettings = {
    lineChart: Domain.Publisher.LineChartControl,
}

/**
 * Represents a UI component that renders a line chart control.
 */
const LineChartControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;

    const [dataSource, setDataSource] = useState<Domain.Publisher.DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: [], fields: ILsColumnProps[] }>();
    const lineChartWrapperRef = useRef<HTMLDivElement>(null);
    const { width: parentWidth } = useComponentBounds(lineChartWrapperRef);
    const pubContext = useContext(PublicationContext);

    const controlSettings: ControlSettings = useMemo(() => {
        const lineChartElement = elementList?.[element.elementId];
        if (!lineChartElement) return;
        const definition = getElementDefinition(lineChartElement.elementDefinitionSystemId, lineChartElement.elementDefinitionId);
        const settings = new Domain.Publisher.LineChartControl();
        FieldsHelper.mapObject<Domain.Publisher.LineChartControl>(settings, definition.fields, lineChartElement.fields);
        return {
            lineChart: settings,
        };
    }, [elementList]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings.lineChart?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.lineChart.dataSourceId)
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings.lineChart?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData(undefined);
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    setDataSourceData({
                        data: JSON.parse(blobs[0]),
                        fields: DataSourceControlsUtils.mapsDataSourceColumnFields(JSON.parse(blobs[1]))
                    });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };
            loadBlobsAsync();
        }
    }, [dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, onLoadAttachment]);

    if (!(controlSettings && controlSettings.lineChart.argument && controlSettings.lineChart.value && dataSourceData?.data
        && DataSourceControlsUtils.checkDataSourceForSeriesItems(controlSettings.lineChart.value, controlSettings.lineChart.argument, dataSourceData?.fields))) {
        return <SharedStyled.ThumbnailWrapper><ThumbnailImg variant="pieChart" /></SharedStyled.ThumbnailWrapper>;
    }

    const valueFormat = JsonUtils.toJson(controlSettings.lineChart?.valueFormat);
    const filter = DataSourceControlsUtils.getDataSourceFilter(controlSettings.lineChart.filter, props.variables);

    const colorPalette = pubContext.colorPalette === CUSTOM_PALETTE
        ? pubContext.customColors
        : pubContext.colorPalette || DEFAULT_PALETTE;

    return (
        <>
            {controlSettings.lineChart.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.lineChart.title}</SharedStyled.Title>}
            {controlSettings.lineChart.description && <SharedStyled.Description textFontColor={props.publicationElement?.bodyFontColor} textFontSize={props.publicationElement.bodyFontSize}>{controlSettings.lineChart.description} </SharedStyled.Description>}
            <SharedStyled.GridWrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
                editMode={false}
            >
                <Styled.CenteredDiv ref={lineChartWrapperRef}>
                    <LsChart
                        type="line"
                        data={dataSourceData.data}
                        filterValue={filter}
                        chartData={{
                            valueField: controlSettings.lineChart.value,
                            argumentField: controlSettings.lineChart.argument,
                        }}
                        palette={colorPalette}
                        primaryColor={props.publicationElement.primaryColor}
                        primaryTextColor={props.publicationElement.primaryContrastColor}
                        tooltipData={{
                            enabled: true,
                            customizeTooltip: DataSourceControlsUtils.setPieChartTooltipText,
                            format: valueFormat,
                        }}
                        redrawOnResize={true}
                        size={parentWidth ? { width: parentWidth, height: getRealHeight(parentWidth, maxAllowedHeight) } : undefined}
                    />
                </Styled.CenteredDiv>
            </SharedStyled.GridWrapper>
        </>
    );
}

const maxAllowedHeight = 400;

export default React.memo(LineChartControl, (prevProps, nextProps) =>
    _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]) && prevProps.variables === nextProps.variables
);
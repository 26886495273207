import React from 'react';
import { MultiSelectEditor } from '../MultiSelect/index.editor';
import { MultiSelectProps } from './index.props';

/**
 * Represents a UI component that renders an Select element.
 */
export const MultiSelectElement: React.FC<MultiSelectProps> = (props) => {
    return <MultiSelectEditor {...props} />;
};

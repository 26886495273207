import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { FlexBox, FloaterButtons, Heading3, Heading4, IconSize, OverflowEllipsis, ResetZIndex, Section } from '@liasincontrol/ui-basics';
import { GridColumn, LoadPanel, LsGrid } from '@liasincontrol/ui-devextreme';
import { ApiErrorReportingHelper, DataUtils, IconHelper } from '@liasincontrol/core-service';
import { formatText } from '.';

export type TransactionsType = {
    expression: any,
    column: string,
    sourceData?: any,
    ammountType?: string,
    layout?: Domain.Finance.BudgetOverviewTransactionLayout[],
    data?: Domain.Finance.BudgetOverviewTransaction[]
};

export interface TransactionProps {
    transactions?: TransactionsType;
    loading?: boolean;
    divideBy?: number;
    onError?: (error: Domain.Shared.ErrorInfo) => void;
    onExporting?: (e: any) => void;
    onClose?: () => void;
}

const extraWidthColumns = /(name$|^description$)/i;

const mapColumnProperties = (layoutColumn, divideBy = 1) => {
    const colProps: GridColumn<Domain.Finance.BudgetOverviewTransaction> = {
        width: "auto",
        align: DataUtils.getDefaultDxAlignment(layoutColumn.dataType),
        dataType: DataUtils.mapStringToDxType(layoutColumn.dataType, false, false),
    };

    if (layoutColumn.dataField === 'Amount') {
        colProps.customizeText = (cellInfo) => formatText(cellInfo, divideBy);
        colProps.width = 100;
    } else if (layoutColumn.dataField === 'WorkflowStep') {
        colProps.renderCustom = (item) => {
            return (<FlexBox>
                {IconHelper.getWorkFlowStatusIcon(item.data.workflowStep || item.data.editStage, IconSize.small)}
                <OverflowEllipsis>
                    {item.data.workflowStep || item.data.editStage}
                </OverflowEllipsis>
            </FlexBox>);
        };
    } else if (extraWidthColumns.test(layoutColumn.dataField)) {
        colProps.width = undefined;
        colProps.minWidth = 160;
    }
    if (layoutColumn.dataType === 'date') {
        colProps.formatter = 'date';
    }

    return colProps;
}

const getJournalElementCodes = (sourceData) => Object.keys(sourceData || {})
    .filter(key => key.startsWith('journalElement') && key.endsWith('Code'))
    .map(key => sourceData[key])
    .join(', ');

export const Transactions: React.FC<TransactionProps> = (props) => {
    if (!props.transactions?.data) return null;

    const availableColumns = props.transactions.layout.map((col) => {
        return {
            name: col.dataField.charAt(0).toLowerCase() + col.dataField.slice(1),
            title: col.caption,
            ...mapColumnProperties(col, props.divideBy),
        }
    });

    const gridTitle = props.transactions?.expression?.['displayExpr']
        ? `${props.transactions?.sourceData?.[props.transactions?.expression?.['displayExpr']]} - ${props.transactions?.sourceData?.[props.transactions?.expression?.['nameExpr']]}`
        : getJournalElementCodes(props.transactions?.sourceData);

    return (<Section
        look='white'
        withCloseButton={true}
        onClose={props.onClose}
        title={<>
            <Heading3>{gridTitle}</Heading3> |
            <Heading4>{props.transactions?.sourceData?.['transactionKind']} - {props.transactions?.sourceData?.['exploitationReserve']}</Heading4> |
            <Heading4>{props.transactions?.column}</Heading4>
        </>
        }>
        <LoadPanel visible={props.loading} />
        <ResetZIndex>
            <LsGrid
                id='transactions-grid'
                searching={true}
                showRowLines={true}
                showColumnLines={true}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={false}
                enableColumnChooser={true}
                columns={availableColumns}
                dataSource={props.transactions?.data}
                onDataError={(exception) => props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                showCellHintText={false}
                scrollIntoView={true}
                export={!!props.transactions?.data.length}
                onExporting={props.onExporting}
            />
            <FloaterButtons />
        </ResetZIndex>
    </Section>);
}
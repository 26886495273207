import React from 'react';
//import { CheckboxField } from '@liasincontrol/ui-basics';
import { CheckboxField } from '@liasincontrol/ui-devextreme';
import { ElementProps } from '../../shared/ElementProps';

/**
 * Represents a UI component that renders a checkbox editor.
 */
export const CheckboxEditor: React.FC<ElementProps<boolean>> = (props) => {
    return (
        <CheckboxField
            id={props.id}
            key={props.id}
            label={props.label}
            altLabel={props.altLabel}
            helpText={props.helpText}
            disabled={props.editorSettings?.disabled}
            value={props.value}
            success={undefined}
            error={undefined}
            onChange={props.editorSettings?.onChange}
            withoutFeedback={props.editorSettings?.withoutFeedback}
        />
    );
};


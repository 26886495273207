import React, { useState, useEffect, useMemo } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { FieldsHelper, OperationsHelper } from '@liasincontrol/core-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import { ThumbnailImg, Text, palette } from '@liasincontrol/ui-basics';
import { LsAccordionPanel } from '@liasincontrol/ui-devextreme';
import { Placeholder, getPlaceholderContent, placeholderCategories } from '../../_shared/Placeholder';
import Styled from './index.styled';
import { ElementActions } from '../../../../ElementActions';

type ContainerItemElementExtended = Domain.Publisher.ContainerItemElement & { id?: number, icon?: string };
type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationRoot: Domain.Publisher.ElementNode,
    publicationElement: Domain.Publisher.PublicationElement,
    selectedElementId: string,
    readonly: boolean,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[], rePosition?: boolean, toIndex?: number) => void,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onSelect: (elementId: string) => void,
    onRemoveElement?: (elementId: string, isControl: boolean) => void,
    onMoveElement?: (elementId: string, isControl: boolean, moveUp: boolean, steps?: number) => void,
    children?: React.ReactNode;
    icons?: Record<string, Domain.Shared.SvgIcon>;
};

/**
 * Represents a UI component that renders an accordion container control.
 */
const AccordionContainer: React.FC<Props> = (props) => {
    const [selectedItems, setSelectedItems] = useState<{ selected: number[], itemIndex?: number, itemId?: number }>({ selected: [], itemIndex: undefined, itemId: undefined });
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    useEffect(() => {
        if (!props.selectedElementId) {
            return;
        }

        const selectedIndex = props.element.children.findIndex((item) =>
            item.elementId === props.selectedElementId ||
            item.children.flatten(child => child.children).some(child => child.elementId === props.selectedElementId)
        );

        if (selectedIndex === -1) {
            setSelectedItems({ selected: [], itemIndex: undefined, itemId: undefined });
            return;
        }
    }, [props.element.children, props.selectedElementId]);

    const itemElements = useMemo<ContainerItemElementExtended[]>(() => {
        if (props.element?.children?.length !== 0) {
            const accordionContainerElement = props.elementList[props.element.elementId];
            const accordionPanelDef = props.getElementDefinition(accordionContainerElement.elementDefinitionSystemId, props.elementList[props.element.children[0].elementId].elementDefinitionId);
            const accordionsContainerDef = props.getElementDefinition(accordionContainerElement.elementDefinitionSystemId, accordionContainerElement.elementDefinitionId);

            const accordionContainerModel = new Domain.Publisher.AccordionContainerElement();
            FieldsHelper.mapObject<Domain.Publisher.AccordionContainerElement>(accordionContainerModel, accordionsContainerDef.fields, accordionContainerElement.fields);
            const items: Domain.Publisher.ContainerItemElement[] = props.element.children.map((item, index) => {
                const panelItemSettings = new Domain.Publisher.ContainerItemElement();
                FieldsHelper.mapObject<Domain.Publisher.ContainerItemElement>(panelItemSettings, accordionPanelDef.fields, props.elementList[item.elementId].fields);
                return {
                    ...panelItemSettings,
                    id: index,
                    icon: props.icons[panelItemSettings.tabIcon]?.svg
                };
            });
            return items;
        } else {
            return [];
        }
    }, [props.icons, props.element, props.getElementDefinition, props.elementList,]);

    if (!itemElements) {
        return null;
    }

    const onCreateItem = (toIndex?: number): void => {
        const ciElement = new Domain.Publisher.ContainerItemElement('Accordeon', '');
        ciElement.showWhitespaceBottom = false;
        ciElement.showWhitespaceLeft = false;
        ciElement.showWhitespaceRight = false;
        ciElement.showWhitespaceTop = false;
        const ciTabOperation = OperationsHelper.getOperation<Domain.Publisher.ContainerItemElement>(
            props.getElementDefinition(Domain.SystemElementDefinitions.Pub.ContainerItem),
            ciElement,
            props.element.elementId,
            Domain.Publisher.OperationKind.AddTo
        );

        const stackContainerDefinition = props.getElementDefinition(Domain.SystemElementDefinitions.Pub.StackContainer);
        const directionDefinition = stackContainerDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.StackContainerDirection);
        const stackContainerOperation = OperationsHelper.getOperation<Domain.Publisher.StackContainerElement>(
            stackContainerDefinition,
            new Domain.Publisher.StackContainerElement(directionDefinition.optionItems.find((optionItem) => optionItem.value === Domain.Publisher.Direction.Vertical).id),
            ciTabOperation.element.elementId,
            Domain.Publisher.OperationKind.AddTo
        );

        props.onTemplateChanged([ciTabOperation, stackContainerOperation], true, itemElements.length - toIndex - 1);
    };

    const getContainerItemContent = (data): JSX.Element => {
        const currentItemIndex = itemElements.findIndex((item) => item.id === data.id);

        const getRenderedElement = () => {
            return (props.element.children[currentItemIndex]?.children?.length !== 0 ?
                <Styled.Overflow>
                    {props.children[currentItemIndex]}
                </Styled.Overflow>
                : <>
                    <Styled.PlaceholderContent>
                        <ThumbnailImg variant='notes' />
                        <Text value='Voeg een container of element toe' />
                    </Styled.PlaceholderContent>
                    <Placeholder
                        wrapperId={props.element.children[currentItemIndex].elementId}
                        direction={Domain.Publisher.Direction.Vertical}
                        items={getPlaceholderContent(userIdentity)}
                        groups={placeholderCategories}
                        title='Open element verticaal toevoegen aan Tab'
                        publicationElement={props.publicationElement}
                        onCreateOperation={(elementSystemId: Domain.SystemElementDefinitions.Pub) => {
                            const operations = OperationsHelper.createElement(elementSystemId, props.element.children[currentItemIndex].elementId, Domain.Publisher.OperationKind.AddTo, props.getElementDefinition, false);
                            props.onTemplateChanged(operations);
                        }} />
                </>);
        };

        return (<>
            {getRenderedElement()}
            {props.onTemplateChanged && !props.readonly && selectedItems.itemId === data.id &&
                <ElementActions
                    elementId={props.element.elementId}
                    elementName={data.title}
                    parentDirection={Domain.Publisher.Direction.Vertical}
                    rotateMoveIcons={false}
                    canMoveUp={selectedItems.itemIndex > 0}
                    canMoveDown={selectedItems.itemIndex < itemElements.length - 1}
                    canAdd={true}
                    canDelete={itemElements.length > 1}
                    onMoveElement={(moveUp: boolean) => panelMove(data, moveUp)}
                    onRemoveElement={() => deleteButtonHandler(data)}
                    onAddElement={() => onCreateItem(selectedItems.itemIndex)}
                    verticalOffset={-1}
                />
            }
        </>);
    };

    const deleteButtonHandler = (data) => {
        const currentItemIndex = itemElements.findIndex((item) => item.id === data.id);
        const elId = props.element.children[currentItemIndex].elementId;
        props.onRemoveElement(elId, false);
    };

    const panelSelect = (data) => {
        const currentItemIndex = itemElements.findIndex((item) => item.id === data.id);
        if (currentItemIndex >= 0) {
            const selectedPanels = [...selectedItems.selected];
            if (selectedPanels.includes(currentItemIndex)) {
                //remove          
                selectedPanels.splice(selectedPanels.indexOf(currentItemIndex), 1);
                setSelectedItems({ selected: selectedPanels, itemIndex: undefined, itemId: undefined });
            } else {
                //add
                selectedPanels.push(currentItemIndex);
                setSelectedItems({ selected: selectedPanels, itemIndex: currentItemIndex, itemId: itemElements[currentItemIndex].id });
            }

            if (props.onTemplateChanged && props.onSelect) {
                props.onSelect(props.element.children[currentItemIndex].elementId);
            }
        }
    };

    const panelMove = (data, moveUp) => {
        const currentItemIndex = itemElements.findIndex((item) => item.id === data.id);
        const elId = props.element.children[currentItemIndex].elementId;
        const newIndex = currentItemIndex + (moveUp ? -1 : 1);

        //Preserve selection
        const selectedPanels = [...selectedItems.selected];
        if (!selectedPanels.includes(newIndex)) {
            selectedPanels.push(newIndex);
            selectedPanels.splice(selectedPanels.indexOf(currentItemIndex), 1);
        }

        props.onMoveElement?.(elId, false, moveUp, 1);
        setSelectedItems({ selected: selectedPanels, itemIndex: newIndex, itemId: itemElements[newIndex].id });
    };

    return (
        <Styled.AccordionContainer
            primaryPublicationColor={props.publicationElement?.primaryColor ?? palette.primary1b}
            textColor={props.publicationElement.bodyFontColor} textFontSize={props.publicationElement.bodyFontSize}>
            <LsAccordionPanel
                id={props.element.elementId}
                data={itemElements}
                getContainerItemContent={getContainerItemContent}
                selected={selectedItems.selected}
                onItemTitleClick={(e) => {
                    e.event?.stopPropagation();
                    panelSelect(e.itemData);
                }}
            />
        </Styled.AccordionContainer>
    );
};

export default AccordionContainer;

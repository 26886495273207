import styled from 'styled-components';
import TextBox from 'devextreme-react/text-box';
import { BasicText, px, palette } from '@liasincontrol/ui-basics';

const BasicTextInput = BasicText.withComponent(TextBox);
export const StyledInput = styled(BasicTextInput)`
    width: 100%;
    max-width: 100%;   
    font-family: inherit;
    margin: 0;
    color: ${palette.grey2};
    text-overflow: ellipsis;
    border-radius: 4px;
    outline: 0;
    transition: border-color 0.3s ease;
    white-space: nowrap;

    ::-ms-clear {
        display: none;
    }

    //Override the DevExtreme default properties
    &.dx-state-disabled.dx-widget {
        background: ${palette.grey4} !important;
        border: 2px solid ${palette.grey5} !important;

        .dx-placeholder {
            color: black;
        }
    }

    &.dx-editor-outlined {
        background: #fff;
        border: 2px solid ${palette.grey4} !important;
        border-radius: 4px;
        display: flex;
        }

    &.dx-state-hover {
        border: 2px solid  rgba(75, 85, 246, 0.15) !important;
        }

    &.dx-state-focused {
        border: 2px solid rgba(75, 85, 246, 1) !important;
    }

    //override devextreme textbox styles
    .custom-icon .dx-button-content .dx-icon {
        color: ${palette.primary2};
    }

    .dx-texteditor-input-container {
        input.dx-texteditor-input {
            padding: ${px(12)} ${px(16)};
            min-height: ${px(20)};
            font-family: inherit;
        }
        .dx-placeholder::before {
            padding: ${px(12)} ${px(16)};
        }
    }

    .dx-texteditor-buttons-container {
        .dx-button {
            margin-left: 0;
            margin-right: 0;

            &.dx-state-hover {
                background-color: ${palette.transparent};
            }

            &.dx-state-active {
                background-color: ${palette.transparent};
            }

            &.dx-button-mode-text.clear-icon { 
                .dx-icon {
                    color: #bbbbbb;
                }
                &:hover .dx-icon {
                    color: #666666;
                }

            }

            .dx-button-content {
                padding-left: 0;
                padding-right: 0;

                .dx-icon {
                    font-size: 15px;
                }
            }

        }

        > .dx-button.dx-button-has-icon:not(.dx-button-has-text) > .dx-button-content {
            padding-left: 1px;
            padding-right: 1px;
        }
    }
`;
